// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setName } from '../../store/slices/app/controlers/pageName'
import desktopIcon from '../../img/desktop.png'

const NotForMobile = () => {
  const dispatch = useDispatch()
  useEffect(() => { dispatch(setName("Страница не создана")) }, []) 

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          width: '100%',
          paddingTop: '40px',
          backgroundColor: 'rgb(248, 249, 252)',
          height: 'calc(100vh - 60px)'
        }}
      >
        <img 
          alt={""}
          src={desktopIcon}
          style={{
            width: '50%',
            opacity: 0.28
          }}
        />
        <span
          style={{
            textAlign: 'center',
            lineHeight: '24px',
            width: '70%',
            marginTop: '18px',
            opacity: 0.68
          }}
        >
          В настоящий момент страница доступна только в версии для ПК
        </span>
      </div>
    </React.Fragment>
  )
}

export default NotForMobile