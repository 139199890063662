import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import R from '../../../services/app/request.service'

const Measurements = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: 'measurementId',
          label: selectedOption.value,
        })
      )

      dispatch(
        changeData({
          value: 'methodId',
          label: '',
        })
      )

      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )

      dispatch(changeData({ value: 'deviceIds', label: [] }))
    } else {
      dispatch(
        changeData({
          value: 'measurementId',
          label: '',
        })
      )

      dispatch(
        changeData({
          value: 'methodId',
          label: '',
        })
      )

      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )
      dispatch(changeData({ value: 'deviceIds', label: [] }))
    }
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()

      !!!false && console.log(listMeasurement)

      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: '9px',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500,
        }}
      >
        Область измерения
        {!measurementId ? (
          <ErrorIcon
            sx={{
              color: '#FFD43B',
              marginLeft: '8px',
              marginTop: '0px',
            }}
          />
        ) : (
          <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
        )}
      </p>
      <Selector
        placeholder={'Выберите область измерения'}
        isClearable={true}
        isSearchable={true}
        options={options}
        value={measurementId ? options.find(option => option.value === measurementId) : null}
        onChange={getValue}
        noOptionsMessage={() => 'Нет доступных областей измерения'}
      />
    </React.Fragment>
  )
}

export default Measurements
