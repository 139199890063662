import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Users from '../app/pages/Users/Users'
import AddUsers from '../app/pages/Users/AddUsers'
import EditUsers from '../app/pages/Users/EditUsers'
import ShowUsers from '../app/pages/Users/ShowUsers'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteUsers: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route path='' element={width > 1050 ? <Users /> : <NotForMobile />} />
      <Route path='create' element={width > 1050 ? <AddUsers /> : <NotForMobile />} />
      <Route path=':users_id/edit' element={width > 1050 ? <EditUsers /> : <NotForMobile />} />
      <Route path=':users_id' element={width > 1050 ? <ShowUsers /> : <NotForMobile />} />
    </Routes>
  )
}

export default RouteUsers
