import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "@mui/material"

import Input from "../../../../LocalElements/Input/InputMUI"
import R from "../../../../../../services/app/request.service"

import { changeProperty } from "../state"

const Conditions = () => {
  const dispatch = useDispatch()
  const [tariffIds, setTariffIds] = useState([])

  const [temperature, setTemperature] = useState("")
  const [humidity, setHumidity] = useState("")
  const [pressure, setPressure] = useState("")
  const [voltageDeviation, setVoltageDeviation] = useState("")
  const [frequencyDeviation, setFrequencyDeviation] = useState("")
  const [airTempDifference, setAirTempDifference] = useState("")
  const [airTempSpeed, setAirTempSpeed] = useState("")

  const handleTemperatureChange = (param) => {
    setTemperature(param.replace(",", "."))
  }

  const handleHumidityChange = (param) => {
    setHumidity(param.replace(",", "."))
  }

  const handlePressureChange = (param) => {
    setPressure(param.replace(",", "."))
  }

  const handleVoltageDeviationChange = (param) => {
    setVoltageDeviation(param.replace(",", "."))
  }

  const handleFrequencyDeviationChange = (param) => {
    setFrequencyDeviation(param.replace(",", "."))
  }

  const handleAirTempDifferenceChange = (param) => {
    setAirTempDifference(param.replace(",", "."))
  }

  const handleAirTempSpeedChange = (param) => {
    setAirTempSpeed(param.replace(",", "."))
  }

  const getRandomNumber = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(1)
  }

  const autogenerateProtocol = () => {
    const randomTemperature = parseFloat(getRandomNumber(17, 23))
    const randomTempDifference = (
      parseFloat(randomTemperature) + parseFloat(getRandomNumber(-1, 1))
    ).toFixed(1)

    setTemperature(randomTemperature.toFixed(1))
    setHumidity(getRandomNumber(30, 80))
    setPressure(getRandomNumber(87, 102))
    setVoltageDeviation(getRandomNumber(84, 106))
    setFrequencyDeviation(getRandomNumber(0, 1))
    setAirTempDifference(randomTempDifference)
    setAirTempSpeed(getRandomNumber(0, 1))
  }

  const Reset = () => {
    setTemperature("")
    setHumidity("")
    setPressure("")
    setVoltageDeviation("")
    setFrequencyDeviation("")
    setAirTempDifference("")
    setAirTempSpeed("")
  }

  useEffect(() => {
    dispatch(
      changeProperty({
        value: "conditions",
        label: [
          {
            temperature_environment: parseFloat(temperature),
            relative_humidity: parseFloat(humidity),
            atmospheric_pressure: parseFloat(pressure),
            voltage_deviation: parseFloat(voltageDeviation),
            frequency_deviation: parseFloat(frequencyDeviation),
            air_temp_difference: parseFloat(airTempDifference),
            air_temp_speed: parseFloat(airTempSpeed),
          },
        ],
      })
    )
  }, [
    temperature,
    humidity,
    pressure,
    voltageDeviation,
    frequencyDeviation,
    airTempDifference,
    airTempSpeed,
    dispatch,
  ])

  useEffect(() => {
    ( async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map((tariff) => tariff.id)
      if ( tariffIds.length === 0 ) { setTariffIds(null)
      } else { setTariffIds(tariffIds) }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className="flexContainerWithGap">
        <h2 className="width50Percent" style={{ marginBottom: "10px" }}>
          Соблюдение условий поверки
        </h2>

        { tariffIds && tariffIds.includes(5) && (
          <div className="width50Percent">
            <div
              className="flexContainerWithGap"
              style={{
                marginBottom: "15px",
              }}
            >
              <div className="width50Percent">
                <Button
                  sx={{ mt: 1, mr: 1 }}
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "10px 20px 12px",
                    borderRadius: "12px",
                    marginRight: "0px",
                    color: "white",
                    width: "100%",
                  }}
                  onClick={autogenerateProtocol}
                >
                  Тестовая среда
                </Button>
              </div>
              <div className="width50Percent">
                <Button
                  sx={{ mt: 1, mr: 1 }}
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "10px 20px 12px",
                    borderRadius: "12px",
                    marginRight: "0px",
                    width: "100%",
                  }}
                  onClick={Reset}
                  disabled={
                    !temperature &&
                    !humidity &&
                    !pressure &&
                    !voltageDeviation &&
                    !frequencyDeviation &&
                    !airTempDifference &&
                    !airTempSpeed
                  }
                >
                  Сбросить все значения
                </Button>
              </div>
            </div>
            <p style={{ marginBottom: "22px" }}>
              Условия поверки указаны как пример в помощь поверителю. <br />
              При создании протокола условия поверки необходимо скорректировать.
            </p>
          </div>
        )}
      </div>

      <div className="conditionsContainer">
        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">Температура окружающей среды, °C</p>
          <Input
            type={"text"}
            value={temperature}
            actions={{
              change: handleTemperatureChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">Относительная влажность, %</p>
          <Input
            type={"text"}
            value={humidity}
            actions={{
              change: handleHumidityChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">Атмосферное давление, кПа</p>
          <Input
            type={"text"}
            value={pressure}
            actions={{
              change: handlePressureChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">Отклонение напряжения питания, %</p>
          <Input
            type={"text"}
            value={voltageDeviation}
            actions={{
              change: handleVoltageDeviationChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">
            Отклонение частоты переменного тока, Гц
          </p>
          <Input
            type={"text"}
            value={frequencyDeviation}
            actions={{
              change: handleFrequencyDeviationChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div
          className="flexContainer"
          style={{ marginBottom: "10px", gap: "10px" }}
        >
          <p className="width32Percent">
            Разность темп. воздуха и поверочной среды, °C
          </p>
          <Input
            type={"text"}
            value={airTempDifference}
            actions={{
              change: handleAirTempDifferenceChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>

        <div className="flexContainer" style={{ gap: "10px" }}>
          <p className="width32Percent">
            Cкорость темп. воздуха и окружающей среды, С/ч
          </p>
          <Input
            type={"text"}
            value={airTempSpeed}
            actions={{
              change: handleAirTempSpeedChange,
            }}
            className={"backgroundColorWhite"}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Conditions
