import React, { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IModelJournalMainSettings } from '../types.ts'
import { Tooltip } from '@mui/material'
import JCR from './JournalContentRow.tsx'
import css from '../styles.css.ts'

import { changeMainData } from '../../store/slices/admin/admin.js'

const { ContentRow } = css

const MainJournals: React.FC = (): ReactElement => {
  const dispatch = useDispatch()
  const journalsData = useSelector((state: any) => state.adminReducer.journalSettings.mainData)

  return (
    <React.Fragment>
      <ContentRow gap={20} mb={12}>
        <span style={{ fontWeight: 600, display: 'block', width: '25%' }}>
          Наименование документа
        </span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Дата создания</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Учетный номер</span>
        <span style={{ fontWeight: 600, display: 'block', width: '30%' }}>
          Ф. И. О. ответственного за документ
        </span>
        <Tooltip
          title='Позволяет системе вносить некоторые данные вместо пользователя. См. подробнее при включении автозаполнения для конкретного документа'
          placement='top'
        >
          <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>
            Автозаполнение
            <i
              className='fas fa-question-circle'
              style={{ marginLeft: '6px', cursor: 'pointer', color: '#0084E2' }}
            />
          </span>
        </Tooltip>
      </ContentRow>
      <ContentRow mb={12}>
        <span
          style={{
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '1px',
            backgroundColor: '#d7ddea',
            opacity: 0.8,
          }}
        />
      </ContentRow>

      {journalsData &&
        journalsData.map((journalItem: IModelJournalMainSettings, index: number): ReactElement => {
          const localIndex = index

          return (
            <JCR
              context='main'
              contextIndex={localIndex}
              name={journalItem.journal_name}
              journalId={journalItem.journal_type_id}
              date={{
                value: journalItem.create_date,
                action: (date: { format: (arg0: string) => any }): void => {
                  dispatch(
                    changeMainData({
                      index: localIndex,
                      value: 'create_date',
                      label: date.format('YYYY-MM-DD'),
                    }),
                  )
                },
              }}
              autogenerated={journalItem.is_autogenerate}
              autogeneratedRequired={
                journalItem.journal_type_id === 4 ? true : journalItem.is_autogenerate_required
              }
              number={journalItem.account_number !== null ? journalItem.account_number : ''}
              executor={journalItem.responsible_name !== null ? journalItem.responsible_name : ''}
              gap={20}
              mb={localIndex !== journalsData.length - 1 ? 12 : 0}
            />
          )
        })}
    </React.Fragment>
  )
}

export default MainJournals
