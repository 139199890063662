import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const StarIconColor = (props) => (
  <SvgIcon
    {...props}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.40316 6.1408C10.6698 3.86855 11.3031 2.73242 12.25 2.73242C13.1969 2.73242 13.8302 3.86855 15.0968 6.1408L15.4245 6.72865C15.7845 7.37436 15.9644 7.69721 16.2451 7.91023C16.5257 8.12325 16.8751 8.20232 17.5741 8.36047L18.2105 8.50445C20.6701 9.06098 21.9 9.33924 22.1926 10.2802C22.4852 11.2211 21.6468 12.2015 19.9699 14.1623L19.5361 14.6696C19.0596 15.2269 18.8213 15.5055 18.7141 15.8501C18.607 16.1948 18.643 16.5665 18.715 17.31L18.7806 17.9868C19.0341 20.603 19.1609 21.9111 18.3949 22.4926C17.6288 23.0742 16.4773 22.544 14.1743 21.4836L13.5785 21.2093C12.9241 20.9079 12.5969 20.7573 12.25 20.7573C11.9031 20.7573 11.5759 20.9079 10.9215 21.2093L10.3257 21.4836C8.02268 22.544 6.87118 23.0742 6.10515 22.4926C5.33912 21.9111 5.46588 20.603 5.7194 17.9868L5.78498 17.31C5.85703 16.5665 5.89305 16.1948 5.78586 15.8501C5.67868 15.5055 5.44043 15.2269 4.96392 14.6696L4.5301 14.1623C2.85325 12.2015 2.01482 11.2211 2.30742 10.2802C2.60002 9.33924 3.82986 9.06098 6.28954 8.50445L6.92589 8.36047C7.62485 8.20232 7.97433 8.12325 8.25494 7.91023C8.53555 7.69721 8.71553 7.37436 9.07547 6.72866L9.40316 6.1408Z"
      fill="#FF8E26"
    />
  </SvgIcon>
);

export default StarIconColor;
