import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  companiesPing: false,
  casesPing: false,
  masterArshinPing: false,
  ticketsPing: false,
  personalMetrologPing: false,
  conditionsPing: false,
  issueCasePing: false,
  complectsPing: false,
  receivedsPing: false,
  archivesPing: false,
  entriesArchivePing: false,
  etalonsPing: false,
  metersPing: false,
  usersPing: false,
  protocolsPing: false,
  headerPing: false,
  customUpdatePing: false,
}

const updaterReducer = createSlice({
  name: 'updaterReducer',
  initialState,
  reducers: {
    refreshCompanies: (state, action) => {
      state.companiesPing = !state.companiesPing
    },
    refreshCases: (state, action) => {
      state.casesPing = !state.casesPing
    },
    refreshMasterArshin: (state, action) => {
      state.masterArshinPing = !state.masterArshinPing
    },

    refreshTickets: (state, action) => {
      state.ticketsPing = !state.ticketsPing
    },
    refreshPersonalMetrolog: (state, action) => {
      state.personalMetrologPing = !state.personalMetrologPing
    },
    refreshConditions: (state, action) => {
      state.conditionsPing = !state.conditionsPing
    },
    refreshIssueCase: (state, action) => {
      state.issueCasePing = !state.issueCasePing
    },
    refreshComplects: (state, action) => {
      state.complectsPing = !state.complectsPing
    },
    refreshReceiveds: (state, action) => {
      state.receivedsPing = !state.receivedsPing
    },
    refreshArchives: (state, action) => {
      state.archivesPing = !state.archivesPing
    },
    refreshEntriesArchive: (state, action) => {
      state.entriesArchivePing = !state.entriesArchivePing
    },

    refreshEtalons: (state, action) => {
      state.etalonsPing = !state.etalonsPing
    },
    refreshMeters: (state, action) => {
      state.metersPing = !state.metersPing
    },
    refreshUsers: (state, action) => {
      state.usersPing = !state.usersPing
    },
    refreshProtocols: (state, action) => {
      state.protocolsPing = !state.protocolsPing
    },

    refreshHeader: (state, action) => {
      state.headerPing = !state.headerPing
    },
    refreshCustomUpdate: (state, action) => {
      state.customUpdatePing = !state.customUpdatePing
    },
  },
})

export const {
  refreshCompanies,
  refreshCases,
  refreshMasterArshin,
  refreshVerifications,
  refreshTickets,
  refreshPersonalMetrolog,
  refreshConditions,
  refreshIssueCase,
  refreshComplects,
  refreshReceiveds,
  refreshArchives,
  refreshEntriesArchive,
  refreshEtalons,
  refreshMeters,
  refreshUsers,
  refreshProtocols,
  refreshHeader,
  refreshCustomUpdate,
} = updaterReducer.actions

export default updaterReducer.reducer
