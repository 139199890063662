// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/rules-of-hooks */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip, Fab } from '@mui/material'
import R from '../../../../services/app/request.service'
import Table from '../../LocalElements/Table/Table'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import {
  refreshPersonalMetrolog,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Approved from '../../LocalElements/Filter/Approved'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import FilterIcon from '../../../../img/icon/FilterIcon'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setSearchQuery,
  changePersonal,
  resetPersonal,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import CloseIcon from '@mui/icons-material/Close'
import PencilIcon from '../../../../img/icon/PencilIcon'
import PencilDisabled from '../../../../img/icon/PencilDisabled'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import DeleteDisabled from '../../../../img/icon/DeleteDisabled'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'

const TablePersonal = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { handleDownload } = useExcelCsvPdf()
  const { width } = useWindowDimensions()

  const [data, setData] = useState([])
  const [localArchiveDateFrom, setLocalArchiveDateFrom] = useState('')

  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const measurementId = useSelector(state => state.journalReducer.personal.measurementId)
  const createdAtFrom = useSelector(state => state.journalReducer.personal.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.personal.createdAtTo)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.personal.verificationDateFrom,
  )
  const verificationDateTo = useSelector(state => state.journalReducer.personal.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.personal.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.personal.createdByRole)

  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const entryId = useSelector(state => state.journalReducer.entryId)
  const personalData = useSelector(state => state.adminReducer.journalSettings.personalData)
  const metrologPing = useSelector(state => state.updaterReducer.personalMetrologPing)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !createdAtFrom &&
      !createdAtTo &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
      width: 120,
    },
    {
      field: 'numSi',
      headerName: 'Номер СИ в Госреестре',
      width: 200,
    },
    {
      field: 'nameSi',
      headerName: 'Наименование владельца СИ',
      width: 200,
    },
    {
      field: 'nameTypeSi',
      headerName: 'Наименование и тип СИ',
      width: 300,
    },
    {
      field: 'numFactory',
      headerName: 'Заводской номер',
      width: 200,
    },
    {
      field: 'masterName',
      headerName: 'Ф.И.О инженера по метрологии',
      width: 300,
    },
    {
      field: 'planDate',
      headerName: 'Дата поверки',
      width: 200,
    },
    {
      field: 'numNotification',
      headerName: '№ свидетельства / извещения',
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 130,
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.autoGenerated ? (
              <Tooltip title='Редактирование доступно только через протокол' placement='top-end'>
                <div>
                  <PencilDisabled />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись' placement='top-end'>
                <div onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            {userRole !== 'metrologist' ? (
              <Tooltip title='Удалить запись' placement='top-end'>
                <div
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(showBA(true))
                    dispatch(setBA('Подтвердить'))
                    dispatch(setTitle('Вы действительно хотите удалить запись?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            ) : userRole === 'metrologist' && isTrusted ? (
              <Tooltip title='Удалить запись' placement='top-end'>
                <div
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(showBA(true))
                    dispatch(setTitle('Вы действительно хотите удалить запись?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                title='Удаление системных записей доступно только пользователям со статусом "Опытный"'
                placement='top-end'
              >
                <div>
                  <DeleteDisabled />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleResetFilter = () => {
    dispatch(resetPersonal())
  }
  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }
  const changeMeasurementId = param => {
    dispatch(changePersonal({ value: 'measurementId', label: param }))
  }
  const changeCompletenessStatus = event => {
    dispatch(changePersonal({ value: 'completenessStatus', label: event.target.value }))
  }
  const changeCreatedByRole = event => {
    dispatch(changePersonal({ value: 'createdByRole', label: event.target.value }))
  }
  const handleCreatedAtFromChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'createdAtFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'createdAtFrom', label: '' }))
    }
  }
  const handleCreatedAtToChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'createdAtTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'createdAtTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deletePersonalMetrolog(journalId, companyId, masterArshine)
    if (status === 200) {
      dispatch(refreshPersonalMetrolog())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка обращения к API.'))
      dispatch(resetState())
    }
  }

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_personal_master-poveritel')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_personal_master-poveritel`)
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archivePersonalMetrolog(companyId, masterArshine, {
      date_from: archiveDateFrom,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive-selected.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (localArchiveDateFrom && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [localArchiveDateFrom, archiveDateTo])

  useEffect(() => {
    false && console.log(personalData)

    if (personalData.filter(row => row.arshin_master_id === masterArshine)[0]) {
      setLocalArchiveDateFrom(
        personalData.filter(row => row.arshin_master_id === masterArshine)[0].create_date,
      )
    } else {
      setLocalArchiveDateFrom('')
    }
  }, [personalData, masterArshine])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  // ----------------------------------------------------------------
  // перенести всю логику получения данных в рефреш на ближайшем спринте !!!
  // ----------------------------------------------------------------

  useEffect(() => {
    console.log(companyId)

    if (companyId && masterArshine) {
      ;(async () => {
        try {
          const { data: conditionsItemData } = await R.getPersonalMetrolog(
            companyId,
            masterArshine,
            `?search=${searchQuery}&measurement_id=${measurementId}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFrom}&verification_date_to=${verificationDateTo}&limit=10000`,
          )
          const data = conditionsItemData.data
          setData(data)
        } catch (error) {
          console.error(error)
        }
      })()
    }

    if (companyId && entryId) {
      ;(async () => {
        try {
          const { data: conditionsItemData } = await R.getArchiveEntriesJournals(
            companyId,
            entryId,
            `?search=${searchQuery}&measurement_id=${measurementId}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFrom}&verification_date_to=${verificationDateTo}&limit=10000`,
          )
          const data = conditionsItemData.data
          setData(data)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [
    companyId,
    masterArshine,
    dispatch,
    searchQuery,
    measurementId,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
    metrologPing,
  ])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
        некоторых записях документа
      </span>
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, найдите такие записи с помощью фильтра
        <span style={{ fontWeight: 'bold' }}> "Заполнение записи"</span>, дождитесь появления или
        внесите недостающие данные и повторите процесс архивации.
      </span>
    </section>
  )

  const archiveTitle = <span style={{ color: 'red' }}>Архивация приостановлена</span>

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа
        будет доступна только в режиме просмотра.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>С какого - значение из настроек</span>
          <DatePicker
            selectedDate={localArchiveDateFrom}
            dateChange={() => {}}
            isDisabled={true}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>По какое (включительно)</span>
          <DatePicker
            selectedDate={archiveDateTo}
            dateChange={date => {
              date
                ? dispatch(setArchiveTo(date.format('YYYY-MM-DD')))
                : dispatch(setArchiveTo(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
      </div>
    </section>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'} containerWidth={700}>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Область измерения </span>
            <div style={{ width: '100%' }}>
              <Measurement measurementId={measurementId} setMeasurementId={changeMeasurementId} />
            </div>
          </div>

          {!!!entryId && (
            <div className='right-side-container-block'>
              <span className='label-filter'>Заполнение записи</span>
              <Approved
                approved={completenessStatus}
                changeApproved={changeCompletenessStatus}
                labelTrue={'Частичное'}
                labelFalse={'Полное'}
                valueTrue={'non-complete'}
                valueFalse={'complete'}
              />
            </div>
          )}

          <div className='right-side-container-block'>
            <span className='label-filter'>Создатель записи</span>
            <Approved
              approved={createdByRole}
              changeApproved={changeCreatedByRole}
              labelTrue={'Система'}
              labelFalse={'Пользователь'}
              valueTrue={'system'}
              valueFalse={'user'}
            />
          </div>
        </div>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата поверки</span>
            <DatePicker selectedDate={createdAtFrom} dateChange={handleCreatedAtFromChange} />

            <DatePicker selectedDate={createdAtTo} dateChange={handleCreatedAtToChange} />
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата создания записи</span>
            <DatePicker
              selectedDate={verificationDateFrom}
              dateChange={handleVerificationDateFromChange}
            />

            <DatePicker
              selectedDate={verificationDateTo}
              dateChange={handleVerificationDateToChange}
            />
          </div>
        </div>
        <Button
          variant='outlined'
          onClick={handleResetFilter}
          style={{
            width: '100%',
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            borderRadius: '12px',
          }}
        >
          Сбросить все значения
        </Button>
      </RightSideContainer>

      <>
        <div
          className='header_box'
          style={{
            margin: '12px 0',
            position: 'relative',
            paddingRight: count > 0 ? '50px' : '0px',
          }}
        >
          <div
            style={{
              width: width > 1050 ? '220px' : '100%',
            }}
          >
            <Button
              disabled={!!!entryId ? false : true}
              variant='contained'
              onClick={handleAddClick}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                borderRadius: '12px',
                width: '100%',
                height: '54.4px',
              }}
            >
              Добавить запись
            </Button>
          </div>

          <div style={{ width: width > 1050 ? '70%' : '100%' }}>
            <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
          </div>

          <div className='flexContainerWithGap' style={{ gap: '12px' }}>
            {count > 0 ? (
              <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
                <div style={{ cursor: 'default', opacity: '40%' }}>
                  <Pdf />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Скачать в формате PDF' placement='top'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    !!!entryId && handleDownload('pdf')
                    !!entryId && handleDownloadSelectionPdf()
                  }}
                >
                  <Pdf />
                </div>
              </Tooltip>
            )}
            {!!!entryId && (
              <Tooltip title='Скачать документ в Csv' placement='top'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('csv')}>
                  <Csv />
                </div>
              </Tooltip>
            )}
            {!!!entryId && (
              <Tooltip title='Скачать документ в Excel' placement='top'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('excel')}>
                  <Excel />
                </div>
              </Tooltip>
            )}
            {(userRole === 'super' || userRole === 'admin') && !!!entryId && (
              <React.Fragment>
                {count > 0 ? (
                  <Tooltip title='Для архивации документа отключите фильтры' placement='top'>
                    <div style={{ cursor: 'default', opacity: '40%' }}>
                      <ArchiveIcon />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title='Архивировать документ' placement='top'>
                    <div style={{ cursor: 'pointer' }}>
                      <ArchiveIcon
                        onClick={() => {
                          dispatch(setTitle('Архивация документа'))
                          dispatch(setChildren(modalContent))
                          dispatch(setBA('Архивировать'))
                          dispatch(setBD('Отменить'))
                          dispatch(showBD(true))
                          dispatch(setConfirmAction(handleArchiveClick))
                          dispatch(setOpen(true))
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </div>

          <div
            style={{
              position: 'relative',
              width: width > 1050 ? '220px' : '100%',
            }}
          >
            <Button
              variant='outlined'
              onClick={() => {
                dispatch(setOpenRightContainer(true))
              }}
              startIcon={<FilterIcon />}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                borderRadius: '12px',
                width: '100%',
                height: '54.4px',
              }}
            >
              Фильтры
            </Button>
            <span
              style={{
                display: 'block',
                position: 'absolute',
                width: '22px',
                height: '22px',
                borderRadius: '50%',
                backgroundColor: '#1976d2',
                color: 'white',
                fontSize: '11px',
                lineHeight: '21px',
                textAlign: 'center',
                top: 0,
                left: '100%',
                marginLeft: '-18px',
                marginTop: '-4px',
                transition: 'all 300ms',
                opacity: count > 0 ? 1 : 0,
              }}
            >
              {count}
            </span>
          </div>
          {count > 0 && (
            <Fab
              onClick={handleResetFilter}
              size='small'
              style={{
                position: 'absolute',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#E74953',
                left: '100%',
                marginLeft: '-48px',
                transform: 'scale(0.7)',
              }}
            >
              <CloseIcon style={{ color: '#f8f9fc' }} />
            </Fab>
          )}
        </div>

        {!!!data ? (
          <Skeleton />
        ) : (
          <Table
            css={{
              marginTop: '12px',
              backgroundColor: '#ffffff',
              border: '1px solid #d7ddea',
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: '1.2',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-cell:hover': {
                cursor: 'default',
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',

                height: 'auto',
                lineHeight: '1.5',
                padding: '8px',
                boxSizing: 'border-box',
              },
            }}
            colData={colData}
            rowData={data.map(item => ({
              id: item.id,
              number: item?.number,
              numSi: item?.fif_number,
              nameSi: item?.customer_arshin_name,
              nameTypeSi: item?.name_type_si,
              numFactory: item?.meter_factory_number,
              masterName: item?.arshin_master_name,
              planDate: item?.verification_date,
              numNotification: item?.arshin_id,
              autoGenerated: item?.is_autogenerated,
            }))}
            hasCheckboxSelection={false}
            noRowsText={'Записи отсутствуют'}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        )}
      </>
    </React.Fragment>
  )
}

export default TablePersonal
