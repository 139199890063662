// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'

const TypeMethod = () => {
  const dispatch = useDispatch()
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)

  const options = [
    { value: 'initial', label: 'Начальная поверка' },
    { value: 'periodic', label: 'Периодическая поверка' },
  ]

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'typeMethodId',
        label: selectedIds,
      })
    )

    dispatch(
      changeData({
        value: 'methodPoints',
        label: [],
      })
    )

    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )
    }
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: '14px' }}>
        <p
          style={{
            marginBottom: '9px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
        >
          Тип поверки
          {!typeMethodId ? (
            <ErrorIcon
              sx={{
                color: '#FFD43B',
                marginLeft: '8px',
                marginTop: '0px',
              }}
            />
          ) : (
            <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
          )}
        </p>
        <Selector
          placeholder={'Выберите тип поверки из списка'}
          isClearable={false}
          isSearchable={false}
          isMulti={true}
          options={options}
          onChange={getValue}
          value={options.filter(option => typeMethodId.includes(option.value))}
          noOptionsMessage={() => 'Сначала выберите методику поверки'}
        />
      </div>
    </React.Fragment>
  )
}

export default TypeMethod
