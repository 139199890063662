import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MasterArshinSelect from '../LocalElements/Filter/MasterArshinSelect'

import Input from '../LocalElements/Input/InputMUI'
import { changeData } from '../../../store/slices/app/views/cases'

const InnerDataCase = () => {
  const dispatch = useDispatch()

  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const handleInnerNameChange = param => {
    dispatch(changeData({ value: 'innerName', label: param }))
  }

  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)

  const changeMasterId = param => {
    dispatch(changeData({ value: 'arshinName', label: param }))
  }

  return (
    <div className='flexContainerWithGap'>
      <div className='width50Percent' style={{ marginBottom: '20px' }}>
        <Input
          label={'Внутренний номер СП / название комплекта СП'}
          type={'text'}
          placeholder={'Впишите номер или название комплекта, например 08'}
          value={innerName}
          actions={{
            change: handleInnerNameChange,
          }}
        />
      </div>

      <div className='width50Percent' style={{ marginBottom: '20px' }}>
        <MasterArshinSelect
          masterId={arshinName}
          setMasterId={changeMasterId}
          label={{
            text: 'Официальный пользователь ',
            topBg: 'white',
            bottomBg: 'white',
          }}
        />
      </div>
    </div>
  )
}

export default InnerDataCase
