// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

import InnerDataCase from '../../components/Cases/InnerDataCase'
import Measurements from '../../components/Cases/Measurements'
import Methods from '../../components/Cases/Methods'
import TypeMethod from '../../components/Cases/TypeMethod'
import MethodPoint from '../../components/Cases/MethodPoint'
import CheckPlace from '../../components/Cases/CheckPlace'
import Kits from '../../components/Cases/Kits'
import { resetCreateData } from '../../../store/slices/app/views/cases'
import { refreshCases } from '../../../store/slices/app/controlers/updater'
import R from '../../../services/app/request.service'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

const AddCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const userRole = useSelector(state => state.headerReducer.role)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyId = useSelector(state => state.companyIdReducer.companyId)
  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)
  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)
  const kitIds = useSelector(state => state.casesReducer.createData.kitIds)

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      arshin_master_id: arshinName,
      inner_name: innerName,
      method_id: methodId,
      verification_type_id: typeMethodId,
      point_ids: methodPointIds,
      kits: kitIds,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status } = await R.addCases(data)

    if (status === 200) {
      dispatch(setMessage('Комплект СП успешно создан'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshCases())
      navigate('/metriva/cases')
    } else if (status === 422) {
      dispatch(setMessage('Комплект СП не добавлен - ошибка валидации'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage('Ошибка обращения к api'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/cases')
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='path_to_back' onClick={navigateToProtocols}>
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Назад к списку комплектов</p>
      </div>

      <div className='box'>
        <h2 style={{ marginBottom: '25px' }}>Данные о комплекте СП</h2>
        <InnerDataCase />

        <h2 style={{ marginBottom: '14px' }}>Область измерения и методика поверки</h2>

        <div className='flexContainerWithGap'>
          <div className='width50Percent'>
            <Measurements />
          </div>

          <div className='width50Percent'>
            <Methods />
          </div>
        </div>

        <div className='flexContainerWithGap'>
          <div className='width50Percent'>
            <CheckPlace />
          </div>
          <div className='width50Percent'>
            <TypeMethod />
          </div>
        </div>

        <div className='flexContainerWithGap'>
          <div className='width32Percent' style={{ width: '100%' }}>
            <MethodPoint />
          </div>
        </div>

        <Collapse in={!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0}>
          <React.Fragment>
            <h2 style={{ marginBottom: '15px', marginTop: '20px' }}>Список эталонов</h2>
            <Kits />
          </React.Fragment>
        </Collapse>
      </div>

      <div style={{ marginTop: '14px' }}>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            margin: 0,
            borderRadius: '12px',
            width: '100%',
          }}
        >
          Сохранить комплект СП
        </Button>
      </div>
    </main>
  )
}

export default AddCases
