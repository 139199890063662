import React, { useState } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ArchiveIcon = props => {
  const [hovered, setHovered] = useState(false)

  return (
    <SvgIcon
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        cursor: 'pointer',
        marginRight: '1.9px',
        marginTop: '1px',
        transition: 'fill 0.3s',
        transform: 'scale(0.85)',
        '& path': {
          fill: hovered ? '#0084E2' : '#899298',
        },
      }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.16753 2.16705C0.480469 3.85411 0.480469 6.5694 0.480469 12C0.480469 17.4306 0.480469 20.1459 2.16753 21.8329C3.8546 23.52 6.56989 23.52 12.0005 23.52C17.431 23.52 20.1463 23.52 21.8334 21.8329C23.5205 20.1459 23.5205 17.4306 23.5205 12C23.5205 6.56943 23.5205 3.85411 21.8334 2.16705C20.1463 0.47998 17.431 0.47998 12.0005 0.47998C6.56989 0.47998 3.8546 0.47998 2.16753 2.16705ZM8.8425 7.36198C9.19313 7.03832 9.215 6.4917 8.89134 6.14107C8.56768 5.79044 8.02106 5.76858 7.67043 6.09224L5.07843 8.48485C4.90124 8.64841 4.80047 8.87858 4.80047 9.11972C4.80047 9.36086 4.90124 9.59103 5.07843 9.75459L7.67043 12.1472C8.02106 12.4709 8.56768 12.449 8.89134 12.0984C9.215 11.7477 9.19313 11.2011 8.8425 10.8775L7.87428 9.98372H14.3488C16.0739 9.98372 17.4725 11.3822 17.4725 13.1074C17.4725 14.8326 16.0739 16.2311 14.3488 16.2311H9.12047C8.6433 16.2311 8.25647 16.6179 8.25647 17.0951C8.25647 17.5723 8.6433 17.9591 9.12047 17.9591H14.3488C17.0283 17.9591 19.2005 15.7869 19.2005 13.1074C19.2005 10.4279 17.0283 8.25572 14.3488 8.25572H7.87428L8.8425 7.36198Z'
        fill='#4B5962'
      />
    </SvgIcon>
  )
}

export default ArchiveIcon
