import React, { useState } from "react"
import SvgIcon from "@mui/material/SvgIcon"

const UserBan = (props) => {
  const [hover, setHover] = useState(false)

  return (
    <SvgIcon
      {...props}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor: "pointer",
        transition: "fill 0.3s",
        transform: "scale(0.93)",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3216 14.8623C13.9294 14.8623 12.8008 15.9909 12.8008 17.3831C12.8008 17.8418 12.9229 18.2715 13.1369 18.6419L16.5804 15.1984C16.2099 14.9844 15.7803 14.8623 15.3216 14.8623ZM17.5396 16.1838L14.1222 19.6011C14.4789 19.7944 14.8872 19.904 15.3216 19.904C16.7138 19.904 17.8424 18.7754 17.8424 17.3831C17.8424 16.9487 17.7329 16.5404 17.5396 16.1838ZM11.4258 17.3831C11.4258 15.2315 13.17 13.4873 15.3216 13.4873C16.4116 13.4873 17.398 13.9358 18.1043 14.6566C18.7922 15.3585 19.2174 16.3219 19.2174 17.3831C19.2174 19.5347 17.4732 21.279 15.3216 21.279C14.2603 21.279 13.297 20.8537 12.5951 20.1658C11.8743 19.4595 11.4258 18.4731 11.4258 17.3831Z"
        fill={hover ? "#FF5963" : "#899298"}
      />
      <path
        d="M14.8633 5.92497C14.8633 7.95001 13.2217 9.59163 11.1966 9.59163C9.17157 9.59163 7.52995 7.95001 7.52995 5.92497C7.52995 3.89992 9.17157 2.2583 11.1966 2.2583C13.2217 2.2583 14.8633 3.89992 14.8633 5.92497Z"
        fill={hover ? "#FF5963" : "#899298"}
      />
      <path
        d="M13.3004 12.5139C11.3925 13.3068 10.0508 15.1883 10.0508 17.3833C10.0508 18.5899 10.4581 19.7038 11.1396 20.5916C3.86328 20.582 3.86328 18.7389 3.86328 16.4666C3.86328 14.1885 7.14653 12.3416 11.1966 12.3416C11.9278 12.3416 12.634 12.4018 13.3004 12.5139Z"
        fill={hover ? "#FF5963" : "#899298"}
      />
    </SvgIcon>
  )
}

export default UserBan
