// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import InnerDataCase from '../../components/Cases/InnerDataCase'
import Measurements from '../../components/Cases/Measurements'
import Methods from '../../components/Cases/Methods'
import TypeMethod from '../../components/Cases/TypeMethod'
import MethodPoint from '../../components/Cases/MethodPoint'
import CheckPlace from '../../components/Cases/CheckPlace'
import Kits from '../../components/Cases/Kits'
import R from '../../../services/app/request.service'
import Feedback from '../../components/LocalElements/Feedback/Feedback'
import { setShow } from '../../../store/slices/app/comps/feedback'
import { changeData, resetCreateData } from '../../../store/slices/app/views/cases'
import { refreshCases } from '../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

const EditCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cases_id } = useParams()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const show = useSelector(state => state.feedbackReducer.show)
  const userRole = useSelector(state => state.headerReducer.role)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyId = useSelector(state => state.casesReducer.createData.companyId)
  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)
  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)
  const kitIds = useSelector(state => state.casesReducer.createData.kitIds)

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: caseData } = await R.getCasesShow(cases_id)
        const data = caseData.data

        dispatch(
          changeData({
            value: 'companyId',
            label: data.company.id,
          })
        )
        dispatch(
          changeData({
            value: 'innerName',
            label: data.inner_name,
          })
        )
        dispatch(
          changeData({
            value: 'arshinName',
            label: data.arshinMaster?.id,
          })
        )

        dispatch(
          changeData({
            value: 'measurementId',
            label: data.measurement.id,
          })
        )
        dispatch(
          changeData({
            value: 'methodId',
            label: data.method.id,
          })
        )

        dispatch(
          changeData({
            value: 'methodPlaces',
            label: data.verification_methods,
          })
        )
        dispatch(
          changeData({
            value: 'typeMethodId',
            label: data.verification_types,
          })
        )

        const pointIds = data.points.map(point => point.id)
        dispatch(
          changeData({
            value: 'methodPoints',
            label: pointIds,
          })
        )

        const initialDevices = data.kits.map(kit => ({
          id: kit.device.id,
          kit_id: kit.id,
          requiredArshin: kit.is_send_to_arshin,
        }))

        dispatch(changeData({ value: 'deviceIds', label: initialDevices }))
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [cases_id])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      arshin_master_id: arshinName,
      inner_name: innerName,
      method_id: methodId,
      verification_type_id: typeMethodId,
      point_ids: methodPointIds,
      kits: kitIds,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status } = await R.addCasesPut(cases_id, data)

    if (status === 200) {
      dispatch(setMessage('Комплект СП успешно отредактирован'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCases())
      navigate('/metriva/cases')
    } else if (status === 422) {
      dispatch(setMessage(`Комплект СП не отредактирован - ошибка валидации`))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage('Ошибка обращения к api'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/cases')
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Назад к списку комплектов</p>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '25px' }}>Данные о комплекте СП</h2>
            <InnerDataCase />

            <h2 style={{ marginBottom: '14px' }}>Область измерения и методика поверки</h2>

            <div className='flexContainerWithGap'>
              <div className='width50Percent'>
                <Measurements />
              </div>

              <div className='width50Percent'>
                <Methods />
              </div>
            </div>

            <div className='flexContainerWithGap'>
              <div className='width50Percent'>
                <CheckPlace />
              </div>
              <div className='width50Percent'>
                <TypeMethod />
              </div>
            </div>

            <div className='flexContainerWithGap'>
              <div className='width32Percent' style={{ width: '100%' }}>
                <MethodPoint />
              </div>
            </div>

            <Collapse
              in={!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0}
            >
              <React.Fragment>
                <h2 style={{ marginBottom: '15px', marginTop: '20px' }}>Список эталонов</h2>
                <Kits />
              </React.Fragment>
            </Collapse>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Редактировать комплект СП
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default EditCases
