import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
  title: "Заголовок модального окна",
  children: "Сообщение по умолчанию когда нет сообщений",
  buttonAccept: null,
  buttonDismiss: null,
  showButtonAccept: true,
  showButtonDismiss: true,

  selectedTariff: null,

  suCaVariant: "default",
  confirmAction: null,

  fileViewerMode: {
    open: false,
    fileTitle: "",
    fileSize: "",
    fileObject: "",
    fileUrl: "",
  },

  imageViewerModal: {
    open: false,
    currentImage: "",
  },
}

export const modalReducer = createSlice({
  name: "modalReducer",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setChildren: (state, action) => {
      state.children = action.payload
    },
    setBA: (state, action) => {
      state.buttonAccept = action.payload
    },
    setBD: (state, action) => {
      state.buttonDismiss = action.payload
    },
    showBA: (state, action) => {
      state.showButtonAccept = action.payload
    },
    showBD: (state, action) => {
      state.showButtonDismiss = action.payload
    },
    changeSelectedTariff: (state, action) => {
      state.selectedTariff = action.payload
    },

    changeSuCaVariant: (state, action) => {
      state.suCaVariant = action.payload
    },
    setConfirmAction: (state, action) => {
      state.confirmAction = action.payload
    },

    settingsFileViewer: (state, action) => {
      state.fileViewerMode[action.payload.value] = action.payload.label
    },

    settingsImageViewer: (state, action) => {
      state.imageViewerModal[action.payload.value] = action.payload.label
    },

    resetState: () => initialState,
  },
})

export const {
  setOpen,
  setTitle,
  setChildren,
  setBA,
  setBD,
  showBA,
  showBD,
  changeSelectedTariff,
  changeSuCaVariant,
  setConfirmAction,
  settingsFileViewer,
  settingsImageViewer,
  resetState,
} = modalReducer.actions
export default modalReducer.reducer
