import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  view: 'journals',

  journalSettings: {
    mainData: [],
    personalData: [],
    specificData: [],

    showMainData: true,
    showPersonalData: true,
    showDopRoleData: true,
    showWeekendData: true,
  },
}

const adminReducer = createSlice({
  name: 'adminReducer',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload
    },

    setJournalSettings: (state, action) => {
      state.journalSettings[action.payload.value] = action.payload.label
    },

    changeMainData: (state, action) => {
      state.journalSettings.mainData[action.payload.index][action.payload.value] =
        action.payload.label
    },
    changePersonData: (state, action) => {
      state.journalSettings.personalData[action.payload.index][action.payload.value] =
        action.payload.label
    },
    changeSpecificData: (state, action) => {
      state.journalSettings.specificData[action.payload.index][action.payload.value] =
        action.payload.label
    },

    setShowMainData: (state, action) => {
      state.journalSettings.showMainData = action.payload
    },
    setShowPersonalData: (state, action) => {
      state.journalSettings.showPersonalData = action.payload
    },
    setShowDopRoleData: (state, action) => {
      state.journalSettings.showDopRoleData = action.payload
    },
    setShowWeekendData: (state, action) => {
      state.journalSettings.showWeekendData = action.payload
    },
  },
})

export const {
  setView,
  setJournalSettings,
  changeMainData,
  changePersonData,
  changeSpecificData,
  setShowMainData,
  setShowPersonalData,
  setShowDopRoleData,
  setShowWeekendData,
} = adminReducer.actions
export default adminReducer.reducer
