// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { Button, Tooltip, Checkbox } from '@mui/material'
import R from '../../../../services/app/request.service'
import Table from '../../LocalElements/Table/Table'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import InnerName from '../../LocalElements/Filter/IssueInnerName'
import Users from '../../LocalElements/Filter/IssueCases'
import Approved from '../../LocalElements/Filter/Approved'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import PencilDisabled from '../../../../img/icon/PencilDisabled'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import { refreshIssueCase, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setSearchQuery,
  changeIssueCaseFilter,
  resetIssueCaseFilter,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'

const TabIssueCase = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { handleDownload } = useExcelCsvPdf()

  const data = useSelector(state => state.journalReducer.dataIssueCase)
  const isLoading = useSelector(state => state.journalReducer.isLoadingIssueCase)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const journalId = useSelector(state => state.journalReducer.journalId)
  const entryId = useSelector(state => state.journalReducer.entryId)
  const measurementId = useSelector(state => state.journalReducer.issueCaseFilter.measurementId)
  const innerName = useSelector(state => state.journalReducer.issueCaseFilter.innerName)
  const issuedDateFrom = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateFrom)
  const issuedDateTo = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateTo)
  const receivedByName = useSelector(state => state.journalReducer.issueCaseFilter.receivedByName)
  const isReturned = useSelector(state => state.journalReducer.issueCaseFilter.isReturned)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateFrom,
  )
  const verificationDateTo = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateTo,
  )
  const completenessStatus = useSelector(
    state => state.journalReducer.issueCaseFilter.completenessStatus,
  )
  const createdByRole = useSelector(state => state.journalReducer.issueCaseFilter.createdByRole)
  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData,
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date
  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (innerName) filtersCount++
    if (issuedDateFrom) filtersCount++
    if (issuedDateTo) filtersCount++
    if (receivedByName) filtersCount++
    if (isReturned) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !innerName &&
      !issuedDateFrom &&
      !issuedDateTo &&
      !receivedByName &&
      !isReturned &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    innerName,
    issuedDateFrom,
    issuedDateTo,
    receivedByName,
    isReturned,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleResetFilter = () => {
    dispatch(resetIssueCaseFilter())
  }

  const changeMeasurementId = param => {
    dispatch(changeIssueCaseFilter({ value: 'measurementId', label: param }))
  }

  const changeInnerName = param => {
    dispatch(changeIssueCaseFilter({ value: 'innerName', label: param }))
  }

  const changeUserId = param => {
    dispatch(changeIssueCaseFilter({ value: 'receivedByName', label: param }))
  }

  const changeIsReturned = event => {
    dispatch(changeIssueCaseFilter({ value: 'isReturned', label: event.target.value }))
  }

  const changeCompletenessStatus = event => {
    dispatch(changeIssueCaseFilter({ value: 'completenessStatus', label: event.target.value }))
  }

  const changeCreatedByRole = event => {
    dispatch(changeIssueCaseFilter({ value: 'createdByRole', label: event.target.value }))
  }

  const handleIssuedDateFromChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'issuedDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'issuedDateFrom', label: '' }))
    }
  }
  const handleIssuedDateToChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'issuedDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'issuedDateTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteIssueCase(journalId, companyId)
    if (status === 200) {
      dispatch(refreshIssueCase())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка обращения к API.'))
      dispatch(resetState())
    }
  }

  const handleReturnedChange = async journalId => {
    const { status } = await R.editIssueCaseReturn(journalId, companyId)

    if (status === 200) {
      dispatch(refreshIssueCase())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Статус пользователя изменён.'))
      dispatch(setOpen(false))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
      width: 120,
    },
    {
      field: 'innerName',
      headerName: '№ комплекта СП',
      width: 180,
    },
    {
      field: 'issueDate',
      headerName: 'Дата выдачи',
      width: 180,
    },
    {
      field: 'issuedByName',
      headerName: 'Выдавший (Ф.И.О, должность)',
      width: 300,
    },
    {
      field: 'receivedByName',
      headerName: 'Принявший (Ф.И.О, должность)',
      width: 300,
    },
    {
      field: 'receivedDate',
      headerName: 'Дата возврата',
      width: 180,
    },
    {
      field: 'isReturned',
      headerName: 'Отметка о возврате',
      width: 140,
      renderCell: params => {
        const returnedDate = moment(params.row.returnedAt).format('DD-MM-YYYY')
        const currentDate = moment().format('DD-MM-YYYY')
        const isDisabled = params.row.isAutogenerated && returnedDate !== currentDate

        if (isDisabled) {
          return (
            <Tooltip
              title='Для записей созданных системой, удаление отметки о возврате задним числом недоступно'
              placement='top'
            >
              <span>
                <Checkbox
                  checked={params.row.isReturned}
                  disabled
                  onClick={event => event.stopPropagation()}
                />
              </span>
            </Tooltip>
          )
        } else {
          return (
            <Checkbox
              checked={params.row.isReturned}
              onChange={event => {
                event.stopPropagation()
                handleReturnedChange(params.row.id, event.target.checked)
              }}
              onClick={event => event.stopPropagation()}
            />
          )
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 130,
      getActions: params => {
        const returnedDate = moment(params.row.returnedAt).format('DD-MM-YYYY')
        const currentDate = moment().format('DD-MM-YYYY')
        const isDisabled = params.row.isAutogenerated && returnedDate !== currentDate

        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {isDisabled ? (
              <Tooltip
                title='Редактирование системных записей с отметкой о возврате возможно только в рамках текущего дня'
                placement='top-end'
              >
                <div>
                  <PencilDisabled />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись' placement='top-end'>
                <div onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Удалить запись' placement='top-end'>
              <div
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_issue_case')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_issue_case`)
  }

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
        некоторых записях документа
      </span>
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, найдите подобные записи с помощью фильтра
        <span style={{ fontWeight: 'bold' }}> "Заполнение записи"</span>, дождитесь
        появления/внесите недостающие данные и повторите процесс архивации.
      </span>
    </section>
  )

  const archiveTitle = <span style={{ color: 'red' }}>Архивация приостановлена</span>

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveIssueCase(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshIssueCase())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive-selected.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFromBySettings, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа
        будет доступна только в режиме просмотра.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>С какого - значение из настроек</span>
          <DatePicker
            selectedDate={archiveDateFromBySettings}
            dateChange={() => {}}
            isDisabled={true}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>По какое (включительно)</span>
          <DatePicker
            selectedDate={archiveDateTo}
            dateChange={date => {
              date
                ? dispatch(setArchiveTo(date.format('YYYY-MM-DD')))
                : dispatch(setArchiveTo(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
      </div>
    </section>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'} containerWidth={700}>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Область измерения </span>
            <div style={{ width: '100%' }}>
              <Measurement measurementId={measurementId} setMeasurementId={changeMeasurementId} />
            </div>
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Номер комплекта СП </span>
            <div style={{ width: '100%' }}>
              <InnerName innerName={innerName} setInnerName={changeInnerName} />
            </div>
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Принявший (Ф.И.О, должность)</span>
            <div style={{ width: '100%' }}>
              <Users userId={receivedByName} setUserId={changeUserId} />
            </div>
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Отметки о возврате</span>
            <Approved
              approved={isReturned}
              changeApproved={changeIsReturned}
              labelTrue={'Да'}
              labelFalse={'Нет'}
              valueTrue={'checked'}
              valueFalse={'not-checked'}
            />
          </div>

          {!!!entryId && (
            <div className='right-side-container-block'>
              <span className='label-filter'>Заполнение записи</span>
              <Approved
                approved={completenessStatus}
                changeApproved={changeCompletenessStatus}
                labelTrue={'Частичное'}
                labelFalse={'Полное'}
                valueTrue={'non-complete'}
                valueFalse={'complete'}
              />
            </div>
          )}

          <div className='right-side-container-block'>
            <span className='label-filter'>Создатель записи</span>
            <Approved
              approved={createdByRole}
              changeApproved={changeCreatedByRole}
              labelTrue={'Система'}
              labelFalse={'Пользователь'}
              valueTrue={'system'}
              valueFalse={'user'}
            />
          </div>
        </div>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата выдачи</span>
            <DatePicker selectedDate={issuedDateFrom} dateChange={handleIssuedDateFromChange} />

            <DatePicker selectedDate={issuedDateTo} dateChange={handleIssuedDateToChange} />
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата создания записи</span>
            <DatePicker
              selectedDate={verificationDateFrom}
              dateChange={handleVerificationDateFromChange}
            />

            <DatePicker
              selectedDate={verificationDateTo}
              dateChange={handleVerificationDateToChange}
            />
          </div>
        </div>
        <Button
          variant='outlined'
          onClick={handleResetFilter}
          style={{
            width: '100%',
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            borderRadius: '12px',
          }}
        >
          Сбросить все значения
        </Button>
      </RightSideContainer>

      <div
        className='header_box'
        style={{
          marginTop: '12px',
          position: 'relative',
          paddingRight: count > 0 ? '50px' : '0px',
        }}
      >
        <div
          style={{
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : true}
            variant='contained'
            onClick={handleAddClick}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Добавить запись
          </Button>
        </div>

        <div style={{ width: width > 1050 ? '70%' : '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
        </div>

        <div className='flexContainerWithGap' style={{ gap: '12px' }}>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF' placement='top'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  !!!entryId && handleDownload('pdf')
                  !!entryId && handleDownloadSelectionPdf()
                }}
              >
                <Pdf />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Csv' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('csv')}>
                <Csv />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Excel' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('excel')}>
                <Excel />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <React.Fragment>
              {count > 0 ? (
                <Tooltip title='Для архивации документа отключите фильтры' placement='top'>
                  <div style={{ cursor: 'default', opacity: '40%' }}>
                    <ArchiveIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Архивировать документ' placement='top'>
                  <div style={{ cursor: 'pointer' }}>
                    <ArchiveIcon
                      onClick={() => {
                        dispatch(setTitle('Архивация документа'))
                        dispatch(setChildren(modalContent))
                        dispatch(setBA('Архивировать'))
                        dispatch(setBD('Отменить'))
                        dispatch(showBD(true))
                        dispatch(setConfirmAction(handleArchiveClick))
                        dispatch(setOpen(true))
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          style={{
            position: 'relative',
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : false}
            variant='outlined'
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterIcon />}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '22px',
              height: '22px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: '11px',
              lineHeight: '21px',
              textAlign: 'center',
              top: 0,
              left: '100%',
              marginLeft: '-18px',
              marginTop: '-4px',
              transition: 'all 300ms',
              opacity: count > 0 ? 1 : 0,
            }}
          >
            {count}
          </span>
        </div>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </div>

      {isLoading || !!!data ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '1.2',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',

              height: 'auto',
              lineHeight: '1.5',
              padding: '8px',
              boxSizing: 'border-box',
            },
          }}
          colData={colData}
          rowData={data.map(item => ({
            id: item.id,
            number: item?.number,
            innerName: item?.inner_name,
            issueDate: item?.issued_date,
            issuedByName: item?.issued_by_name,
            receivedByName: item?.received_by_name,
            receivedDate: item?.received_date ? item?.received_date : '-',
            isReturned: item?.is_returned,
            isAutogenerated: item?.is_autogenerated,
            returnedAt: item?.returned_at,
          }))}
          hasCheckboxSelection={false}
          noRowsText={'Записи отсутствуют'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </React.Fragment>
  )
}

export default TabIssueCase
