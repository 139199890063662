import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { changeEtalon } from "../../../store/slices/app/views/etalon"
import R from "../../../services/app/request.service"

const Method = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const isClearable = true
  const isSearchable = false

  const idMeasurement = useSelector(
    (state) => state.etalonReducer.createData.idMeasurement
  )
  const methodId = useSelector(
    (state) => state.etalonReducer.createData.methodId
  )

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeEtalon({
        value: "methodId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  useEffect(() => {
    if ( idMeasurement ) {
      ( async () => {
        const { data: listMethod } = await R.getMethodItem(
          `?measurement_id=${idMeasurement}`
        )
        const method = listMethod.data
        setData(method)
      })()
    }
  }, [idMeasurement])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
          fontWeight: 500
        }}
      >
        Методика поверки{" "}
        {!methodId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-0px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-0px" }}
          />
        )}
      </p>
      <Selector
        placeholder={"Выберите методику поверки из списка"}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        value={options.find((option) => option.value === methodId)}
        onChange={getValue}
        noOptionsMessage={() => "Сначала выберите область измерения"}
      />
    </React.Fragment>
  )
}

export default Method
