import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

const Input = ({
  type,
  pattern,
  className,
  placeholder,
  label,
  length,
  style,
  value,
  actions,
  isValid,
  autoComplete = 'off',
  isDisabled = false,
  isNotBordered = false,
  notRequired = false,
  noRequired = false,
  multiline,
  minRows,
  maxRows,
  sx,
}) => {
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = React.useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  return (
    <FormControl fullWidth sx={{ m: 1, margin: '0px' }} style={style}>
      <InputLabel
        htmlFor='outlined-adornment-amount'
        sx={{
          color: '#123532',
          fontFamily: "'Wix Madefor Display', sans-serif",
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id='outlined-adornment-amount'
        type={isPassword ? (showPassword ? 'text' : 'password') : type}
        startAdornment={
          <InputAdornment position='start' sx={sx}>
            {!!!noRequired && (
              <React.Fragment>
                {isValid !== undefined ? (
                  isValid ? (
                    <CheckCircleIcon sx={{ color: '#63E6BE' }} />
                  ) : (
                    <ErrorIcon sx={{ color: '#FFD43B' }} />
                  )
                ) : value || notRequired ? (
                  <CheckCircleIcon sx={{ color: '#63E6BE' }} />
                ) : (
                  <ErrorIcon sx={{ color: '#FFD43B' }} />
                )}
              </React.Fragment>
            )}
          </InputAdornment>
        }
        endAdornment={
          isPassword && (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleTogglePasswordVisibility}
                edge='end'
                style={{ marginRight: '0px' }}
              >
                {showPassword ? (
                  <VisibilityIcon sx={{ color: '#63E6BE', fontSize: 20 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: '#d7ddea', fontSize: 20 }} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        label={label}
        placeholder={placeholder}
        pattern={pattern}
        className={className}
        maxLength={length}
        value={value}
        autoComplete={autoComplete}
        disabled={isDisabled}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        onChange={event => {
          try {
            actions['change'](event.target.value)
          } catch {
            actions['change'] &&
              actions['change'][0] &&
              actions['change'][0]([...actions['change'][1], event.target.value])
          }
        }}
        onKeyUp={event => {
          // ----------------------------------------------------------------
          // actions['keyup'] && actions['keyup'][0]([...actions['keyup'][1], event.key])
          // ----------------------------------------------------------------

          actions['keyup'] && actions['keyup'][0](event.key)
        }}
        sx={{
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            margin: '0px',
            border: isNotBordered ? 'none' : '1px solid #d7ddea',
          },
        }}
      />
    </FormControl>
  )
}

export default Input
