// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/request.service'
import { changeArchived } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshArchives } from '../../../../store/slices/app/controlers/updater'

const Edit = () => {
  const { entry_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const accountNumber = useSelector(state => state.journalReducer.archived.accountNumber)
  const createdByName = useSelector(state => state.journalReducer.archived.createdByName)
  const issueDate = useSelector(state => state.journalReducer.archived.issueDate)
  const returnDate = useSelector(state => state.journalReducer.archived.returnDate)

  const handleSave = async () => {
    // ----------------------------------------------------------------
    // {
    //   "account_number": "01-01",
    //   "created_by_name": "Иванов И.И.",
    //   "issue_date": "2024-10-05",
    //   "return_date": "2024-10-05"
    // }
    // ----------------------------------------------------------------

    const changeData = {
      account_number: accountNumber,
      created_by_name: createdByName,
      issue_date: issueDate,
      return_date: returnDate,
    }

    const { status } = await R.editArchiveJournals(companyId, entry_id, changeData)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshArchives())
      navigate('/metriva/journals/archive')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals/archive')
  }

  useEffect(() => {
    ;(async () => {
      if (companyId) {
        const {
          data: { data: editJournalData },
        } = await R.getEditArchiveJournals(companyId, entry_id)

        console.log(editJournalData)

        dispatch(
          changeArchived({
            value: 'accountNumber',
            label: editJournalData.account_number ? editJournalData.account_number.toString() : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'createdByName',
            label: editJournalData.created_by_name ? editJournalData.created_by_name : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'issueDate',
            label: editJournalData.issue_date ? editJournalData.issue_date : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'returnDate',
            label: editJournalData.return_date ? editJournalData.return_date : '',
          }),
        )
      }
    })()
  }, [companyId, entry_id])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все архивные записи</p>
          </div>

          <div className='path_to_edit'>
            <h4>Редактирование архивной записи</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '25px' }}>Информация о журнале</h2>

            <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
              <div className='width50Percent'>
                <Input
                  label={'Учетный номер документа'}
                  placeholder={'Пример ввода - 00-03'}
                  type={'text'}
                  value={accountNumber}
                  actions={{
                    change: param =>
                      dispatch(
                        changeArchived({
                          value: 'accountNumber',
                          label: param.toString(),
                        }),
                      ),
                  }}
                />
              </div>
              <div className='width50Percent'>
                <Input
                  label={'Ф.И.О передавшего документ'}
                  placeholder={'Введите данные передавшего'}
                  type={'text'}
                  value={createdByName}
                  actions={{
                    change: param =>
                      dispatch(
                        changeArchived({
                          value: 'createdByName',
                          label: param,
                        }),
                      ),
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap'>
              <div className='width50Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Дата выдачи из архива
                  {!!issueDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={issueDate}
                  dateChange={date =>
                    dispatch(
                      changeArchived({
                        value: 'issueDate',
                        label: date.format('YYYY-MM-DD'),
                      }),
                    )
                  }
                />
              </div>

              <div className='width50Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Дата возврата в архив
                  {!!returnDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={returnDate}
                  dateChange={date =>
                    dispatch(
                      changeArchived({
                        value: 'returnDate',
                        label: date.format('YYYY-MM-DD'),
                      }),
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Редактировать запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Edit
