import React, { useState, useEffect } from 'react'
import Input from '../Input/InputMUI'

const SearchQuery = ({ searchQuery, setSearchQuery, disabled = false }) => {
  const [inputValue, setInputValue] = useState(searchQuery)

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearchQuery(inputValue)
    }, 500)

    return () => clearTimeout(delay)
  }, [inputValue, setSearchQuery])

  const clearSearchQuery = () => {
    setInputValue('')
  }

  const handleInputChange = param => {
    setInputValue(param)
  }

  useEffect(() => {
    setInputValue('')
  }, [])

  return (
    <React.Fragment>
      <div className='index_search'>
        <i
          className='fas fa-search icon_search'
          style={{ display: 'block', position: 'absolute', fontSize: '16px', marginLeft: '14px' }}
        />
        <Input
          type={'text'}
          disabled={disabled}
          isNotBordered={true}
          placeholder='Общий поиск по таблице'
          noRequired={true}
          value={inputValue}
          actions={{
            change: handleInputChange,
          }}
          style={{
            backgroundColor: 'transparent',
            marginLeft: '20px',
            width: '100%',
          }}
        />
        {inputValue && false && (
          <button
            className='clear_button'
            onClick={() => {
              clearSearchQuery()
            }}
          >
            <i className='fas fa-times' />
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

export default SearchQuery
