import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  masterArshinList: [],
  selectedMasterArshin: null,
  isLoading: true,
  meta: null | undefined,
  searchQuery: "",
  createData: {
    surname: "",
    name: "",
    patr: "",
    role: "",
    measurementIds: [],
  },
};

const masterArshinReducer = createSlice({
  name: "masterArshinReducer",
  initialState,
  reducers: {
    setList: (state, action) => {
      state.masterArshinList = action.payload;
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
    changeData: (state, action) => {
      state.createData[action.payload.value] = action.payload.label;
    },
    resetCreateData: (state) => {
      state.createData = initialState.createData;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setList,
  setMeta,
  changeData,
  resetCreateData,
  setSearchQuery,
  setIsLoading,
} = masterArshinReducer.actions;
export default masterArshinReducer.reducer;
