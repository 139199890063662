// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import R from '../../../services/app/request.service'

const MethodPoint = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPlaces = useSelector(state => state.casesReducer.createData.methodPlaces)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)

  const options = data
    ? data
        .filter(data => data.method_id === methodId)
        .filter(data => methodPlaces.includes(data.verification_method))
        .filter(data => typeMethodId.includes(data.verification_type))
        .map(data => ({
          value: data.id,
          label: `${data.name} (${data?.additional},  ${
            data?.verification_method === 'field' ? 'выездная' : 'лабораторная'
          } ${data?.verification_type === 'periodic' ? 'периодическая' : 'начальная'} поверка) `,
        }))
    : []

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'methodPoints',
        label: selectedIds,
      })
    )
    dispatch(changeData({ value: 'deviceIds', label: [] }))
    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )
    }
    dispatch(changeData({ value: 'deviceIds', label: [] }))
  }

  useEffect(() => {
    if (methodId) {
      ;(async () => {
        const { data: listMethod } = await R.getPointItem()
        const method = listMethod.data
        setData(method)
      })()
    }
  }, [methodId, typeMethodId])

  return (
    <React.Fragment>
      <div style={{ marginTop: '14px' }}>
        <p
          style={{
            marginBottom: '9px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
        >
          Пункты методики
          {methodPointIds.length === 0 ? (
            <ErrorIcon
              sx={{
                color: '#FFD43B',
                marginLeft: '8px',
                marginTop: '0px',
              }}
            />
          ) : (
            <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
          )}
        </p>
        <Selector
          placeholder={'Выберите необходимые пункты'}
          isClearable={false}
          isSearchable={false}
          isMulti={true}
          options={options}
          onChange={getValue}
          value={options.filter(option => methodPointIds.includes(option.value))}
          noOptionsMessage={() => 'Вы выбрали все доступные пункты для этой методики'}
        />
      </div>
    </React.Fragment>
  )
}

export default MethodPoint
