import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Company from '../app/pages/Сompanies/Сompanies'
import AddCompany from '../app/pages/Сompanies/AddСompanies'
import ShowCompany from '../app/pages/Сompanies/ShowСompanies'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteCompanies: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route index element={width > 1050 ? <Company /> : <NotForMobile />} />
      <Route path='create' element={width > 1050 ? <AddCompany /> : <NotForMobile />} />
      <Route path=':company_id' element={width > 1050 ? <ShowCompany /> : <NotForMobile />} />
    </Routes>
  )
}

export default RouteCompanies
