import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../../services/app/axios-config.service'
import Selector from '../Selector/reactSelect/Selector'

const InnerName = ({ innerName, setInnerName }) => {
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const token = useSelector(state => state.authReducer.authData.token)
  const [data, setData] = useState([])

  const options = data
    ? data.map(data => ({
        value: data?.inner_name,
        label: data?.inner_name,
      }))
    : []

  const getValue = selectedOption => {
    setInnerName(selectedOption ? selectedOption.value : null)
  }

  useEffect(() => {
    const fetchInnerName = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/inner-name-list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const innerName = response.data.data
        setData(innerName)
      } catch (error) {
        console.error(error)
      }
    }

    fetchInnerName()
  }, [token])

  return (
    <React.Fragment>
      <Selector
        placeholder={'Выберите комплект СП'}
        options={options}
        onChange={getValue}
        isClearable={true}
        isSearchable={true}
        value={innerName ? options.find(option => option.value === innerName) : null}
        noOptionsMessage={() => 'Нет доступных комплектов СП'}
      />
    </React.Fragment>
  )
}

export default InnerName
