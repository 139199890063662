// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeMeterWaterMod,
  changeMeterWaterMods,
} from '../../../../store/slices/app/views/meters'

import Input from '../../LocalElements/Input/InputMUI'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'
import { Button } from '@mui/material'

import { setOpen } from '../../../../store/slices/app/comps/bottomContainer'
import { changeMeterWater } from '../../../../store/slices/app/views/meters'

const AddMod = ({ widthAdditionals = false, isEdit = false }) => {
  const dispatch = useDispatch()

  const [localAdditionalName, setLocalAdditionalName] = useState('')
  const [localAdditionalMpiCold, setLocalAdditionalMpiCold] = useState('')
  const [localAdditionalMpiHot, setLocalAdditionalMpiHot] = useState('')

  const [validate, setValidate] = useState(false)

  const modName = useSelector(state => state.meterReducer.createMeterWater.mod.title)
  const modDiameter = useSelector(state => state.meterReducer.createMeterWater.mod.diameter)
  const modKoeff = useSelector(state => state.meterReducer.createMeterWater.mod.koeff)
  const modWater = useSelector(state => state.meterReducer.createMeterWater.mod.water)
  const vPositionQmin = useSelector(state => state.meterReducer.createMeterWater.mod.vPositionQmin)
  const vPositionQt = useSelector(state => state.meterReducer.createMeterWater.mod.vPositionQt)
  const vPositionQmax = useSelector(state => state.meterReducer.createMeterWater.mod.vPositionQmax)
  const hPositionQmin = useSelector(state => state.meterReducer.createMeterWater.mod.hPositionQmin)
  const hPositionQt = useSelector(state => state.meterReducer.createMeterWater.mod.hPositionQt)
  const hPositionQmax = useSelector(state => state.meterReducer.createMeterWater.mod.hPositionQmax)

  const additionals = useSelector(state => state.meterReducer.createMeterWater.mod.additionals)
  const mods = useSelector(state => state.meterReducer.createMeterWater.mods)
  const modeIndex = useSelector(state => state.meterReducer.createMeterWater.editIndex)

  const clearState = () => {
    dispatch(
      changeMeterWaterMod({
        value: 'title',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'diameter',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'koeff',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'water',
        label: null,
      }),
    )

    dispatch(
      changeMeterWaterMod({
        value: 'vPositionQmin',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'vPositionQt',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'vPositionQmax',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'hPositionQmin',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'hPositionQt',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'hPositionQmax',
        label: '',
      }),
    )
    dispatch(
      changeMeterWaterMod({
        value: 'additionals',
        label: [],
      }),
    )
  }

  const buildMod = () => {
    !!!isEdit &&
      dispatch(
        changeMeterWaterMods({
          name: modName,
          diameter: modDiameter,
          koeff: modKoeff,
          type: modWater,
          q_min_vertical: vPositionQmin,
          q_min_horizontal: hPositionQmin,
          q_t_vertical: vPositionQt,
          q_t_horizontal: hPositionQt,
          q_max_vertical: vPositionQmax,
          q_max_horizontal: hPositionQmax,
          additionals: additionals,
        }),
      )

    if (isEdit) {
      let newMods = mods.map((item, index) => {
        if (index !== modeIndex) return item
        if (index === modeIndex) {
          return {
            name: modName,
            diameter: modDiameter,
            koeff: modKoeff,
            type: modWater,
            q_min_vertical: vPositionQmin,
            q_min_horizontal: hPositionQmin,
            q_t_vertical: vPositionQt,
            q_t_horizontal: hPositionQt,
            q_max_vertical: vPositionQmax,
            q_max_horizontal: hPositionQmax,
            additionals: additionals,
          }
        }
      })

      dispatch(
        changeMeterWater({
          value: 'mods',
          label: newMods,
        }),
      )
    }

    clearState()
    dispatch(setOpen(false))
  }

  useEffect(() => {
    if (localAdditionalName && localAdditionalMpiCold && localAdditionalMpiHot) {
      dispatch(
        changeMeterWaterMod({
          value: 'additionals',
          label: [
            {
              text: localAdditionalName,
              mpi_hot: localAdditionalMpiHot,
              mpi_cold: localAdditionalMpiCold,
            },
          ],
        }),
      )
    } else {
      dispatch(
        changeMeterWaterMod({
          value: 'additionals',
          label: [],
        }),
      )
    }
  }, [localAdditionalName, localAdditionalMpiCold, localAdditionalMpiHot])

  useEffect(() => {
    false && console.log(modName)
    false && console.log(modDiameter)
    false && console.log(modWater)
    false && console.log(vPositionQmin)
    false && console.log(vPositionQt)
    false && console.log(vPositionQmax)
    false && console.log(hPositionQmin)
    false && console.log(hPositionQt)
    false && console.log(hPositionQmax)

    if (
      modName &&
      modDiameter &&
      // ----------------------------------------------------------------
      // modKoeff       &&
      // ----------------------------------------------------------------
      modWater &&
      vPositionQmin &&
      vPositionQt &&
      vPositionQmax &&
      hPositionQmin &&
      hPositionQt &&
      hPositionQmax
    ) {
      if (!!!widthAdditionals) {
        setValidate(true)
      } else {
        if (localAdditionalName && localAdditionalMpiCold && localAdditionalMpiHot) {
          setValidate(true)
        } else {
          setValidate(false)
        }
      }
    } else {
      setValidate(false)
    }
  }, [
    modName,
    modDiameter,
    modKoeff,
    modWater,
    vPositionQmin,
    vPositionQt,
    vPositionQmax,
    hPositionQmin,
    hPositionQt,
    hPositionQmax,
    widthAdditionals,
    localAdditionalName,
    localAdditionalMpiCold,
    localAdditionalMpiHot,
  ])

  useEffect(() => {
    if (additionals.length > 0) {
      setLocalAdditionalName(additionals[0].text)
      setLocalAdditionalMpiCold(additionals[0].mpi_cold)
      setLocalAdditionalMpiHot(additionals[0].mpi_hot)
    }
  }, [])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '24px' }}>
        <div style={{ width: '50%' }}>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите название модификации'}
            label={'Название модификации'}
            value={modName}
            actions={{
              change: param =>
                dispatch(
                  changeMeterWaterMod({
                    value: 'title',
                    label: param,
                  }),
                ),
            }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Selector
            placeholder={'Диаметр условного прохода, мм'}
            options={[
              { value: 10, label: 10 },
              { value: 15, label: 15 },
              { value: 20, label: 20 },
            ]}
            onChange={option => {
              dispatch(
                changeMeterWaterMod({
                  value: 'diameter',
                  label: option.value,
                }),
              )
            }}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных вариантов'}
            isMulti={false}
          />
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '24px' }}>
        <div style={{ width: '50%' }}>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'0.000000888'}
            label={'Коэффициент преобразования (необязательно)'}
            value={modKoeff}
            noRequired={true}
            actions={{
              change: param =>
                dispatch(
                  changeMeterWaterMod({
                    value: 'koeff',
                    label: param,
                  }),
                ),
            }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Selector
            placeholder={'Измеряемая вода'}
            options={[
              { value: 'cold', label: 'Холодная' },
              { value: 'hot', label: 'Горячая' },
            ]}
            onChange={option => {
              dispatch(
                changeMeterWaterMod({
                  value: 'water',
                  label: option.value,
                }),
              )
            }}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных вариантов'}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#eaedf375',
          padding: '18px',
          marginBottom: '14px',
          boxSizing: 'border-box',
          width: '100%',
          borderRadius: '12px',
          gap: '16px',
        }}
      >
        <h4>Вертикальное расположение (класс точности A / B)</h4>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qmin, м³/ч'}
              value={vPositionQmin}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'vPositionQmin',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qt, м³/ч'}
              value={vPositionQt}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'vPositionQt',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qmax, м³/ч'}
              value={vPositionQmax}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'vPositionQmax',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#eaedf375',
          padding: '18px',
          marginBottom: '14px',
          boxSizing: 'border-box',
          width: '100%',
          borderRadius: '12px',
          gap: '16px',
        }}
      >
        <h4>Горизонтальное расположение (класс точности B / C)</h4>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qmin, м³/ч'}
              value={hPositionQmin}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'hPositionQmin',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qt, м³/ч'}
              value={hPositionQt}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'hPositionQt',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
          <div className='width32Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'0.0000'}
              label={'Значение Qmax, м³/ч'}
              value={hPositionQmax}
              actions={{
                change: param =>
                  dispatch(
                    changeMeterWaterMod({
                      value: 'hPositionQmax',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
        </div>
      </div>
      {widthAdditionals && (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            backgroundColor: '#eaedf375',
            padding: '18px',
            marginBottom: '18px',
            boxSizing: 'border-box',
            width: '100%',
            borderRadius: '12px',
            gap: '16px',
          }}
        >
          <React.Fragment>
            <h3 style={{ marginBottom: '0px' }}>Информация о дополнительных условиях</h3>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div className='width32Percent'>
                <Input
                  className={'input'}
                  type={'text'}
                  placeholder={'Описание доп. условия'}
                  label={'Описание доп. условия'}
                  value={localAdditionalName || ''}
                  actions={{
                    change: param => setLocalAdditionalName(param),
                  }}
                />
              </div>
              <div className='width32Percent'>
                <Input
                  className={'input'}
                  type={'text'}
                  placeholder={'1'}
                  label={'МПИ холодное, лет'}
                  value={localAdditionalMpiCold || ''}
                  actions={{
                    change: param => setLocalAdditionalMpiCold(param),
                  }}
                />
              </div>
              <div className='width32Percent'>
                <Input
                  className={'input'}
                  type={'text'}
                  placeholder={'1'}
                  label={'МПИ горячее, лет'}
                  value={localAdditionalMpiHot || ''}
                  actions={{
                    change: param => setLocalAdditionalMpiHot(param),
                  }}
                />
              </div>
            </div>
            {false && (
              <div className='flexContainerWithGap' style={{ marginBottom: '23px' }}>
                <div style={{ width: '33.33333%' }}>
                  <Input
                    className={'input'}
                    type={'text'}
                    placeholder={'Описание доп. условия'}
                    label={'Описание доп. условия'}
                    value={'' || ''}
                    isDisabled={false}
                    actions={{
                      change: () => {},
                    }}
                  />
                </div>
                <div style={{ width: '33.33333%' }}>
                  <Input
                    className={'input'}
                    type={'text'}
                    placeholder={'1'}
                    label={'МПИ холодное, лет'}
                    value={'' || ''}
                    isDisabled={false}
                    actions={{
                      change: () => {},
                    }}
                  />
                </div>
                <div style={{ width: '33.33333%' }}>
                  <Input
                    className={'input'}
                    type={'text'}
                    placeholder={'1'}
                    label={'МПИ горячее, лет'}
                    value={'' || ''}
                    isDisabled={false}
                    actions={{
                      change: () => {},
                    }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <div className='flexContainerWithGap' style={{ justifyContent: 'flex-end' }}>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={buildMod}
            disabled={validate ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              marginTop: '14px',
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {isEdit === false ? 'Добавить модификацию' : 'Редактировать'}
          </Button>
        </div>
        {isEdit === false && (
          <div style={{ width: '240px' }}>
            <Button
              variant='outlined'
              onClick={clearState}
              disabled={false}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                marginTop: '14px',
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Сбросить все значения
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default AddMod
