import React from 'react'
import AccordionComponent from '../../../LocalElements/Accordion/Accordion'

const Scope = ({ data }) => {
  const methodTitle = [
    {
      id: 1,
      title: 'Тип поверки',
      desc: data?.point?.verification_type === 'periodic' ? 'Периодическая' : 'Первичная',
    },
    {
      id: 2,
      title: 'Способ проведения',
      desc: data?.point?.verification_method === 'field' ? 'На выезде' : 'В лаборатории',
    },
    { id: 3, title: 'Методика', desc: data?.method?.name },
    { id: 4, title: 'Применяемые пункты или разделы', desc: data?.point?.name },
  ]

  return (
    <React.Fragment>
      <AccordionComponent
        title='Рамки применения  методики'
        dataType='custom'
        backgroundColor='white'
        border={'1px solid #d7ddea'}
        data={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '306px' }}>
              {methodTitle.map(item => (
                <span key={item.id} style={{ color: '#4B5962' }}>
                  {item.title}
                </span>
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {methodTitle.map(item => (
                <span key={item.id} style={{ color: '#132532', fontWeight: 'bold' }}>
                  {item.desc}
                </span>
              ))}
            </div>
          </div>
        }
      />
    </React.Fragment>
  )
}

export default Scope
