import dayjs from 'dayjs'

export const createMethodology = {
  customerName: 'Физическое лицо',
  customerNameType: 'Физическое лицо',
  customerAddress: null,
  customerFullName: '',
  customerPhoneNumber: '',

  meterId: '',
  selectedMeter: [],
  modifications: [],

  selectedMod: null,
  defaultData: null,

  selectedAdditionals: null,
  mpiColdAdditionals: '',
  mpiHotAdditionals: '',

  valueMod: '',
  valueAdditional: null,
  valueAnotherMod: '',
  is_custom_modification: false,

  meterFifNumber: '',
  meterNameSi: '',
  meterHot: '',
  meterCold: '',
  meterFactoryNumber: '',
  meterYear: '',
  meterType: '',

  diameter: '',
  meterLocation: '',

  q_min: '',
  q_t: '',
  q_max: '',
  q_actual: '',
  q_min_limit: '',
  q_max_limit: '',

  q_i_1: null,
  q_i_2: null,
  q_i_3: null,
  q_i_4: null,
  q_i_5: null,
  q_i_6: null,
  q_i_7: null,
  q_i_8: null,
  q_i_9: null,

  start_1: null,
  finish_1: null,
  start_2: null,
  finish_2: null,
  start_3: null,
  finish_3: null,
  start_4: null,
  finish_4: null,
  start_5: null,
  finish_5: null,
  start_6: null,
  finish_6: null,
  start_7: null,
  finish_7: null,
  start_8: null,
  finish_8: null,
  start_9: null,
  finish_9: null,

  v_y_i_1: null,
  v_y_i_2: null,
  v_y_i_3: null,
  v_y_i_4: null,
  v_y_i_5: null,
  v_y_i_6: null,
  v_y_i_7: null,
  v_y_i_8: null,
  v_y_i_9: null,

  vyi1Fault: null,
  vyi2Fault: null,
  vyi3Fault: null,
  vyi4Fault: null,
  vyi5Fault: null,
  vyi6Fault: null,
  vyi7Fault: null,
  vyi8Fault: null,
  vyi9Fault: null,

  conversion_factor: null,

  impulses_1: null,
  impulses_2: null,
  impulses_3: null,

  isMechanicalAccepted: null,
  isTightnessAccepted: null,
  isTotalAccepted: false,
  isMarkPassport: false,
  isMarkSi: false,

  airTempStart: '',
  airTempFinish: '',
  humidityStart: '',
  humidityFinish: '',

  pressureStart: '',
  pressureFinish: '',
  waterTempStart: '',
  waterTempFinish: '',

  verificationDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  nextVerificationDate: '',
  nextVerificationDateAutoGenerate: '',
  agreementNumber: '',

  indications: [],
  indicationsValuesEdit: [],
  opticalSensorValuesEdit: [],
  conditions: [],
  indicationsPass: [],
  conditionsValuesEdit: [],

  images: [],
  selectedImages: [],
  file: [],
  fileBase64: [],
  comment: '',
  imgBase64: [],

  doChecked: false,
}
