// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/rules-of-hooks */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import css from '../styles.css.ts'

import DatePicker from '../../app/components/LocalElements/DatePicker/DatePicker.jsx'
import InputElement from '../../app/components/LocalElements/Input/InputMUI.jsx'
import { Tooltip } from '@mui/material'
import Switch from '../../app/components/LocalElements/Switch/Switch.jsx'
import { changeMainData, changePersonData } from '../../store/slices/admin/admin.js'

import { IJCR } from '../types.ts'

const { ContentRow } = css

const JCR: React.FC<IJCR> = (props: IJCR): ReactElement => {
  const dispatch = useDispatch()

  const changeRowDataPerson = (label: any): void => {
    props.context === 'main' &&
      dispatch(
        changeMainData({
          index: props.contextIndex,
          value: 'responsible_name',
          label,
        }),
      )
  }

  const changeRowDataAutogenerate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.context === 'main' &&
      dispatch(
        changeMainData({
          index: props.contextIndex,
          value: 'is_autogenerate',
          label: event.target.checked,
        }),
      )
  }

  const changeRowDataNumber = (label: any): void => {
    props.context === 'main' &&
      dispatch(
        changeMainData({
          index: props.contextIndex,
          value: 'account_number',
          label,
        }),
      )

    props.context === 'person' &&
      dispatch(
        changePersonData({
          index: props.contextIndex,
          value: 'account_number',
          label,
        }),
      )
  }

  return (
    <React.Fragment>
      <ContentRow gap={props.gap ? props.gap : 0} mb={props.mb ? props.mb : 0}>
        <div style={{ display: 'block', width: '25%' }}>
          <span>{props.name}</span>
        </div>
        <div style={{ display: 'block', width: '15%' }}>
          <DatePicker selectedDate={props.date.value} dateChange={props.date.action} />
        </div>
        <div style={{ display: 'block', width: '15%' }}>
          <InputElement
            type={'text'}
            placeholder={'00-00'}
            value={props.number}
            noRequired={true}
            actions={{
              change: changeRowDataNumber,
            }}
            pattern={undefined}
            className={undefined}
            label={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
            isValid={undefined}
          />
        </div>
        <div style={{ display: 'block', width: '30%' }}>
          {props.executorControllable !== false ? (
            <InputElement
              type={'text'}
              placeholder={'Введите ответственного'}
              value={props.executor}
              noRequired={true}
              actions={{
                change: changeRowDataPerson,
              }}
              pattern={undefined}
              className={undefined}
              label={undefined}
              length={undefined}
              style={undefined}
              multiline={undefined}
              minRows={undefined}
              maxRows={undefined}
              sx={undefined}
              isValid={undefined}
            />
          ) : (
            <span>{props.executor}</span>
          )}
        </div>
        <div style={{ display: 'block', width: '15%', paddingLeft: '14px' }}>
          {props.actions !== false && (
            <React.Fragment>
              {props.journalId === 5 ? (
                <Tooltip
                  title='Автозаполнение данных - дата возврата и отметка о возврате'
                  placement='top'
                >
                  <Switch
                    checked={props.autogenerated}
                    disabled={props.autogeneratedRequired}
                    onChange={changeRowDataAutogenerate}
                  />
                </Tooltip>
              ) : props.journalId === 2 ? (
                <Tooltip
                  title='Автозаполнение данных - температура, влажность и давление'
                  placement='top'
                >
                  <Switch
                    checked={props.autogenerated}
                    disabled={props.autogeneratedRequired}
                    onChange={changeRowDataAutogenerate}
                  />
                </Tooltip>
              ) : (
                <Switch
                  checked={props.autogenerated}
                  disabled={props.autogeneratedRequired}
                  onChange={changeRowDataAutogenerate}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </ContentRow>
    </React.Fragment>
  )
}

export default JCR
