// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import Approved from '../../LocalElements/Filter/Approved'
import R from '../../../../services/app/request.service'
import { changeReceived, resetReceived } from '../../../../store/slices/app/views/journals'
import { refreshReceiveds } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const measurementId = useSelector(state => state.journalReducer.received.measurementId)
  const nameTypeSi = useSelector(state => state.journalReducer.received.nameTypeSi)
  const fifNumber = useSelector(state => state.journalReducer.received.fifNumber)
  const factoryNumber = useSelector(state => state.journalReducer.received.meterFactoryNumber)
  const arshinName = useSelector(state => state.journalReducer.received.customerArshinName)
  const verDate = useSelector(state => state.journalReducer.received.verificationDate)
  const protocolNumber = useSelector(state => state.journalReducer.received.protocolNumber)
  const arshinMasterName = useSelector(state => state.journalReducer.received.arshinMasterName)
  const receivedDate = useSelector(state => state.journalReducer.received.receivedDate)
  const receivedByName = useSelector(state => state.journalReducer.received.receivedByName)
  const issuedName = useSelector(state => state.journalReducer.received.issuedByName)
  const issuedDate = useSelector(state => state.journalReducer.received.issuedDate)
  const totalAccepted = useSelector(state => state.journalReducer.received.totalAccepted)
  const arshinId = useSelector(state => state.journalReducer.received.arshinId)
  const typeMethod = useSelector(state => state.journalReducer.received.typeMethod)

  const changeMeasurement = param => {
    dispatch(changeReceived({ value: 'measurementId', label: param }))
  }
  const changeNameTypeSi = param => {
    dispatch(changeReceived({ value: 'nameTypeSi', label: param }))
  }
  const changeFifNumber = param => {
    dispatch(changeReceived({ value: 'fifNumber', label: param }))
  }
  const changeFactoryNumber = param => {
    dispatch(changeReceived({ value: 'meterFactoryNumber', label: param }))
  }
  const changeArshinName = param => {
    dispatch(changeReceived({ value: 'customerArshinName', label: param }))
  }
  const changeIsArshinName = event => {
    dispatch(changeReceived({ value: 'customerArshinName', label: event.target.value }))
  }
  const changeProtocolNumber = param => {
    dispatch(changeReceived({ value: 'protocolNumber', label: param }))
  }
  const changeMasterName = param => {
    dispatch(changeReceived({ value: 'arshinMasterName', label: param }))
  }
  const changeReceivedByName = param => {
    dispatch(changeReceived({ value: 'receivedByName', label: param }))
  }
  const changeIssuedNamed = param => {
    dispatch(changeReceived({ value: 'issuedByName', label: param }))
  }
  const changeArshinId = param => {
    dispatch(changeReceived({ value: 'arshinId', label: param }))
  }
  const changeTypeMethod = event => {
    dispatch(changeReceived({ value: 'typeMethod', label: event.target.value }))
    dispatch(changeReceived({ value: 'issuedByName', label: '' }))
    dispatch(changeReceived({ value: 'receivedByName', label: '' }))
    dispatch(
      changeReceived({
        value: 'receivedDate',
        label: '',
      }),
    )
    dispatch(
      changeReceived({
        value: 'issuedDate',
        label: '',
      }),
    )
  }
  const changeTotalAccepted = event => {
    const value = event.target.value === 'true'
    dispatch(changeReceived({ value: 'totalAccepted', label: value }))
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurementId,
      customer_arshin_name: arshinName,
      work_content: 'no-content',
      fif_number: fifNumber,
      name_type_si: nameTypeSi,
      meter_factory_number: factoryNumber,
      verification_date: verDate,
      protocol_number: protocolNumber,
      arshin_master_name: arshinMasterName,
      total_accepted: totalAccepted,
      arshin_id: arshinId,
      verification_method: typeMethod,
    }

    if (typeMethod === 'laboratory') {
      data.issued_by_name = issuedName
      data.issued_date = issuedDate
      data.received_by_name = receivedByName
      data.received_date = receivedDate
    }

    const { status } = await R.addReceivedJournalRow(companyId, data)

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshReceiveds())
      dispatch(resetReceived())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все записи документа</p>
          </div>

          <div className='path_to_edit'>
            <h4>Журнал учета поступивших на поверку СИ и проведения поверочных работ</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '24px' }}>Информация о журнале</h2>
            <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
              <div className='width32Percent'>
                <Measurement
                  measurementId={measurementId}
                  setMeasurementId={changeMeasurement}
                  label={{
                    text: 'Область измерения',
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Наименование и тип СИ'}
                  placeholder={'Введите наименование и тип СИ'}
                  type={'text'}
                  value={nameTypeSi}
                  actions={{
                    change: changeNameTypeSi,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Номер СИ в госреестре'}
                  placeholder={'Пример ввода - 349392-93'}
                  type={'text'}
                  value={fifNumber}
                  actions={{
                    change: changeFifNumber,
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
              <div className='width32Percent'>
                <Input
                  label={'Заводской номер СИ'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  value={factoryNumber}
                  actions={{
                    change: changeFactoryNumber,
                  }}
                />
              </div>

              <div style={{ gap: '8px', display: 'flex', flexFlow: 'column', width: '66%' }}>
                <Input
                  label={'Владелец СИ в системе Аршин'}
                  placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                  type={'text'}
                  value={arshinName}
                  actions={{
                    change: changeArshinName,
                  }}
                />
                <Approved
                  approved={arshinName}
                  changeApproved={changeIsArshinName}
                  labelTrue={'Физическое лицо'}
                  labelFalse={'Юридическое лицо'}
                  valueTrue={'Физическое лицо'}
                  valueFalse={'Юридическое лицо'}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
              <div className='width32Percent'>
                <DatePicker
                  selectedDate={verDate}
                  label='Дата поверки'
                  dateChange={date =>
                    date
                      ? dispatch(
                          changeReceived({
                            value: 'verificationDate',
                            label: date.format('YYYY-MM-DD'),
                          }),
                        )
                      : dispatch(
                          changeReceived({
                            value: 'verificationDate',
                            label: null,
                          }),
                        )
                  }
                />
              </div>
              <div className='width32Percent'>
                <Input
                  label={'Номер протокола поверки'}
                  type={'text'}
                  placeholder={'Введите номер протокола'}
                  value={protocolNumber}
                  actions={{
                    change: changeProtocolNumber,
                  }}
                />
              </div>
              <div className='width32Percent'>
                <Input
                  label={'ФИО проводившего поверку'}
                  type={'text'}
                  placeholder={'Введите ФИО проводившего поверку'}
                  value={arshinMasterName}
                  actions={{
                    change: changeMasterName,
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
              <div className='width32Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Способ проведения поверки
                </p>
                <Approved
                  approved={typeMethod}
                  changeApproved={changeTypeMethod}
                  labelTrue={'На выезде'}
                  labelFalse={'В лаборатории'}
                  valueTrue={'field'}
                  valueFalse={'laboratory'}
                />
              </div>

              {typeMethod === 'laboratory' && (
                <div className='width67Percent'>
                  <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                    <div style={{ width: '50%' }}>
                      <p
                        style={{
                          marginBottom: '9px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Дата поступления СИ{' '}
                        {!!receivedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </p>
                      <DatePicker
                        selectedDate={receivedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'receivedDate',
                              label: date.format('YYYY-MM-DD'),
                            }),
                          )
                        }
                        maxDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div style={{ width: '50%' }}>
                      <p
                        style={{
                          marginBottom: '9px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        ФИО принявшего СИ{' '}
                        {!!receivedByName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </p>
                      <Input
                        placeholder={'Введите ФИО принявшего СИ'}
                        noRequired={true}
                        type={'text'}
                        value={receivedByName}
                        actions={{
                          change: changeReceivedByName,
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div style={{ width: '50%' }}>
                      <p
                        style={{
                          marginBottom: '9px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Дата выдачи СИ{' '}
                        {!!issuedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </p>
                      <DatePicker
                        selectedDate={issuedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'issuedDate',
                              label: date.format('YYYY-MM-DD'),
                            }),
                          )
                        }
                        minDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div style={{ width: '50%' }}>
                      <p
                        style={{
                          marginBottom: '9px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        ФИО выдавшего СИ{' '}
                        {!!issuedName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </p>
                      <Input
                        type={'text'}
                        placeholder={'Введите ФИО выдавшего СИ'}
                        noRequired={true}
                        value={issuedName}
                        actions={{
                          change: changeIssuedNamed,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='flexContainerWithGap'>
              <div className='width32Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Заключение поверки
                </p>
                <Approved
                  approved={totalAccepted}
                  changeApproved={changeTotalAccepted}
                  labelTrue={'Пригоден'}
                  labelFalse={'Непригоден'}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>

              <div className='width32Percent' style={{ marginTop: '22px' }}>
                <Input
                  label={'Номер свидетельства / извещения'}
                  placeholder={'Пример ввода - С-АИК/24-09-2024/372708413'}
                  type={'text'}
                  value={arshinId}
                  actions={{
                    change: changeArshinId,
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Добавить запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Add
