// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'

import SelectJournal from '../../components/Journals/Select/SelectJournal'
import MasterArshinSelect from '../../components/LocalElements/Filter/MasterArshinSelect'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import { setName } from '../../../store/slices/app/controlers/pageName'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import TableConditions from '../../components/Journals/JournalConditionsCases/Table'
import TableReceived from '../../components/Journals/JournalReceivedForVerification/Table'
import TableTickets from '../../components/Journals/JournalTickets/Table'
import TablePersonalMetrolog from '../../components/Journals/JournalPersonal/Table'
import TableIssueCase from '../../components/Journals/JournalIssueCase/Table'

import { setMasterArshin, setJournalId } from '../../../store/slices/app/views/journals'
import {
  setArchiveFromDate,
  setArchiveToDate,
  setComplete,
  setJournalIdFilter,
} from '../../../store/slices/app/views/journals'
import { setJournalSettings } from '../../../store/slices/admin/admin'
import {
  setOpen as setOpenSnackbar,
  setType,
  setMessage,
} from '../../../store/slices/app/comps/snackbar'

import R from '../../../services/app/request.service'

const MagazinesIndex = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const journalId = useSelector(state => state.journalReducer.journalId)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const resetActions = [setComplete, setArchiveFromDate, setArchiveToDate, setJournalIdFilter]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeMasterId = param => {
    dispatch(setMasterArshin(param))
  }

  const changeViewVariant = param => {
    if (companyId) {
      if (userRole === 'brigadier' && !!!masterArshine) {
        param === 0 && navigate('/metriva/journals')

        if (param === 1) {
          dispatch(setOpenSnackbar(true))
          dispatch(setType('warning'))
          dispatch(setMessage('Для перехода в раздел настроек выберите метролога'))
        }
      } else {
        param === 0 && navigate('/metriva/journals')
        param === 1 && navigate('/metriva/settings/journals')
        param === 2 && navigate('archive')
      }
    } else {
      param === 0 && navigate('/metriva/journals')

      if (param === 1) {
        dispatch(setOpenSnackbar(true))
        dispatch(setType('warning'))
        dispatch(setMessage('Для перехода в раздел настроек выберите компанию'))
      }

      if (param === 2) {
        dispatch(setOpenSnackbar(true))
        dispatch(setType('warning'))
        dispatch(setMessage('Для перехода в раздел архивов выберите компанию'))
      }
    }
  }

  useEffect(() => {
    dispatch(setName('История журналов'))
  }, [])

  useEffect(() => {
    handleResetFilter()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (companyId) {
        const { data } = await R.getJournalSettings(companyId)
        if (userRole === 'super' || userRole === 'admin' || true) {
          dispatch(setJournalSettings({ value: 'mainData', label: data.main }))
          dispatch(setJournalSettings({ value: 'personalData', label: data.personal }))
          dispatch(setJournalSettings({ value: 'specificData', label: data.specific }))
        }
      }
    })()
  }, [companyId])

  useEffect(() => {
    if (userRole === 'brigadier' || userRole === 'metrologist') {
      dispatch(setJournalId(6))
    }
  }, [userRole, dispatch])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
            marginBottom: '10px',
          }}
        >
          <FilterCompany />
        </div>
      )}

      {(userRole === 'brigadier' || userRole === 'metrologist') && (
        <h2 style={{ marginBottom: '12px' }}>Персональный журнал инженера по метрологии</h2>
      )}

      <div style={{ margin: '0px 0 20px' }}>
        <BasicTabs
          viewVariant={0}
          callback={changeViewVariant}
          tabs={
            userRole === 'super' || userRole === 'admin'
              ? [
                  { label: 'Актуальная версия документа' },
                  { label: 'Настройки' },
                  { label: 'Архивные' },
                ]
              : userRole === 'brigadier' || userRole === 'metrologist'
                ? [{ label: 'Актуальная версия документа' }, { label: 'Настройки' }].filter(tab => {
                    if (isTrusted === true) {
                      return tab
                    } else {
                      if (tab.label === 'Актуальная версия документа') {
                        return tab
                      }
                    }
                  })
                : []
          }
        />
      </div>

      <div style={{ display: 'flex', gap: '16px', marginBottom: '12px' }}>
        {(userRole === 'super' || userRole === 'admin') && (
          <div className='selector' style={{ width: '605px' }}>
            <SelectJournal />
          </div>
        )}

        {(journalId === 6 || userRole === 'brigadier' || userRole === 'metrologist') && (
          <div className='selector' style={{ width: '605px' }}>
            <MasterArshinSelect
              masterId={masterArshine}
              setMasterId={changeMasterId}
              label={{
                text: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Ответственный за документ
                    <Tooltip
                      title='Владелец комплекта СП, чьи данные передаются во ФГИС «Аршин» и ФСА'
                      placement='top'
                      style={{ marginLeft: '7px' }}
                    >
                      <i
                        className='fas fa-question-circle question'
                        style={{ marginLeft: '7px', cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                ),
                topBg: '#f8f9fc',
                bottomBg: 'white',
              }}
            />
          </div>
        )}
      </div>

      {(!journalId || !companyId) && (
        <div className='box' style={{ marginTop: '12px' }}>
          <div className='page_null'>
            <h4 className='context-null-table'>
              Для просмотра записей выберите компанию и документ
            </h4>
          </div>
        </div>
      )}

      {!masterArshine && journalId === 6 && (
        <div
          className='page_null'
          style={{
            borderRadius: '12px',
            background: 'white',
            marginTop: '12px',
          }}
        >
          <h4
            style={
              width > 1050
                ? {
                    fontSize: '20.8px',
                    letterSpacing: '0.4px',
                    color: '#132532',
                    fontWeight: '400',
                  }
                : {
                    fontSize: '17px',
                    letterSpacing: '0.4px',
                    color: '#132532',
                    fontWeight: '400',
                    textAlign: 'center',
                  }
            }
          >
            Для просмотра записей выберите ответственного за документ
          </h4>
        </div>
      )}

      {companyId && (
        <React.Fragment>
          {journalId === 2 && <TableConditions />}
          {journalId === 3 && <TableReceived />}
          {journalId === 4 && <TableTickets />}
          {journalId === 5 && <TableIssueCase />}
          {journalId === 6 && !!masterArshine && <TablePersonalMetrolog />}
        </React.Fragment>
      )}
    </main>
  )
}

export default MagazinesIndex
