import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  casesList: [],
  selectedCase: null,
  isLoading: true,
  meta: null | undefined,
  searchQuery: '',
  createData: {
    companyId: '',
    measurementId: null,
    methodId: '',
    typeMethodId: [],
    methodPoints: [],
    methodPlaces: [],
    innerName: '',
    arshinName: '',
    snils: '',
    deviceIds: [],
    kitIds: [],
  },
}

const casesReducer = createSlice({
  name: 'casesReducer',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.casesList = action.payload
    },
    setMeta: (state, action) => {
      state.meta = action.payload
    },
    changeData: (state, action) => {
      state.createData[action.payload.value] = action.payload.label
    },
    resetCreateData: state => {
      state.createData = initialState.createData
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setList, setMeta, changeData, resetCreateData, setSearchQuery, setIsLoading } =
  casesReducer.actions
export default casesReducer.reducer
