import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logo: "",
  role: "",
  surname: "",
  name: "",
  isTrusted: "",
  companyId: "",
};

export const headerReducer = createSlice({
  name: "headerReducer",
  initialState,
  reducers: {
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setSurName: (state, action) => {
      state.surname = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setIsTrusted: (state, action) => {
      state.isTrusted = action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
  },
});

export const {
  setLogo,
  setRole,
  setSurName,
  setName,
  setIsTrusted,
  setCompanyId,
} = headerReducer.actions;
export default headerReducer.reducer;
