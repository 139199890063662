// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import Button from "@mui/material/Button"
import FilterAltIcon from "@mui/icons-material/FilterAlt"

import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/app/request.service"
import Table from "../../components/LocalElements/Table/Table"
import FilterCompany from "../../components/LocalElements/Filter/FilterCompany"
import SearchQuery from "../../components/LocalElements/Search/SearchQuery"
import Skeleton from "../../components/LocalElements/Skeleton/Skeleton"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../../store/slices/app/comps/modal"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"
import {
  setDataEtalon,
  setSearchQuery as setSearchQueryEtalon,
} from "../../../store/slices/app/views/etalon"
import { refreshEtalons } from "../../../store/slices/app/controlers/updater"
import { setName } from "../../../store/slices/app/controlers/pageName"

const IndexEtalon = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const isLoading = useSelector((state) => state.etalonReducer.isLoading)
  const dataEtalon = useSelector((state) => state.etalonReducer.dataEtalon)

  const etalonSearchQuery = useSelector(
    (state) => state.etalonReducer.searchQuery
  )

  const changeSearchQuery = (param) => {
    dispatch(setSearchQueryEtalon(param))
  }

  const handleCreate = () => {
    navigate("/metriva/etalon/create")
  }

  const handleEdit = (param) => {
    navigate(`/metriva/etalon/${param.row.id}/edit`)
  }

  const handleDelete = async (etalonId) => {
    const { status } = await R.deleteEtalon(etalonId)
    if (status === 200) {
      dispatch(refreshEtalons())
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Эталон удален"))
      dispatch(setOpen(false))
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к API."))
    }
  }

  let colData = [
    {
      field: "nameKits",
      headerName: "Наименование эталона",
      width: 400,
    },
    {
      field: "typeKits",
      headerName: "Тип эталона",
      width: 200,
    },
    {
      field: "manufacturerNumber",
      headerName: "Зав. номер",
      width: 200,
    },
    {
      field: "regNumber",
      headerName: "Рег. номер",
      width: 200,
    },
    {
      field: "signKits",
      headerName: "Свид-во о поверке",
      width: 300,
    },
    {
      field: "verificationPlace",
      headerName: "Место проведения",
      width: 300,
    },
    {
      field: "link",
      headerName: "Ссылка на поверку",
      width: 400,
    },
    {
      field: "dateFrom",
      headerName: "Дата поверки",
      width: 200,
    },
    {
      field: "dateTo",
      headerName: "Действует до",
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 200,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
              gap: "14px",
            }}
          >
            <span
              onClick={() => {
                dispatch(setOpen(true))
                dispatch(setTitle("Вы действительно хотите удалить эталон?"))
                dispatch(
                  setChildren("Отменить действие будет уже невозможно.")
                )
                dispatch(setConfirmAction(() => handleDelete(localItem.id)))
              }}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-trash icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span>
          </div>,
        ]
      },
    },
  ]

  if ( userRole === "super" ) {
    colData.splice(9, 0, {
      field: "company",
      headerName: "Компания",
      width: 400,
    })
  }

  useEffect(() => {
    dispatch(setName("База эталонов"))
  }, [])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      { width > 1050 && (
        <React.Fragment>
          <div className="header_box">
          <div 
            className="flexContainerWithGap"
            style={{
              flexWrap: 'wrap',
              width: '100%',
              gap: '12px',
              position: 'relative',
            }}
          >
              { userRole === "super" && (
                <div 
                  className="selector"
                  style={{ width: '100%', position: 'relative' }}
                >
                  <FilterCompany />
                </div>
              )}

              <div 
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px'
                }}
              >
                <div style={{ width: width > 1050 ? "18%" : "100%" }}>
                  <Button
                    variant="contained"
                    onClick={handleCreate}
                    style={{
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: "14px 20px",
                      margin: 0,
                      borderRadius: "12px",
                      width: "100%",
                      height: "54.4px"
                    }}
                  >
                    Добавить эталон
                  </Button>
                </div>
                <div style={{ width: width > 1050 ? "82%" : "100%" }}>
                  <SearchQuery
                    searchQuery={etalonSearchQuery}
                    setSearchQuery={changeSearchQuery}
                  />
                </div>
                <div style={{ width: width > 1050 ? "16%" : "100%", position: "relative" }}>
                  <Button
                    variant="outlined"
                    disabled={true}
                    onClick={() => {}}
                    startIcon={<FilterAltIcon />}
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: "0px 20px",
                      borderRadius: "12px",
                      height: "54.4px"
                    }}
                  >
                    Фильтры
                  </Button>
                </div>
              </div>
            </div>
          </div>

          { isLoading ? (
            <Skeleton />
          ) : (
            <Table
              css={{
                marginTop: "12px",
                backgroundColor: "#ffffff",
                border: "1px solid #d7ddea",
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
              }}
              colData={colData}
              rowData={dataEtalon.map((item) => ({
                id: item.id,
                nameKits: item.device.name,
                typeKits: item.device_sign,
                manufacturerNumber: item.device_manufacturer_number,
                regNumber: item.reg_number,
                link: item.device_link,
                signKits: item.device_description,
                dateFrom: moment(item.verified_at).format("DD-MM-YYYY"),
                dateTo: moment(item.expired_at).format("DD-MM-YYYY"),
                company: item.company?.name,
                verificationPlace: item.verification_place,
              }))}
              rowCallback={handleEdit}
              hasCheckboxSelection={false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          )}
        </React.Fragment>
      )}
      { width <= 1050 && (
        <span
          style={{
            color: "gray",
            width: "100%",
            textAlign: "center",
            display: "block",
            lineHeight: "23px",
            marginTop: "30px",
          }}
        >
          Воспользуйтесь версией для ПК чтобы просмотреть страницу
        </span>
      )}
    </main>
  )
}

export default IndexEtalon
