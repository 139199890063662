/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from 'react-redux'
import api from '../../services/app/axios-config.service'
import Cookies from 'js-cookie'
import { saveAs } from 'file-saver'
import { setOpen as setOpenAlert, setMessage, setType } from '../../store/slices/app/comps/snackbar'

const useExcelCsvPdf = () => {
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const journalId = useSelector(state => state.journalReducer.journalId)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  // ----------------------------------------------------------------
  // журнал учета поступивших на поверку СП
  // ----------------------------------------------------------------

  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)

  // ----------------------------------------------------------------
  // журнал регистрации условий хранений СП
  // ----------------------------------------------------------------

  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const completeJournal = useSelector(state => state.journalReducer.complete)
  const dateFromJournal = useSelector(state => state.journalReducer.dateFrom)
  const dateUntilJournal = useSelector(state => state.journalReducer.dateUntil)
  const creatorJournal = useSelector(state => state.journalReducer.creator)
  const totalAcceptedJournal = useSelector(state => state.journalReducer.totalAccepted)
  const createDateFromJournal = useSelector(state => state.journalReducer.createDateFrom)
  const createDateUntilJournal = useSelector(state => state.journalReducer.createDateUntil)

  // ----------------------------------------------------------------
  // журнал графиков заявок
  // ----------------------------------------------------------------

  const measurementId = useSelector(state => state.journalReducer.tickets.measurementId)
  const arshinName = useSelector(state => state.journalReducer.tickets.arshinName)
  const createdAtFrom = useSelector(state => state.journalReducer.tickets.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.tickets.createdAtTo)
  const isChecked = useSelector(state => state.journalReducer.tickets.isChecked)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.tickets.verificationDateFrom,
  )
  const verificationDateTo = useSelector(state => state.journalReducer.tickets.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.tickets.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.tickets.createdByRole)

  // ----------------------------------------------------------------
  // журнал выдачи средств поверки
  // ----------------------------------------------------------------

  const measurementIdIssueCases = useSelector(
    state => state.journalReducer.issueCaseFilter.measurementId,
  )
  const innerName = useSelector(state => state.journalReducer.issueCaseFilter.innerName)
  const issuedDateFrom = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateFrom)
  const issuedDateTo = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateTo)
  const receivedByName = useSelector(state => state.journalReducer.issueCaseFilter.receivedByName)
  const isReturned = useSelector(state => state.journalReducer.issueCaseFilter.isReturned)
  const completenessStatusIssueCases = useSelector(
    state => state.journalReducer.issueCaseFilter.completenessStatus,
  )
  const createdByRoleIssueCases = useSelector(
    state => state.journalReducer.issueCaseFilter.createdByRole,
  )
  const verificationDateFromIssueCases = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateFrom,
  )
  const verificationDateToIssueCases = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateTo,
  )

  // ----------------------------------------------------------------
  // журнал персонального поверителя
  // ----------------------------------------------------------------

  const measurementIdPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.measurementId,
  )
  const createdAtFromPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.createdAtFrom,
  )
  const createdAtToPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.createdAtTo,
  )
  const verificationDateFromPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.verificationDateFrom,
  )
  const verificationDateToPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.verificationDateTo,
  )
  const completenessStatusPersonalMetrolog = useSelector(
    state => state.journalReducer.personal.completenessStatus,
  )
  const createdByRolePersonalMetrolog = useSelector(
    state => state.journalReducer.personal.createdByRole,
  )

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const downloadFile = async (type, apiUrl) => {
    try {
      const token = Cookies.get('accessToken')
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }
      const responseType = type === 'pdf' ? 'arraybuffer' : 'blob'
      const response = await api.get(apiUrl, {
        headers,
        responseType,
      })

      const currentDate = new Date()
        .toLocaleString('ru-RU', {
          timeZone: 'Europe/Moscow',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        .replace(/[.:]/g, '-')

      let filename = `${currentDate}`

      switch (journalId) {
        case 2:
          filename = `Zhurnal_registracii_uslovij_hraneniya_sredstv_poverki_${currentDate}`
          break
        case 3:
          filename = `Zhurnal_ucheta_postupivshih_na_poverku_SP_i_provvedeniya_poverokhnyh_rabot_${currentDate}`
          break
        case 4:
          filename = `Zhurnal_registracii_zayavok_na_poverky_${currentDate}`
          break
        case 5:
          filename = `Zhurnal_vydachi_sredstv_poverki_${currentDate}`
          break
        case 6:
          filename = `Personalnyi_zhurnal_metrologa_${currentDate}`
          break
        default:
          break
      }

      if (type === 'excel') {
        filename += '.xlsx'
      } else if (type === 'csv') {
        filename += '.csv'
      } else if (type === 'pdf') {
        filename += '.pdf'
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }

      if (type !== 'pdf') {
        saveAs(new Blob([response.data]), filename)
      }

      dispatch(setMessage('Файл загружается.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
    } catch (error) {
      console.error(error)
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к api.'))
    }
  }

  const getApiUrl = type => {
    let apiUrl = ''
    switch (journalId) {
      case 2:
        if (type === 'excel') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/excel?completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
        } else if (type === 'csv') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/csv?completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
        } else if (type === 'pdf') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/pdf`
        }
        break
      case 3:
        if (type === 'excel') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/excel?&verification_date_from=${dateFromJournal}&verification_date_to=${dateUntilJournal}&completeness_status=${completeJournal}&created_by_role=${creatorJournal}&total_accepted_status=${totalAcceptedJournal}&arshin_name=&measurement_id=${measurementIdFilter}&created_at_from=${createDateFromJournal}&created_at_to=${createDateUntilJournal}`
        } else if (type === 'csv') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/csv?&verification_date_from=${dateFromJournal}&verification_date_to=${dateUntilJournal}&completeness_status=${completeJournal}&created_by_role=${creatorJournal}&total_accepted_status=${totalAcceptedJournal}&arshin_name=&measurement_id=${measurementIdFilter}&created_at_from=${createDateFromJournal}&created_at_to=${createDateUntilJournal}`
        } else if (type === 'pdf') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/pdf`
        }
        break

      case 4:
        if (type === 'excel') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/excel?&measurement_id=${measurementId}&arshin_name=${arshinName}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&is_checked=${isChecked}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}`
        } else if (type === 'csv') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/csv?&measurement_id=${measurementId}&arshin_name=${arshinName}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&is_checked=${isChecked}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}`
        } else if (type === 'pdf') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/pdf`
        }
        break
      case 5:
        if (type === 'excel') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/excel?measurement_id=${measurementIdIssueCases}&inner_name=${innerName}&issued_date_from=${issuedDateFrom}&issued_date_to=${issuedDateTo}&received_by_name=${receivedByName}&is_returned=${isReturned}&completeness_status=${completenessStatusIssueCases}&created_by_role=${createdByRoleIssueCases}`
        } else if (type === 'csv') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/csv?measurement_id=${measurementIdIssueCases}&inner_name=${innerName}&issued_date_from=${issuedDateFrom}&issued_date_to=${issuedDateTo}&received_by_name=${receivedByName}&is_returned=${isReturned}&completeness_status=${completenessStatusIssueCases}&created_by_role=${createdByRoleIssueCases}`
        } else if (type === 'pdf') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/pdf`
        }
        break
      case 6:
        if (type === 'excel') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${masterArshine}/excel?measurement_id=${measurementIdPersonalMetrolog}&created_at_from=${createdAtFromPersonalMetrolog}&created_at_to=${createdAtToPersonalMetrolog}&verification_date_from=${verificationDateFromPersonalMetrolog}&verification_date_to=${verificationDateToPersonalMetrolog}&completeness_status=${completenessStatusPersonalMetrolog}&created_by_role=${createdByRolePersonalMetrolog}`
        } else if (type === 'csv') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${masterArshine}/csv?measurement_id=${measurementIdPersonalMetrolog}&created_at_from=${createdAtFromPersonalMetrolog}&created_at_to=${createdAtToPersonalMetrolog}&verification_date_from=${verificationDateFromPersonalMetrolog}&verification_date_to=${verificationDateToPersonalMetrolog}&completeness_status=${completenessStatusPersonalMetrolog}&created_by_role=${createdByRolePersonalMetrolog}`
        } else if (type === 'pdf') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${masterArshine}/pdf`
        }
        break
      default:
        break
    }

    return apiUrl
  }

  const handleDownload = type => {
    const apiUrl = getApiUrl(type)
    if (apiUrl) {
      downloadFile(type, apiUrl)
    }
  }

  return { handleDownload }
}

export default useExcelCsvPdf
