import React, { useState } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PencilIcon = props => {
  const [hovered, setHovered] = useState(false)

  return (
    <SvgIcon
      {...props}
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        cursor: 'pointer',
        marginRight: '1.9px',
        marginTop: '1px',
        transition: 'fill 0.3s',
        transform: 'scale(0.85)',
        '& path': {
          fill: hovered ? '#0084E2' : '#899298',
        },
      }}
    >
      <path d='M9.69667 15.559L9.6967 15.559L15.8599 9.39574C15.0211 9.04662 14.0276 8.47315 13.088 7.53354C12.1482 6.59379 11.5747 5.60013 11.2256 4.76123L5.0623 10.9246L5.06229 10.9246C4.58134 11.4055 4.34084 11.646 4.13403 11.9112C3.89006 12.224 3.68089 12.5624 3.51023 12.9205C3.36556 13.2241 3.25801 13.5467 3.04291 14.192L1.90865 17.5948C1.8028 17.9123 1.88545 18.2624 2.12214 18.4991C2.35883 18.7358 2.70893 18.8185 3.02648 18.7126L6.42926 17.5783C7.07454 17.3632 7.3972 17.2557 7.70076 17.111C8.05886 16.9404 8.3973 16.7312 8.71009 16.4872C8.97523 16.2804 9.21574 16.0399 9.69667 15.559Z' />
      <path d='M17.5701 7.68552C18.8499 6.40577 18.8499 4.33088 17.5701 3.05112C16.2904 1.77137 14.2155 1.77137 12.9357 3.05112L12.1965 3.79032C12.2067 3.82089 12.2172 3.85188 12.228 3.88327C12.499 4.66422 13.0102 5.68798 13.9719 6.64966C14.9336 7.61134 15.9573 8.12255 16.7383 8.39349C16.7695 8.40433 16.8004 8.41478 16.8308 8.42486L17.5701 7.68552Z' />
    </SvgIcon>
  )
}

export default PencilIcon
