import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import Input from '../../../../LocalElements/Input/InputMUI'

import R from '../../../../../../services/app/request.service'
import { changeProperty } from '../state'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'

import css from '../style.css'

const { Container, ConditionsContainer, ConditionsBox, ParagraphConditions } = css

const Conditions = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [tariffIds, setTariffIds] = useState(null)

  const conditionsValuesEdit = useSelector(state => state.mi15922015Reducer.conditionsValuesEdit)

  const meterType = useSelector(state => state.mi15922015Reducer.meterType)

  const airTempStart = useSelector(state => state.mi15922015Reducer.airTempStart)
  const airTempFinish = useSelector(state => state.mi15922015Reducer.airTempFinish)
  const humidityStart = useSelector(state => state.mi15922015Reducer.humidityStart)
  const humidityFinish = useSelector(state => state.mi15922015Reducer.humidityFinish)
  const pressureStart = useSelector(state => state.mi15922015Reducer.pressureStart)
  const pressureFinish = useSelector(state => state.mi15922015Reducer.pressureFinish)
  const waterTempStart = useSelector(state => state.mi15922015Reducer.waterTempStart)
  const waterTempFinish = useSelector(state => state.mi15922015Reducer.waterTempFinish)

  const [airTempStartCopy, setAirTempStartCopy] = useState('')
  const [airTempFinishCopy, setAirTempFinishCopy] = useState('')

  const [humidityStartCopy, setHumidityStartCopy] = useState('')
  const [humidityFinishCopy, setHumidityFinishCopy] = useState('')

  const [pressureStartCopy, setPressureStartCopy] = useState('')
  const [pressureFinishCopy, setPressureFinishCopy] = useState('')

  const [waterTempStartCopy, setWaterTempStartCopy] = useState('')
  const [waterTempFinishCopy, setWaterTempFinishCopy] = useState('')

  const handleAirTempStartChange = param => {
    setAirTempStartCopy(param.replace(',', '.'))
    dispatch(changeProperty({ value: 'airTempStart', label: param.replace(',', '.') }))
  }

  const handleAirTempFinishChange = param => {
    setAirTempFinishCopy(param.replace(',', '.'))
    dispatch(changeProperty({ value: 'airTempFinish', label: param.replace(',', '.') }))
  }

  const handleHumidityStartChange = param => {
    setHumidityStartCopy(param.replace(',', '.'))
    dispatch(changeProperty({ value: 'humidityStart', label: param.replace(',', '.') }))
  }

  const handleHumidityFinishChange = param => {
    setHumidityFinishCopy(param.replace(',', '.'))
    dispatch(
      changeProperty({
        value: 'humidityFinish',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handlePressureStartChange = param => {
    setPressureStartCopy(param.replace(',', '.'))
    dispatch(changeProperty({ value: 'pressureStart', label: param.replace(',', '.') }))
  }

  const handlePressureFinishChange = param => {
    setPressureFinishCopy(param.replace(',', '.'))
    dispatch(
      changeProperty({
        value: 'pressureFinish',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleWaterTempStartChange = param => {
    const newValue = param.replace(',', '.')
    setWaterTempStartCopy(newValue)
    dispatch(
      changeProperty({
        value: 'waterTempStart',
        label: newValue,
      }),
    )

    if (newValue === '') {
      dispatch(
        changeProperty({
          value: 'waterTempFinish',
          label: '',
        }),
      )
    } else {
      let newWaterTempFinish
      if (meterType === 'hot') {
        newWaterTempFinish = (parseFloat(newValue) + parseFloat(getRandomNumber(1, 2))).toFixed(1)
      } else if (meterType === 'cold') {
        newWaterTempFinish = (parseFloat(newValue) - parseFloat(getRandomNumber(1, 2))).toFixed(1)
      }
      dispatch(
        changeProperty({
          value: 'waterTempFinish',
          label: newWaterTempFinish.toString(),
        }),
      )
    }
  }

  const handleWaterTempFinishChange = param => {
    setWaterTempFinishCopy(param.replace(',', '.'))
    dispatch(
      changeProperty({
        value: 'waterTempFinish',
        label: param.replace(',', '.'),
      }),
    )
  }

  const getRandomNumber = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(1)
  }

  const autogenerateProtocol = () => {
    const randomAirTempStart = getRandomNumber(21, 25)
    const randomHumidityStart = getRandomNumber(33, 57)
    const randomPressureStart = getRandomNumber(87, 102)

    const randomAirTempFinish = (
      parseFloat(randomAirTempStart) + parseFloat(getRandomNumber(-3, 3))
    ).toFixed(1)
    const randomHumidityFinish = (
      parseFloat(randomHumidityStart) + parseFloat(getRandomNumber(-3, 3))
    ).toFixed(1)
    const randomPressureFinish = (
      parseFloat(randomPressureStart) + parseFloat(getRandomNumber(-3, 3))
    ).toFixed(1)

    dispatch(
      changeProperty({
        value: 'airTempStart',
        label: randomAirTempStart,
      }),
    )
    dispatch(
      changeProperty({
        value: 'airTempFinish',
        label: randomAirTempFinish,
      }),
    )
    dispatch(
      changeProperty({
        value: 'humidityStart',
        label: randomHumidityStart,
      }),
    )
    dispatch(
      changeProperty({
        value: 'humidityFinish',
        label: randomHumidityFinish,
      }),
    )
    dispatch(
      changeProperty({
        value: 'pressureStart',
        label: randomPressureStart,
      }),
    )
    dispatch(
      changeProperty({
        value: 'pressureFinish',
        label: randomPressureFinish,
      }),
    )

    if (meterType === 'hot') {
      const randomTempStart = parseFloat(getRandomNumber(60, 75))
      const randomTempFinish = (randomTempStart + parseFloat(getRandomNumber(1.1, 2.0))).toFixed(1)

      dispatch(
        changeProperty({
          value: 'waterTempStart',
          label: randomTempStart.toString(),
        }),
      )

      dispatch(
        changeProperty({
          value: 'waterTempFinish',
          label: randomTempFinish.toString(),
        }),
      )
    } else if (meterType === 'cold') {
      const randomTempStart = getRandomNumber(10, 12)

      dispatch(
        changeProperty({
          value: 'waterTempStart',
          label: randomTempStart,
        }),
      )
      dispatch(
        changeProperty({
          value: 'waterTempFinish',
          label: (parseFloat(randomTempStart) - parseFloat(getRandomNumber(0.1, 1.0)))
            .toFixed(1)
            .toString(),
        }),
      )
    }
  }

  useEffect(() => {
    if (conditionsValuesEdit.length > 0) {
      const initialValuesStart = conditionsValuesEdit[0]
      setAirTempStartCopy(initialValuesStart.temperature_environment.toString())
      setHumidityStartCopy(initialValuesStart.relative_humidity.toString())
      setPressureStartCopy(initialValuesStart.atmospheric_pressure.toString())
      if (initialValuesStart && initialValuesStart.water_temp) {
        setWaterTempStartCopy(initialValuesStart.water_temp.toString())
      } else {
        setWaterTempStartCopy('')
      }

      const initialValuesFinish = conditionsValuesEdit[1]
      setAirTempFinishCopy(initialValuesFinish.temperature_environment.toString())
      setHumidityFinishCopy(initialValuesFinish.relative_humidity.toString())
      setPressureFinishCopy(initialValuesFinish.atmospheric_pressure.toString())

      if (initialValuesFinish && initialValuesFinish.water_temp) {
        setWaterTempFinishCopy(initialValuesFinish.water_temp.toString())
      } else {
        setWaterTempFinishCopy('')
      }
    }
  }, [conditionsValuesEdit])

  const Reset = () => {
    dispatch(
      changeProperty({
        value: 'airTempStart',
        label: '',
      }),
    )
    setAirTempStartCopy('')
    dispatch(
      changeProperty({
        value: 'airTempFinish',
        label: '',
      }),
    )
    setAirTempFinishCopy('')
    dispatch(
      changeProperty({
        value: 'humidityStart',
        label: '',
      }),
    )
    setHumidityStartCopy('')
    dispatch(
      changeProperty({
        value: 'humidityFinish',
        label: '',
      }),
    )
    setHumidityFinishCopy('')
    dispatch(
      changeProperty({
        value: 'pressureStart',
        label: '',
      }),
    )
    setPressureStartCopy('')
    dispatch(
      changeProperty({
        value: 'pressureFinish',
        label: '',
      }),
    )
    setPressureFinishCopy('')
    dispatch(
      changeProperty({
        value: 'waterTempStart',
        label: '',
      }),
    )
    setWaterTempStartCopy('')
    dispatch(
      changeProperty({
        value: 'waterTempFinish',
        label: '',
      }),
    )
    setWaterTempFinishCopy('')
  }

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'conditions',
        label: [
          {
            temperature_environment: airTempStart
              ? parseFloat(airTempStart)
              : parseFloat(airTempStartCopy),
            relative_humidity: humidityStart
              ? parseFloat(humidityStart)
              : parseFloat(humidityStartCopy),
            atmospheric_pressure: pressureStart
              ? parseFloat(pressureStart)
              : parseFloat(pressureStartCopy),
            water_temp: waterTempStart
              ? parseFloat(waterTempStart)
              : parseFloat(waterTempStartCopy),
          },
          {
            temperature_environment: airTempFinish
              ? parseFloat(airTempFinish)
              : parseFloat(airTempFinishCopy),
            relative_humidity: humidityFinish
              ? parseFloat(humidityFinish)
              : parseFloat(humidityFinishCopy),
            atmospheric_pressure: pressureFinish
              ? parseFloat(pressureFinish)
              : parseFloat(pressureFinishCopy),
            water_temp: waterTempFinish
              ? parseFloat(waterTempFinish)
              : parseFloat(waterTempFinishCopy),
          },
        ],
      }),
    )
  }, [
    airTempStart,
    airTempFinish,
    humidityStart,
    humidityFinish,
    pressureStart,
    pressureFinish,
    waterTempStart,
    waterTempFinish,
    airTempStartCopy,
    airTempFinishCopy,
    humidityStartCopy,
    humidityFinishCopy,
    pressureStartCopy,
    pressureFinishCopy,
    waterTempStartCopy,
    waterTempFinishCopy,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()
      const tariffIds = data.tariffs.map(tariff => tariff.id)
      setTariffIds(tariffIds.length > 0 ? tariffIds : null)
    })()
  }, [])

  return (
    <React.Fragment>
      <Container style={{ alignItems: 'flex-start' }}>
        <h2 className='width50Percent' style={{ marginBottom: width > 1050 ? '20px' : '10px' }}>
          Соблюдение условий поверки
        </h2>

        {tariffIds && tariffIds.includes(5) && (
          <div className='width50Percent'>
            <Container style={width > 1050 ? {} : { gap: '14px' }}>
              <div className='width50Percent'>
                <Button
                  variant='contained'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    color: 'white',
                    width: '100%',
                  }}
                  disabled={!meterType}
                  onClick={autogenerateProtocol}
                >
                  Тестовая среда
                </Button>
              </div>
              <div className='width50Percent'>
                <Button
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    width: '100%',
                  }}
                  onClick={Reset}
                  disabled={
                    !airTempStart &&
                    !airTempFinish &&
                    !humidityStart &&
                    !humidityFinish &&
                    !pressureStart &&
                    !pressureFinish &&
                    !waterTempStart &&
                    !waterTempFinish
                  }
                >
                  Сбросить все значения
                </Button>
              </div>
            </Container>
            <ParagraphConditions
              style={width <= 1050 ? { textAlign: 'center', width: '100%' } : {}}
            >
              Генерация данных указаны как пример в помощь поверителю. При создании протокола все
              данные необходимо скорректировать
            </ParagraphConditions>
          </div>
        )}
      </Container>

      <ConditionsContainer margin={'0 0 14px'}>
        <h3 style={{ marginBottom: '15px' }}>На начало поверки</h3>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура окружающей среды, °C
          </p>
          <Input
            type={'text'}
            value={airTempStart ? airTempStart : airTempStartCopy}
            actions={{ change: handleAirTempStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру среды'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Относительная влажность, %
          </p>
          <Input
            type={'text'}
            value={humidityStart ? humidityStart : humidityStartCopy}
            actions={{ change: handleHumidityStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните влажность'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Атмосферное давление, кПа
          </p>
          <Input
            type={'text'}
            value={pressureStart ? pressureStart : pressureStartCopy}
            actions={{ change: handlePressureStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните атмосферное давление'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 0px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура воды, °С
          </p>
          <Input
            type={'text'}
            value={waterTempStart ? waterTempStart : waterTempStartCopy}
            actions={{ change: handleWaterTempStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру воды'}
          />
        </ConditionsBox>
      </ConditionsContainer>

      <ConditionsContainer>
        <h3 style={{ marginBottom: '15px' }}>По окончанию поверки</h3>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура окружающей среды, °C
          </p>
          <Input
            type={'text'}
            value={airTempFinish ? airTempFinish : airTempFinishCopy}
            actions={{ change: handleAirTempFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру среды'}
          />
        </ConditionsBox>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Относительная влажность, %
          </p>
          <Input
            type={'text'}
            value={humidityFinish ? humidityFinish : humidityFinishCopy}
            actions={{ change: handleHumidityFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните влажность'}
          />
        </ConditionsBox>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Атмосферное давление, кПа
          </p>
          <Input
            type={'text'}
            value={pressureFinish ? pressureFinish : pressureFinishCopy}
            actions={{ change: handlePressureFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните атмосферное давление'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 0px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура воды, °С
          </p>
          <Input
            type={'text'}
            value={waterTempFinish ? waterTempFinish : waterTempFinishCopy}
            actions={{ change: handleWaterTempFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру воды'}
          />
        </ConditionsBox>
      </ConditionsContainer>
    </React.Fragment>
  )
}

export default Conditions
