// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../../../services/app/axios-config.service'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'

import {
  setJournalId,
  setJournalIdFilter,
  setMasterArshin,
} from '../../../../store/slices/app/views/journals'

const SelectorMagazine = ({ isLabel = true, variant = 'default', disabled = false }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const journalId = useSelector(state => state.journalReducer.journalId)
  const journalIdFilter = useSelector(state => state.journalReducer.journalIdFilter)
  const token = useSelector(state => state.authReducer.authData.token)

  const options = data
    .filter(item => item.is_selectable)
    .map(item => ({
      value: item.id,
      label: item.name,
    }))

  const getValue = selectedOption => {
    variant === 'default' && dispatch(setJournalId(selectedOption ? selectedOption.value : ''))
    variant === 'filter' && dispatch(setJournalIdFilter(selectedOption ? selectedOption.value : ''))
    dispatch(setMasterArshin(''))
  }

  useEffect(() => {
    const fetchMagazine = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/journal-types/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const magazines = response.data.data
        setData(magazines)
      } catch (error) {
        console.error(error)
      }
    }

    fetchMagazine()
  }, [dispatch, token])

  return (
    <React.Fragment>
      {variant === 'default' && (
        <Selector
          placeholder={'Выберите вид журнала'}
          isClearable={true}
          isSearchable={true}
          options={options}
          onChange={getValue}
          value={options.find(option => option.value === journalId)}
          isDisabled={disabled}
          label={
            isLabel
              ? {
                  text: 'Документ',
                  topBg: '#f8f9fc',
                  bottomBg: 'white',
                }
              : null
          }
        />
      )}
      {variant === 'filter' && (
        <Selector
          placeholder={'Выберите вид журнала'}
          isClearable={true}
          isSearchable={true}
          options={options}
          onChange={getValue}
          value={options.find(option => option.value === journalIdFilter)}
          isDisabled={disabled}
        />
      )}
    </React.Fragment>
  )
}

export default SelectorMagazine
