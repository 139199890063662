import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const FilterIcon = props => (
  <SvgIcon
    {...props}
    width='22'
    height='16'
    viewBox='0 0 22 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.25 10C9.90685 10 11.25 11.3431 11.25 13C11.25 14.6569 9.90685 16 8.25 16C6.59315 16 5.25 14.6569 5.25 13C5.25 11.3431 6.59315 10 8.25 10Z'
      fill='#0084E2'
    />
    <path
      d='M13.25 0C11.5931 -7.24234e-08 10.25 1.34315 10.25 3C10.25 4.65685 11.5931 6 13.25 6C14.9069 6 16.25 4.65685 16.25 3C16.25 1.34315 14.9069 7.24234e-08 13.25 0Z'
      fill='#0084E2'
    />
    <path
      d='M7.75 2.20852C8.16421 2.20852 8.5 2.54431 8.5 2.95852C8.5 3.37273 8.16421 3.70852 7.75 3.70852L0.749999 3.70852C0.335786 3.70852 0 3.37273 0 2.95852C0 2.54431 0.335786 2.20852 0.75 2.20852H7.75Z'
      fill='#0084E2'
    />
    <path
      d='M13.75 12.2085C13.3358 12.2085 13 12.5443 13 12.9585C13 13.3727 13.3358 13.7085 13.75 13.7085H20.75C21.1642 13.7085 21.5 13.3727 21.5 12.9585C21.5 12.5443 21.1642 12.2085 20.75 12.2085H13.75Z'
      fill='#0084E2'
    />
    <path
      d='M0 12.9585C0 12.5443 0.335786 12.2085 0.75 12.2085H2.75C3.16421 12.2085 3.5 12.5443 3.5 12.9585C3.5 13.3727 3.16421 13.7085 2.75 13.7085H0.75C0.335786 13.7085 0 13.3727 0 12.9585Z'
      fill='#0084E2'
    />
    <path
      d='M20.75 2.20852C21.1642 2.20852 21.5 2.54431 21.5 2.95852C21.5 3.37273 21.1642 3.70852 20.75 3.70852L18.75 3.70852C18.3358 3.70852 18 3.37273 18 2.95852C18 2.54431 18.3358 2.20852 18.75 2.20852H20.75Z'
      fill='#0084E2'
    />
  </SvgIcon>
)

export default FilterIcon
