import React, { useState } from "react"
import SvgIcon from "@mui/material/SvgIcon"

const UserBanIcon = (props) => {
  const [hover, setHover] = useState(false)

  return (
    <SvgIcon
      {...props}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor: "pointer",
        transition: "fill 0.3s",
        transform: "scale(0.85)",
        fill: hover ? "#FF6F61" : "#E74953",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3919 14.8149C13.9997 14.8149 12.8711 15.9436 12.8711 17.3358C12.8711 17.7945 12.9932 18.2241 13.2072 18.5945L16.6507 15.1511C16.2802 14.9371 15.8506 14.8149 15.3919 14.8149ZM17.6099 16.1364L14.1925 19.5537C14.5492 19.7471 14.9575 19.8566 15.3919 19.8566C16.7841 19.8566 17.9128 18.728 17.9128 17.3358C17.9128 16.9013 17.8032 16.493 17.6099 16.1364ZM11.4961 17.3358C11.4961 15.1842 13.2403 13.4399 15.3919 13.4399C16.4819 13.4399 17.4683 13.8885 18.1746 14.6092C18.8625 15.3112 19.2878 16.2745 19.2878 17.3358C19.2878 19.4874 17.5435 21.2316 15.3919 21.2316C14.3307 21.2316 13.3673 20.8063 12.6654 20.1185C11.9446 19.4122 11.4961 18.4257 11.4961 17.3358Z"
      />
      <path d="M14.9336 5.8776C14.9336 7.90265 13.292 9.54427 11.2669 9.54427C9.24188 9.54427 7.60026 7.90265 7.60026 5.8776C7.60026 3.85256 9.24188 2.21094 11.2669 2.21094C13.292 2.21094 14.9336 3.85256 14.9336 5.8776Z" />
      <path d="M13.3708 12.4665C11.4628 13.2594 10.1211 15.141 10.1211 17.3359C10.1211 18.5426 10.5284 19.6565 11.2099 20.5442C3.93359 20.5347 3.93359 18.6915 3.93359 16.4193C3.93359 14.1411 7.21684 12.2943 11.2669 12.2943C11.9981 12.2943 12.7044 12.3545 13.3708 12.4665Z" />
    </SvgIcon>
  )
}

export default UserBanIcon
