import React, { useState, useEffect } from "react";
import Selector from "../LocalElements/Selector/reactSelect/Selector";
import { useDispatch, useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { changeData } from "../../../store/slices/app/views/users";
import R from "../../../services/app/request.service";

const SelectedCases = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const companyId = useSelector(
    (state) => state.usersReducer.createData.companyId
  );
  const casesIds = useSelector(
    (state) => state.usersReducer.createData.casesIds
  );

  const options = data
    ? data.map((item) => ({
        value: item.id,
        label: item.inner_name,
      }))
    : [];

  const getValue = (selectedOptions) => {
    const selectedIds = selectedOptions.map((options) => options.value);
    dispatch(changeData({ value: "casesIds", label: selectedIds }));
  };

  useEffect(() => {
    (async () => {
      const { data: listCases } = await R.getCasesItem(
        `?company_id=${companyId}`
      );
      const data = listCases.data;
      setData(data);
    })();
  }, [companyId]);

  const hasCase = Array.isArray(casesIds) && casesIds.length > 0;

  return (
    <React.Fragment>
      <span
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Доступные комплекты СП
        {hasCase ? (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        ) : (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        )}
      </span>
      <Selector
        placeholder={"Выберите комплекты СП"}
        options={options}
        value={options.filter((option) => casesIds.includes(option.value))}
        onChange={getValue}
        isMulti={true}
        isSearchable={false}
        noOptionsMessage={() => "Нет доступных комплектов"}
      />
    </React.Fragment>
  );
};

export default SelectedCases;
