// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import api from "../../../services/app/axios-config.service"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { changeMainData } from "../../../store/slices/app/views/companies"

const RegionCompany = ({ validationErrors }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.authReducer.authData.token)
  const [data, setData] = useState(null)
  const isSearchable = true
  const isClearable = true

  const regionId = useSelector(
    (state) => state.companiesReducer.createData.main.regionId
  )

  const options = data
    ? data.map((data) => ({
      value: data.id,
      label: data.name,
    }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeMainData({
        value: "regionId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const fetchRegionsData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/create`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
      const response = await api.get(apiUrl, { headers })
      const regionData = response.data.regions
      setData(regionData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchRegionsData()
  }, [])

  return (
    <React.Fragment>
      <div className="width32Percent" style={{ marginRight: "10px" }}>
        <p style={{ marginBottom: "9px", fontWeight: 500 }}>Регион компании</p>
        <Selector
          placeholder={"Выберите регион"}
          options={options}
          onChange={getValue}
          isSearchable={isSearchable}
          isClearable={isClearable}
          noOptionsMessage={() => "Нет доступных регионов"}
          value={options.find((option) => option.value === regionId)}
        />

        { validationErrors["region_id"] && (
          <div className="error">{validationErrors["region_id"]}</div>
        )}
      </div>
    </React.Fragment>
  )
}

export default RegionCompany
