import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { styled } from "@mui/material/styles"

import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import CloseIcon from "@mui/icons-material/Close"

import { settingsImageViewer } from "../../../../store/slices/app/comps/modal"

const ImageViewer = () => {
  const dispatch = useDispatch()

  const open = useSelector((state) => state.modalReducer.imageViewerModal.open)
  const currentImage = useSelector((state) => state.modalReducer.imageViewerModal.currentImage)

  const [scale, setScale] = useState(1)

  const handleClose = () => {
    dispatch(settingsImageViewer({ value: "open", label: false }))
    setScale(1)
  }

  const BootstrapDialog = styled(Dialog)(() => ({
    "& .MuiPaper-root": {
      width: "100vw",
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100vh",
      margin: 0,
      backgroundColor: "transparent",
      borderRadius: "0px",
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "white",
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
    "& .MuiDialogActions-root": {
      backgroundColor: "rgba(19, 37, 50, 0.3)",
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    "& .MuiDialogContent-root": {
      position: "relative",
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
      justifyContent: "space-around",
      padding: 0,
    },
  }))

  return (
    <React.Fragment>
      <BootstrapDialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          width: "100vw",
        }}
      >
        <DialogContent>
          <div className="img--zoom">
            <img
              src={currentImage}
              alt="фото протокола"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                transform: `scale(${scale})`,
                transformOrigin: "0 0",
              }}
            />
          </div>
          <IconButton
            onClick={handleClose}
            aria-label="add"
            style={{
              backgroundColor: "white",
              width: "44px",
              height: "44px",
              display: "block",
              position: "absolute",
              left: "100%",
              marginLeft: "-68px",
              top: 0,
              marginTop: "24px",
            }}
          >
            <CloseIcon
              sx={{
                color: "black",
                marginBottom: "-2px",
              }}
            />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "300px",
            }}
          >
            <IconButton
              disabled={scale > 1 ? false : true}
              onClick={() => {
                setScale((prev) => {
                  let newValue = prev - 0.2
                  if (newValue > 0.99) return newValue
                  else return prev
                })
              }}
              style={{
                border: "0.6px solid white",
                width: "40px",
                height: "40px",
                opacity: scale === 1 ? 0.6 : 1,
              }}
            >
              <RemoveIcon sx={{ color: "white" }} />
            </IconButton>
            <span
              style={{ color: "white", fontWeight: "200", fontSize: "15px" }}
            >
              {(scale * 100).toFixed(0)}%
            </span>
            <IconButton
              disabled={scale === 2 ? true : false}
              onClick={() => {
                setScale((prev) => {
                  let newValue = prev + 0.2
                  if (newValue <= 2) return newValue
                  else return prev
                })
              }}
              style={{
                border: "0.6px solid white",
                width: "40px",
                height: "40px",
                opacity: scale === 2 ? 0.6 : 1,
              }}
            >
              <AddIcon sx={{ color: "white" }} />
            </IconButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}

export default ImageViewer
