// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import axios from 'axios'
import Cookies from 'js-cookie'

import D from '../../store/localStorage/dispatcher'
import { store } from '../../store/store'
import { setAuthToken } from '../../store/slices/app/controlers/auth'

import { incrementLoading, decrementLoading } from '../../store/slices/app/controlers/loadingSlice'
import { setOpen, setMessage, setType } from '../../store/slices/app/comps/snackbar'

const createAxiosInstance = () => {
  const instance = axios.create({
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  })

  instance.interceptors.request.use(
    config => {
      const state = store.getState()
      const token = state.authReducer.authData.token
      const tokenCookies = Cookies.get('accessToken')

      if (!config.headers['Authorization']) {
        if (token) {
          config.headers['Authorization'] = `Bearer ${token ? token : tokenCookies}`
        } else {
          delete config.headers['Authorization']
        }
      }

      store.dispatch(incrementLoading())

      // ----------------------------------------------------------------
      // store.dispatch(setOpen(true))
      // store.dispatch(setType('info'))
      // store.dispatch(setMessage('Загрузка данных с сервера'))
      // ----------------------------------------------------------------
      return config
    },
    error => {
      store.dispatch(decrementLoading())
      store.dispatch(setOpen(false))
      Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    response => {
      store.dispatch(decrementLoading())
      return response
    },
    error => {
      store.dispatch(decrementLoading())

      if (error.response && error.response.status === 401) {
        Cookies.remove('accessToken')

        store.dispatch(setAuthToken(''))

        D.cls()
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export default createAxiosInstance()
