// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Button from "@mui/material/Button"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Input from "../../components/LocalElements/Input/InputMUI"
import SelectCompany from "../../components/Etalon/SelectCompany"
import SelectType from "../../components/Etalon/SelectType"
import DatePicker from "../../components/LocalElements/DatePicker/DatePicker"
import SelectMeasurement from "../../components/Etalon/SelectMeasurement"
import Method from "../../components/Etalon/Method"

import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/app/request.service"

import { changeEtalon, resetCreateData } from "../../../store/slices/app/views/etalon"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"
import { refreshEtalons } from "../../../store/slices/app/controlers/updater"

import useWindowDimensions from "../../hooks/useWindowDimensions"

const AddEtalon = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyId = useSelector((state) => state.etalonReducer.createData.companyId)
  const etalonId = useSelector((state) => state.etalonReducer.createData.etalonId)
  const typeKits = useSelector((state) => state.etalonReducer.createData.typeKits)
  const manufacturerNumber = useSelector((state) => state.etalonReducer.createData.manufacturerNumber)
  const regNumber = useSelector((state) => state.etalonReducer.createData.regNumber)
  const signKits = useSelector((state) => state.etalonReducer.createData.signKits)
  const verificationPlace = useSelector((state) => state.etalonReducer.createData.verificationPlace)
  const link = useSelector((state) => state.etalonReducer.createData.link)
  const dateFrom = useSelector((state) => state.etalonReducer.createData.dateFrom)
  const dateTo = useSelector((state) => state.etalonReducer.createData.dateTo)

  const handleTypeKitsChange = (param) => {
    dispatch(changeEtalon({ value: "typeKits", label: param }))
  }

  const handleManufacturerNumberChange = (param) => {
    dispatch(changeEtalon({ value: "manufacturerNumber", label: param }))
  }

  const handleRegNumberChange = (param) => {
    dispatch(changeEtalon({ value: "regNumber", label: param }))
  }

  const handleSignKitsChange = (param) => {
    dispatch(changeEtalon({ value: "signKits", label: param }))
  }

  const handleVerificationPlaceChange = (param) => {
    dispatch(changeEtalon({ value: "verificationPlace", label: param }))
  }

  const handleLinkChange = (param) => {
    dispatch(changeEtalon({ value: "link", label: param }))
  }

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeEtalon({
          value: "dateFrom",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const handleNextDateChange = (date) => {
    if (date) {
      dispatch(
        changeEtalon({
          value: "dateTo",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const navigateToEtalon = () => {
    navigate("/metriva/etalon")
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)
    const data = {
      device_id: etalonId,
      device_description: signKits,
      device_manufacturer_number: manufacturerNumber,
      reg_number: regNumber,
      device_sign: typeKits,
      device_link: link,
      expired_at: dateTo,
      verified_at: dateFrom,
      verification_place: verificationPlace,
    }

    if (userRole === "super") {
      data.company_id = companyId
    }

    const { status, data: etalonAddData } = await R.addEtalon(data)

    if (status === 200) {
      dispatch(setMessage("Эталон успешно создан"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshEtalons())
      navigate("/metriva/etalon")
    } else {
      if (etalonAddData.errors) {
        const errors = etalonAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Эталон не добавлен - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToEtalon}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Назад к списку эталонов</p>
      </div>

      <div className="box">
        { userRole === "super" && (
          <div className="width50Percent" style={{ marginBottom: "20px" }}>
            <h2 style={{ marginBottom: "15px" }}>Название компании</h2>
            <SelectCompany />
            { validationErrors["company_id"] && (
              <div className="error">{validationErrors["company_id"]}</div>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "14px" }}>Эталон - общая информация</h2>
        <div className="flexContainerWithGap" style={{ marginBottom: "20px" }}>
          <div className="width32Percent">
            <SelectMeasurement />
          </div>

          <div className="width32Percent">
            <Method />
          </div>

          <div className="width32Percent">
            <SelectType />
            { validationErrors["device_id"] && (
              <div className="error">{validationErrors["device_id"]}</div>
            )}
          </div>
        </div>

        <h2 style={{ marginBottom: "20px" }}>Эталон - подробнее</h2>
        <div 
          className="flexContainerWithGap" 
          style={ width > 1050 
            ? { marginBottom: "25px" }
            : { marginBottom: "25px", gap: "22px" }
          }
        >
          <div className="width32Percent">
            <Input
              label={"Тип эталона"}
              placeholder={"Введите тип эталона"}
              type={"text"}
              value={typeKits || ""}
              actions={{
                change: handleTypeKitsChange,
              }}
            />
            { validationErrors["device_sign"] && (
              <div className="error">{validationErrors["device_sign"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Заводской номер"}
              placeholder={"Введите заводской номер эталона"}
              type={"text"}
              value={manufacturerNumber || ""}
              actions={{
                change: handleManufacturerNumberChange,
              }}
            />
            { validationErrors["device_manufacturer_number"] && (
              <div className="error">
                {validationErrors["device_manufacturer_number"]}
              </div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Регистрационный номер"}
              placeholder={"Введите регистрационный номер"}
              type={"text"}
              value={regNumber || ""}
              actions={{
                change: handleRegNumberChange,
              }}
            />
            { validationErrors["reg_number"] && (
              <div className="error">{validationErrors["reg_number"]}</div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: "25px" }}>
          <Input
            label={"Свидетельство о поверке"}
            placeholder={"Информация о свидетельстве поверки"}
            type={"text"}
            value={signKits || ""}
            actions={{
              change: handleSignKitsChange,
            }}
          />
          { validationErrors["device_description"] && (
            <div className="error">
              {validationErrors["device_description"]}
            </div>
          )}
        </div>

        <div style={{ marginBottom: "25px" }}>
          <Input
            label={"Место проведения поверки"}
            type={"text"}
            placeholder={"Введите место проведения поверки"}
            value={verificationPlace || ""}
            actions={{
              change: handleVerificationPlaceChange,
            }}
          />

          { validationErrors["verification_place"] && (
            <div className="error">
              {validationErrors["verification_place"]}
            </div>
          )}
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Input
            label={"Ссылка"}
            type={"text"}
            placeholder={"Введите ссылку на эталон"}
            value={link || ""}
            actions={{
              change: handleLinkChange,
            }}
          />

          { validationErrors["device_link"] && (
            <div className="error">{validationErrors["device_link"]}</div>
          )}
        </div>

        <div className="flexContainerWithGap" style={ width > 1050 ? {} : { marginBottom: 0 }}>
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
                fontWeight: 500
              }}
            >
              Дата проведения поверки
              {!dateFrom ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-0px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-0px",
                  }}
                />
              )}
            </p>
            <DatePicker selectedDate={dateFrom} dateChange={handleDateChange} />
          </div>

          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
                fontWeight: 500
              }}
            >
              Поверка действительна до
              {!dateTo ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-0px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-0px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={dateTo}
              dateChange={handleNextDateChange}
            />

            {!dateTo && validationErrors["expired_at"] && (
              <div className="error">{validationErrors["expired_at"]}</div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "14px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Сохранить эталон
        </Button>
      </div>
    </main>
  )
}

export default AddEtalon
