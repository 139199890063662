import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import moment from 'moment'

import Input from '../../../LocalElements/Input/InputMUI'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import dayjs from 'dayjs'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const ProtocolDate = props => {
  const {
    currentDate,
    changeCurrentDate,
    nextDate,
    nextDateAutoGenerate,
    changeNextDate,
    valueAgreement,
    changeAgreement,
    valuePassport,
    onChangePassport,
    valueSi,
    onChangeSi,
    isTotalAccepted,
  } = props
  const { width } = useWindowDimensions()
  const today = dayjs()
  const minDate = today.subtract(40, 'day')

  return (
    <React.Fragment>
      {isTotalAccepted && (
        <React.Fragment>
          <h2 style={{ marginBottom: '10px' }}>Знак поверки</h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: '-12px' }}>
              <Checkbox checked={valuePassport} onChange={onChangePassport} id='valuePassport' />
            </div>
            <label for='valuePassport'>Знак поверки в паспорте</label>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <div style={{ marginLeft: '-12px' }}>
              <Checkbox checked={valueSi} onChange={onChangeSi} id='valueSi' />
            </div>
            <label for='valueSi'>Знак поверки на системах измерения</label>
          </div>
        </React.Fragment>
      )}

      <h2 style={{ marginBottom: '10px' }}>Поверка проведена</h2>

      <div className='flexContainerWithGap' style={width <= 1050 ? { marginBottom: '0px' } : {}}>
        <div className='width32Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Дата поверки{' '}
            {!currentDate ? (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-0px',
                }}
              />
            ) : (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </p>

          <DatePicker
            selectedDate={currentDate}
            dateChange={changeCurrentDate}
            minDate={minDate}
            maxDate={today}
          />
        </div>

        <div className='width32Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Дата следующей поверки{' '}
            {nextDateAutoGenerate || nextDate ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-0px',
                }}
              />
            )}
          </p>

          {nextDateAutoGenerate ? (
            <p
              style={{
                height: '54px',
                border: '1px solid #d7ddea',
                padding: '14px 16px',
                borderRadius: '12px',
              }}
            >
              {moment(nextDateAutoGenerate).format('DD-MM-YYYY')}
            </p>
          ) : (
            <DatePicker selectedDate={nextDate} dateChange={changeNextDate} />
          )}
        </div>

        <div className='width32Percent'>
          <p style={{ marginBottom: '9px' }}>Номер договора (необязательно)</p>
          <Input
            placeholder={'Пример ввода - 00000-00'}
            type={'text'}
            notRequired={true}
            value={valueAgreement}
            actions={{
              change: changeAgreement,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProtocolDate
