import styled from 'styled-components'

const css = {
  PageContainer: styled.section`
    display: flex;
    flex-flow: column;
    position: relative;
  `,
  BoxContainer: styled.div`
    margin: ${props => (props.margin ? props.margin : '0 0 14px')};
    padding: 22px;
    background-color: #ffffff;
    border: 1px solid #d7ddea;
    border-radius: 12px;
    box-sizing: border-box;
  `,
  ViewProtocol: {
    BoxContainer: styled.div`
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      width: 100%;
      padding: 22px;
      background-color: #ffffff;
      border: 1px solid #d7ddea;
      border-radius: 12px;
      box-sizing: border-box;
    `,
    ContentRow: styled.div`
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding-left: 22px;
      width: 100%;
      gap: ${props => (props.gap ? props.gap : 0)};
    `,
    MiddleHeadline: styled.h3`
      display: block;
      position: relative;
      font-size: 18px;
      letter-spacing: 0.2px;
      background-color: #e5f3fc;
      padding-left: 22px;
      height: 55px;
      line-height: 53px;
      width: 600px;
      overflow: hidden;
      margin: ${props => (props.margin ? props.margin : 0)};
    `,
    SmallHeadline: styled.h5`
      display: block;
      position: relative;
      width: 100%;
      font-size: 18px;
      letter-spacing: 0.2px;
    `,
    TextElement: styled.span`
      display: block;
      position: relative;
      font-size: 15px;
    `,
  },
}

export default css
