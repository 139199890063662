// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'

const Feedback = () => {
  const show = useSelector(state => state.feedbackReducer.show)

  return (
    <React.Fragment>
      <div
        style={{
          display: 'none',
          position: 'absolute',
          width: '100%',
          top: 0,
          zIndex: 5,
        }}
      >
        {show && false && <LinearProgress />}
      </div>
    </React.Fragment>
  )
}

export default Feedback
