import React from 'react'
import Input from '../LocalElements/Input/InputMUI'
import { useDispatch, useSelector } from 'react-redux'

import { changeData } from '../../../store/slices/app/views/users'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const InputData = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const name = useSelector(state => state.usersReducer.createData.name)
  const surname = useSelector(state => state.usersReducer.createData.surname)
  const patr = useSelector(state => state.usersReducer.createData.patr)
  const login = useSelector(state => state.usersReducer.createData.login)
  const password = useSelector(state => state.usersReducer.createData.password)
  const email = useSelector(state => state.usersReducer.createData.email)

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleNameChange = param => {
    const value = capitalizeFirstLetter(param)
    dispatch(changeData({ value: 'name', label: value }))
  }

  const handleSurNameChange = param => {
    const value = capitalizeFirstLetter(param)
    dispatch(changeData({ value: 'surname', label: value }))
  }

  const handlePatrChange = param => {
    const value = capitalizeFirstLetter(param)
    dispatch(changeData({ value: 'patr', label: value }))
  }

  const handleEmailChange = param => {
    dispatch(changeData({ value: 'email', label: param }))
  }

  const handleLoginChange = param => {
    dispatch(changeData({ value: 'login', label: param }))
  }

  const handlePasswordChange = param => {
    dispatch(changeData({ value: 'password', label: param }))
  }

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginBottom: width > 1050 ? '25px' : '0px' }}>
        <div className='width32Percent' style={width > 1050 ? {} : { marginBottom: '10px' }}>
          <Input
            label={'Фамилия пользователя'}
            type={'text'}
            placeholder={"Введите фамилию'"}
            value={surname}
            actions={{
              change: handleSurNameChange,
            }}
          />
        </div>
        <div className='width32Percent' style={width > 1050 ? {} : { marginBottom: '10px' }}>
          <Input
            label={'Имя пользователя'}
            type={'text'}
            placeholder={'Введите имя'}
            value={name}
            actions={{
              change: handleNameChange,
            }}
          />
        </div>
        <div className='width32Percent' style={width > 1050 ? {} : { marginBottom: '10px' }}>
          <Input
            label={'Отчество пользователя'}
            type={'text'}
            placeholder={'Введите отчество'}
            value={patr}
            actions={{
              change: handlePatrChange,
            }}
          />
        </div>
      </div>

      <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
        <div className='width32Percent' style={width > 1050 ? {} : { marginBottom: '10px' }}>
          <Input
            label={'Email адрес'}
            type={'text'}
            placeholder={"Пример ввода - 'почта@gmail.com'"}
            value={email}
            actions={{
              change: handleEmailChange,
            }}
          />
        </div>
        <div className='width32Percent' style={width > 1050 ? {} : { marginBottom: '10px' }}>
          <Input
            label={'Логин'}
            type={'text'}
            placeholder={'Введите логин'}
            value={login}
            actions={{
              change: handleLoginChange,
            }}
            autoComplete={'off'}
          />
        </div>
        <div className='width32Percent'>
          <Input
            label={'Пароль'}
            type={'password'}
            placeholder={'***********'}
            value={password}
            actions={{
              change: handlePasswordChange,
            }}
            autoComplete={'new-password'}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default InputData
