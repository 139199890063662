import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "@mui/material"

import Input from "../../../../LocalElements/Input/InputMUI"
import R from "../../../../../../services/app/request.service"

import { changeProperty } from "../state"
import useWindowDimensions from "../../../../../hooks/useWindowDimensions"

import css from "../style.css"

const { Container, ConditionsContainer, ConditionsBox } = css

const Conditions = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [tariffIds, setTariffIds] = useState([])

  const [temperature, setTemperature] = useState("")
  const [humidity, setHumidity] = useState("")
  const [pressure, setPressure] = useState("")
  const [waterTempStart, setWaterTempStart] = useState("")
  const [waterTempFinish, setWaterTempFinish] = useState("")

  const handleTemperatureChange = (param) => {
    setTemperature(param.replace(",", "."))
  }

  const handleHumidityChange = (param) => {
    setHumidity(param.replace(",", "."))
  }

  const handlePressureChange = (param) => {
    setPressure(param.replace(",", "."))
  }

  const handleWaterTempStartChange = (param) => {
    setWaterTempStart(param.replace(",", "."))
  }

  const handleWaterTempFinishChange = (param) => {
    setWaterTempFinish(param.replace(",", "."))
  }

  const getRandomNumber = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(1)
  }

  const autogenerateProtocol = () => {
    const randomWaterTempStart = parseFloat(getRandomNumber(5, 40))
    const randomWaterTempFinish = parseFloat(
      getRandomNumber(randomWaterTempStart, randomWaterTempStart + 5)
    ).toFixed(1)

    setTemperature(getRandomNumber(21, 25))
    setHumidity(getRandomNumber(33, 57))
    setPressure(getRandomNumber(87, 102))
    setWaterTempStart(randomWaterTempStart.toFixed(1))
    setWaterTempFinish(randomWaterTempFinish)
  }

  const Reset = () => {
    setTemperature("")
    setHumidity("")
    setPressure("")
    setWaterTempStart("")
    setWaterTempFinish("")
  }

  useEffect(() => {
    dispatch(
      changeProperty({
        value: "conditions",
        label: [
          {
            temperature_environment: parseFloat(temperature),
            relative_humidity: parseFloat(humidity),
            atmospheric_pressure: parseFloat(pressure),
            water_temp: parseFloat(waterTempStart),
          },
          {
            temperature_environment: parseFloat(temperature),
            relative_humidity: parseFloat(humidity),
            atmospheric_pressure: parseFloat(pressure),
            water_temp: parseFloat(waterTempFinish),
          },
        ],
      })
    )
  }, [
    temperature,
    humidity,
    pressure,
    waterTempStart,
    waterTempFinish,
    dispatch,
  ])

  useEffect(() => {
    (async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map((tariff) => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <Container>
        <h2 className="width50Percent" style={{ marginBottom: "10px" }}>
          Соблюдение условий поверки
        </h2>

        { tariffIds && tariffIds.includes(5) && (
          <div className="width50Percent">
            <Container style={ width > 1050 ? {} : { gap: '14px' }}>
              <div className="width50Percent">
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "10px 20px 12px",
                    borderRadius: "12px",
                    marginRight: "0px",
                    color: "white",
                    width: "100%",
                  }}
                  onClick={autogenerateProtocol}
                >
                  Тестовая среда
                </Button>
              </div>
              <div className="width50Percent">
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "10px 20px 12px",
                    borderRadius: "12px",
                    marginRight: "0px",
                    width: "100%",
                  }}
                  onClick={Reset}
                  disabled={
                    !temperature &&
                    !humidity &&
                    !pressure &&
                    !waterTempStart &&
                    !waterTempFinish
                  }
                >
                  Сбросить все значения
                </Button>
              </div>
            </Container>
            <p style={{ margin: "14px 0 22px", width: "90%" }}>
              Генерация данных указаны как пример в помощь поверителю. При создании протокола все данные необходимо скорректировать
            </p>
          </div>
        )}
      </Container>

      <ConditionsContainer margin={"0 0 14px"}>
        <h3 style={{ marginBottom: "15px" }}>На начало поверки</h3>
        <ConditionsBox margin={"0 0 10px"}>
          <p className="width32Percent">Температура окружающей среды, °C</p>
          <Input
            type={"text"}
            value={temperature}
            actions={{
              change: handleTemperatureChange,
            }}
            className={"backgroundColorWhite"}
          />
        </ConditionsBox>

        <ConditionsBox margin={"0 0 10px"}>
          <p className="width32Percent">Относительная влажность, %</p>
          <Input
            type={"text"}
            value={humidity}
            actions={{
              change: handleHumidityChange,
            }}
            className={"backgroundColorWhite"}
          />
        </ConditionsBox>

        <ConditionsBox margin={"0 0 10px"}>
          <p className="width32Percent">Атмосферное давление, кПа</p>
          <Input
            type={"text"}
            value={pressure}
            actions={{
              change: handlePressureChange,
            }}
            className={"backgroundColorWhite"}
          />
        </ConditionsBox>

        <ConditionsBox margin={"0 0 5px"}>
          <p className="width32Percent">Температура воды, °С</p>
          <Input
            type={"text"}
            value={waterTempStart}
            actions={{
              change: handleWaterTempStartChange,
            }}
            className={"backgroundColorWhite"}
          />
        </ConditionsBox>
      </ConditionsContainer>

      <ConditionsContainer>
        <h3 style={{ marginBottom: "15px" }}>По окончанию поверки</h3>
        <ConditionsBox margin={"0 0 5px"}>
          <p className="width32Percent">Температура воды, °С</p>
          <Input
            type={"text"}
            value={waterTempFinish}
            actions={{
              change: handleWaterTempFinishChange,
            }}
            className={"backgroundColorWhite"}
          />
        </ConditionsBox>
      </ConditionsContainer>
    </React.Fragment>
  )
}

export default Conditions
