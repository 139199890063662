// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Collapse from '@mui/material/Collapse'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import Input from '../../../../LocalElements/Input/InputMUI'

import R from '../../../../../../services/app/request.service'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import useGenerateProtocolData from '../../../../../hooks/useGenerateProtocolDataMi1592'

const MeterData = props => {
  const {
    meterFactoryNumber,
    meterYear,
    meterId,
    modifications,
    selectedAdditionals,
    valueMod,
    valueAdditional,
    valueAnotherMod,
    is_custom_modification,
    changeMeter,
  } = props
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { defaultValuesStartFinish } = useGenerateProtocolData()

  const [data, setData] = useState([])

  const measurementId = useSelector(state => state.protocolReducer.measurementId)
  const defaultData = useSelector(state => state.mi15922015Reducer.defaultData)

  const [meterInputValue, setMeterInputValue] = useState('')

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  // const optionsMeter = data
  //   ? data.map((item) => ({
  //       value: item.id,
  //       label: `${item.fif_number}, ${item.name_si}, ${item.manufacturer.name}`,
  //     }))
  //   : []

  const optionsMeter = data
    ? data
        .map(item => ({
          value: item.id,
          label: `${item.fif_number}, ${item.name_si}, ${item.manufacturer.name}`,
        }))
        .filter(item => ~item.label.indexOf(meterInputValue))
    : []

  const getValueMeter = selectedOption => {
    if (selectedOption) {
      dispatch(changeMeter({ value: 'meterId', label: selectedOption.value }))

      const selectedData = data.find(item => item.id === selectedOption.value)
      const selectedModifications = selectedData.modifications

      false && console.log(selectedModifications)

      defaultValuesStartFinish()

      dispatch(
        changeMeter({
          value: 'modifications',
          label: selectedModifications,
        }),
      )

      false && console.log(selectedData)
      false &&
        false &&
        console.log({
          q_max_limit_cold: selectedData.q_max_limit_cold,
          q_max_limit_hot: selectedData.q_max_limit_hot,
          q_min_limit_cold: selectedData.q_min_limit_cold,
          q_min_limit_hot: selectedData.q_min_limit_hot,
          mpi_cold: selectedData.mpi_cold,
          mpi_hot: selectedData.mpi_hot,
        })

      dispatch(
        changeMeter({
          value: 'defaultData',
          label: {
            // ----------------------------------------------------------------
            // q_max_limit_cold: defaultData.q_max_limit_cold || "",
            // q_max_limit_hot: defaultData.q_max_limit_hot || "",
            // q_min_limit_cold: defaultData.q_min_limit_cold || "",
            // q_min_limit_hot: defaultData.q_min_limit_hot || "",
            // ----------------------------------------------------------------
            mpi_cold: selectedData.mpi_cold,
            mpi_hot: selectedData.mpi_hot,
          },
        }),
      )

      dispatch(changeMeter({ value: 'selectedMod', label: null }))
      dispatch(changeMeter({ value: 'valueMod', label: null }))
      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: '' }))

      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))

      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))
      dispatch(
        changeMeter({
          value: 'selectedAdditionals',
          label: [],
        }),
      )
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))
    }
  }

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMod = modifications
    ? modifications
        .map(item => ({
          value: item.name,
          label: item.name,
        }))
        .concat([
          {
            value: 'Нет модификации',
            label: 'Нет модификации',
          },
          {
            value: 'another',
            label: 'Другая модификация',
          },
        ])
    : []

  const getValueMod = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueMod',
          label: selectedOption.value,
        }),
      )

      const selectedMod = modifications.find(item => item.name === selectedOption.value)

      false && console.log(defaultData)

      if (selectedMod) {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: selectedMod.q_max_limit_cold || '',
              q_max_limit_hot: selectedMod.q_max_limit_hot || '',
              q_min_limit_cold: selectedMod.q_min_limit_cold || '',
              q_min_limit_hot: selectedMod.q_min_limit_hot || '',
              mpi_cold: defaultData.mpi_cold,
              mpi_hot: defaultData.mpi_hot,
            },
          }),
        )

        const selectedAdditionals =
          selectedMod.additionals && selectedMod.additionals.length > 0
            ? selectedMod.additionals
            : []

        dispatch(
          changeMeter({
            value: 'conversion_factor',
            label: selectedMod.conversion_factor,
          }),
        )
        dispatch(
          changeMeter({
            value: 'selectedAdditionals',
            label: selectedAdditionals,
          }),
        )
        dispatch(
          changeMeter({
            value: 'selectedMod',
            label: selectedMod,
          }),
        )
      } else {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: '',
              q_max_limit_hot: '',
              q_min_limit_cold: '',
              q_min_limit_hot: '',
              mpi_cold: defaultData.mpi_cold,
              mpi_hot: defaultData.mpi_hot,
            },
          }),
        )
        dispatch(changeMeter({ value: 'selectedAdditionals', label: [] }))
        dispatch(changeMeter({ value: 'selectedMod', label: null }))
      }

      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: '' }))
      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))
      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))
    }
  }

  const optionsAdditionals = selectedAdditionals
    ? selectedAdditionals.map(item => ({
        value: item.text,
        label: item.text,
      }))
    : []

  const getValueAdditionals = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueAdditional',
          label: selectedOption.value,
        }),
      )

      const selectedAdditional = selectedAdditionals.find(
        item => item.text === selectedOption.value,
      )
      if (selectedAdditional) {
        dispatch(
          changeMeter({
            value: 'mpiColdAdditionals',
            label: selectedAdditional.mpi_cold,
          }),
        )

        dispatch(
          changeMeter({
            value: 'mpiHotAdditionals',
            label: selectedAdditional.mpi_hot,
          }),
        )
      }
    }
  }

  const handleValueModChange = param => {
    dispatch(
      changeMeter({
        value: 'valueAnotherMod',
        label: param,
      }),
    )
  }

  useEffect(() => {
    if (valueMod === 'another') {
      dispatch(changeMeter({ value: 'is_custom_modification', label: true }))
    } else {
      dispatch(changeMeter({ value: 'is_custom_modification', label: false }))
    }
  }, [valueMod, dispatch])

  useEffect(() => {
    if (is_custom_modification) {
      dispatch(changeMeter({ value: 'valueMod', label: 'another' }))
    }
  }, [is_custom_modification])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    ;(async () => {
      const { data: metersList } = await R.getMetersList(
        `?measurement_id=${measurementId}&limit=1000`,
      )
      const meterData = metersList.data
      setData(meterData)
    })()
  }, [measurementId])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleMeterFactoryNumberChange = param => {
    dispatch(
      changeMeter({
        value: 'meterFactoryNumber',
        label: param,
      }),
    )
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const yearlist = Array.from({ length: 25 }, (item, index) => (2000 + index).toString())

  yearlist.push('Не выбрано')

  const optionsYear = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValueYear = selectedOption => {
    dispatch(
      changeMeter({
        value: 'meterYear',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  useEffect(() => {
    false && console.log(meterId)
  }, [meterId])
  useEffect(() => {
    false && console.log(defaultData)
  }, [defaultData])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '14px' }}>Данные о поверяемом приборе</h2>
      <div className='flexContainerWithGap' style={{ marginBottom: '10px' }}>
        <div className='width50Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Поверяемый прибор ( № Госреестра, Завод производитель )
            {meterId ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            ) : (
              <ErrorIcon sx={{ color: '#FFD43B', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </p>
          <Selector
            placeholder={'Выберите прибор'}
            options={optionsMeter}
            onChange={getValueMeter}
            onInputChange={inputValue => {
              setMeterInputValue(inputValue)
            }}
            value={meterId ? optionsMeter.find(option => option.value === meterId) : null}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Нет доступных приборов'}
          />
        </div>

        <div
          className={
            selectedAdditionals && selectedAdditionals.length > 0
              ? 'width50Percent'
              : 'width50Percent'
          }
        >
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Модификация прибора
            {valueMod ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '-2px' }} />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>
          <Selector
            placeholder={'Выберите модификацию'}
            options={optionsMod}
            onChange={getValueMod}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Сначало выберите поверяемый прибор'}
            value={valueMod ? optionsMod.find(option => option.value === valueMod) : null}
          />
        </div>
      </div>
      <Collapse in={selectedAdditionals && selectedAdditionals.length > 0}>
        {true && (
          <div className='width24Point5Percent' style={{ width: '100%', marginBottom: '10px' }}>
            <p
              style={{
                marginBottom: '9px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Дополнительное условие
              {valueAdditional ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                    marginLeft: '8px',
                    marginTop: '-0px',
                  }}
                />
              ) : (
                <ErrorIcon
                  sx={{
                    color: '#FFD43B',
                    marginLeft: '8px',
                    marginTop: '0px',
                  }}
                />
              )}
            </p>
            <Selector
              placeholder={'Выберите доп. условие'}
              options={optionsAdditionals}
              onChange={getValueAdditionals}
              isClearable={false}
              isSearchable={false}
              value={
                selectedAdditionals
                  ? !!optionsAdditionals.find(option => option.value === valueAdditional)
                    ? optionsAdditionals.find(option => option.value === valueAdditional)
                    : null
                  : null
              }
            />
          </div>
        )}
      </Collapse>

      <Collapse in={valueMod === 'another'}>
        <div
          className='width50Percent'
          style={
            width > 1050
              ? { marginTop: '0px', marginBottom: '10px', width: '100%' }
              : {
                  margin: 0,
                  marginTop: '0px',
                  marginBottom: '10px',
                  width: '100%',
                }
          }
        >
          {true && (
            <p
              style={{
                marginBottom: '9px',
                display: 'flex',
                alignItems: 'center',
                opacity: 1,
              }}
            >
              Другая модификация прибора
              {!!valueAnotherMod ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                    marginLeft: '8px',
                    marginTop: '-0px',
                  }}
                />
              ) : (
                <ErrorIcon
                  sx={{
                    color: '#FFD43B',
                    marginLeft: '8px',
                    marginTop: '-0px',
                    opacity: 0,
                  }}
                />
              )}
            </p>
          )}
          <Input
            type={'text'}
            placeholder={'Введите модификацию прибора'}
            value={valueAnotherMod}
            actions={{
              change: handleValueModChange,
            }}
          />
        </div>
      </Collapse>

      <div
        className='flexContainerWithGap'
        style={width > 1050 ? {} : { marginTop: '0px', marginBottom: '0px' }}
      >
        <div className='width50Percent'>
          {true && (
            <p
              style={{
                marginBottom: '9px',
                display: 'flex',
                alignItems: 'center',
                opacity: 1,
              }}
            >
              Заводской номер прибора
              {!!meterFactoryNumber ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                    marginLeft: '8px',
                    marginTop: '-0px',
                  }}
                />
              ) : (
                <ErrorIcon
                  sx={{
                    color: '#FFD43B',
                    marginLeft: '8px',
                    marginTop: '-0px',
                    opacity: 0,
                  }}
                />
              )}
            </p>
          )}
          <Input
            placeholder={'Введите заводской номер'}
            value={meterFactoryNumber}
            type={'text'}
            actions={{
              change: handleMeterFactoryNumberChange,
            }}
          />
        </div>
        <div className='width50Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Год выпуска прибора
            {!!meterYear ? (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>
          <Selector
            placeholder={'Выберите год'}
            options={optionsYear}
            onChange={getValueYear}
            value={optionsYear.find(option => option.value === meterYear)}
            isClearable={true}
            isSearchable={true}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MeterData
