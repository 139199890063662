import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import CompanyShow from '../app/pages/Company/CompanyShow'
import CompanyEdit from '../app/pages/Company/CompanyEdit'

const RouteCompany: React.FC = (): ReactElement => {
  return (
    <Routes>
      <Route index element={<CompanyShow />} />
      <Route path=':companyAdminId/edit' element={<CompanyEdit />} />
    </Routes>
  )
}

export default RouteCompany
