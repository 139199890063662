import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

const AsyncSelector = ({
  callback,
  value,
  options = async () => {},
  optionKey,
  placeholder = 'Начните вводить искомый адрес',
}) => {
  const animatedComponents = makeAnimated()

  const selectStyles = {
    control: theme => ({
      ...theme,
      outline: 'none',
      boxShadow: '0px 0px 1.5px grey',
      minHeight: '55px',
      fontSize: '14px',
      paddingBottom: '1px',
      paddingTop: '1px',
      borderRadius: '12px',
      fontFamily: "'Wix Madefor Display', sans-serif",
      color: '#123532',
      border: '1px solid #d7ddea',
    }),
    placeholder: theme => ({
      ...theme,
      paddingLeft: '5px',
      paddingBottom: '2px',
      fontFamily: "'Wix Madefor Display', sans-serif",
      color: 'rgba(79, 89, 96, 0.6)',
    }),
    singleValue: theme => ({
      ...theme,
      paddingLeft: '5px',
    }),
    input: theme => ({
      ...theme,
      paddingLeft: '4px',
    }),
    menu: theme => ({
      ...theme,
      overflow: 'hidden',
      paddingTop: 6,
      paddingBottom: 6,
      width: '90%',
      marginTop: 10,
      border: 'none',
      zIndex: 20,
      fontFamily: "'Wix Madefor Display', sans-serif",
    }),
    menuList: theme => ({
      ...theme,
      display: 'block',
      position: 'relative',
      width: 'calc(100% + 23px)',
    }),
    option: (theme, { isSelected, isFocused }) => ({
      ...theme,
      fontSize: '13px',
      padding: '12px 0',
      paddingLeft: '13px',
      paddingBottom: '12.8px',
      paddingRight: '33px',
      cursor: 'pointer',
      color: isSelected ? 'white' : '#2E2E2E',
      lineHeight: '24px',
      fontFamily: "'Wix Madefor Display', sans-serif",
    }),
    multiValueLabel: theme => ({
      ...theme,
      height: '30px',
      lineHeight: '30px',
      fontSize: '14px',
      paddingLeft: '10px',
      paddingRight: '3px',
      color: '#123532',
      fontFamily: "'Wix Madefor Display', sans-serif",
    }),
    multiValueRemove: theme => ({
      ...theme,
      marginRight: '8px',
      marginLeft: '2px',
      marginTop: '8px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : null,
      transition: 'transform 0.3s ease',
    }),
  }

  const [, setValue] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    if (value) {
      setSelectedValue({
        value: value,
        label: value,
      })
    }
  }, [value])

  const handleInputChange = value => {
    setValue(value)
  }

  const handleChange = value => {
    setSelectedValue(value)
    setValue(value)
    callback(value)
  }

  const loadOptions = async inputValue => {
    const { data } = await options(inputValue)

    return data[optionKey]
  }

  return (
    <React.Fragment>
      <AsyncSelect
        classNamePrefix='custom-select'
        components={animatedComponents}
        styles={selectStyles}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        getOptionLabel={e => e.value}
        getOptionValue={e => e.value}
        value={selectedValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        placeholder={placeholder}
        noOptionsMessage={() => 'Ничего не найдено'}
        loadingMessage={() => 'Подождите, идет загрузка'}
      />
    </React.Fragment>
  )
}

export default AsyncSelector
