import React from "react"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { changeData } from "../../../store/slices/app/views/users"

const SelectedRole = () => {
  const dispatch = useDispatch()

  const userRole = useSelector((state) => state.headerReducer.role)
  const isSearchable = false
  const rolesId = useSelector((state) => state.usersReducer.createData.rolesId)

  const rolesData = [
    ...(userRole === "super"
      ? [
          {
            value: "super",
            label: "Супер-админ",
          },
        ]
      : []),
    {
      value: "admin",
      label: "Администратор",
    },
    {
      value: "brigadier",
      label: "Руководитель",
    },
    {
      value: "metrologist",
      label: "Поверитель",
    },
  ]

  const optionsRoles = rolesData
    ? rolesData.map((rolesData) => ({
        value: rolesData.value,
        label: rolesData.label,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeData({
        value: "rolesId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  return (
    <div className="width50Percent">
      <span
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Роль в системе
        { !rolesId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-0px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-0px" }}
          />
        )}
      </span>
      <Selector
        placeholder={"Выберите роль"}
        options={optionsRoles}
        value={optionsRoles.find((option) => option.value === rolesId)}
        onChange={getValue}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных должностей"}
      />
    </div>
  )
}

export default SelectedRole
