// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../../../LocalElements/Selector/reactSelect/Selector'
import { changeCase } from '../../../../../store/slices/app/views/protocols'
import Approved from '../../../LocalElements/Filter/Approved'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const TypeMethod = () => {
  const dispatch = useDispatch()

  const verificationMethod = useSelector(state => state.protocolReducer.case.method)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const pointMethod = useSelector(state => state.protocolReducer.case.pointMethod)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  console.log('verificationType', verificationType)

  const changeType = event => {
    dispatch(changeCase({ value: 'verificationType', label: event.target.value }))
  }
  const changeMethod = event => {
    dispatch(changeCase({ value: 'method', label: event.target.value }))
  }

  const options = pointMethod
    ? pointMethod.map(item => ({
        value: item.id,
        label: item.isOpticalSensor ? `${item.name} (с оптосчитывателем)` : item.name,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeCase({
          value: 'pointId',
          label: selectedOption ? selectedOption.value : null,
        })
      )
      const selectedPoint = pointMethod.find(item => item.id === selectedOption.value)

      if (selectedPoint && selectedPoint.isOpticalSensor !== undefined) {
        dispatch(
          changeCase({
            value: 'opticalSensor',
            label: !!selectedPoint.isOpticalSensor,
          })
        )
      } else {
        dispatch(changeCase({ value: 'opticalSensor', label: null }))
      }
    }
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '14px' }}>Рамки применения методики</h2>
      <div
        className='flexContainer M__TypeMethod__container'
        style={{
          display: 'flex',
          flexFlow: 'row',
        }}
      >
        <div
          className='width32Percent M__TypeMethod__row'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            width: '25%',
          }}
        >
          <p style={{ display: 'flex', flexFlow: 'row' }}>
            Способ проведения
            {!!verificationMethod ? (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>

          <Approved
            changeApproved={changeMethod}
            approved={verificationMethod}
            labelTrue={'На выезде'}
            labelFalse={'В лаборатории'}
            valueTrue={'field'}
            valueFalse={'labaratory'}
            disabledTrue={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'labaratory'
            }
            disabledFalse={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'field'
            }
          />
        </div>

        <div
          className='width32Percent M__TypeMethod__row'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            width: '25%',
          }}
        >
          <p style={{ display: 'flex', flexFlow: 'row' }}>
            Тип поверки
            {!!verificationType ? (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>

          <Approved
            changeApproved={changeType}
            approved={verificationType}
            labelTrue={'Первичная'}
            labelFalse={'Периодическая'}
            valueTrue={'initial'}
            valueFalse={'periodic'}
            disabledTrue={
              verificationType &&
              verificationType.length === 1 &&
              verificationType[0] === 'periodic'
            }
            disabledFalse={
              verificationType && verificationType.length === 1 && verificationType[0] === 'initial'
            }
          />
        </div>

        <div
          className='width67Percent M__TypeMethod__row'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            width: '50%',
          }}
        >
          <p style={{ marginBottom: '9px', display: 'flex', flexFlow: 'row' }}>
            Конкретные разделы/пункты методики, применяемые в ходе поверки
            {pointId ? (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>
          <div style={{ width: '100%' }}>
            {options.length === 1 ? (
              <React.Fragment>
                {getValue(options[0])}
                <Selector
                  placeholder={'Выберете раздел методики из списка'}
                  onChange={getValue}
                  options={options}
                  isClearable={false}
                  isSearchable={false}
                  value={options[0]}
                />
              </React.Fragment>
            ) : (
              <Selector
                placeholder={'Выберете раздел методики из списка'}
                onChange={getValue}
                options={options}
                isClearable={false}
                isSearchable={true}
                noOptionsMessage={() => 'Для этой методики нет доступных пунктов поверки'}
                value={pointId ? options.find(option => option.value === pointId) : null}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TypeMethod
