import React, { useState } from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeleteDisabled = (props) => {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99609 5.56403C2.99609 5.1805 3.28391 4.86959 3.63895 4.86959L5.85915 4.86922C6.30028 4.85714 6.68944 4.55414 6.83954 4.10589C6.84349 4.09411 6.84803 4.07957 6.86431 4.02682L6.95997 3.71674C7.01851 3.52662 7.06951 3.36098 7.14088 3.21293C7.42283 2.62804 7.94449 2.22188 8.54732 2.1179C8.69991 2.09158 8.8615 2.09169 9.04698 2.09181H11.9454C12.1308 2.09169 12.2924 2.09158 12.445 2.1179C13.0478 2.22188 13.5695 2.62804 13.8514 3.21293C13.9228 3.36098 13.9738 3.52662 14.0324 3.71674L14.128 4.02682C14.1443 4.07957 14.1488 4.09411 14.1528 4.10589C14.3029 4.55414 14.7692 4.85751 15.2104 4.86959H17.3532C17.7083 4.86959 17.9961 5.1805 17.9961 5.56403C17.9961 5.94755 17.7083 6.25846 17.3532 6.25846H3.63895C3.28391 6.25846 2.99609 5.94755 2.99609 5.56403Z"
        fill="#BDC1C4"
      />
      <path
        d="M10.3767 18.7583H11.0325C13.2888 18.7583 14.4169 18.7583 15.1504 18.039C15.8839 17.3196 15.9589 16.1397 16.109 13.7798L16.3252 10.3793C16.4067 9.09887 16.4474 8.45863 16.0794 8.05293C15.7115 7.64722 15.0901 7.64722 13.8473 7.64722H7.56197C6.31918 7.64722 5.69779 7.64722 5.32982 8.05293C4.96185 8.45863 5.00257 9.09887 5.08401 10.3793L5.30026 13.7798C5.45034 16.1397 5.52539 17.3196 6.25888 18.039C6.99237 18.7583 8.12049 18.7583 10.3767 18.7583Z"
        fill="#BDC1C4"
      />
    </SvgIcon>
  );
};

export default DeleteDisabled;
