import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import Cookies from 'js-cookie'

const ProtectedRoute = ({ children }) => {
  const TOKEN = Cookies.get('accessToken')
  const reduxToken = useSelector(state => state.authReducer.authData.token)

  let location = useLocation()

  if (!!!TOKEN || !!!reduxToken) {
    return (
      <Navigate
        to='/'
        replace
        state={{
          from: location,
        }}
      />
    )
  }
  return children
}

export default ProtectedRoute
