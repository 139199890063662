import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'

import { changeMainData } from '../../../store/slices/app/views/companies'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const EditCompanyName = ({ imageLogoPath, setImageLogoPath, setImageId }) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const logo = useSelector(state => state.companiesReducer.createData.main.logo)

  const handleLogoChangeEdit = e => {
    const file = e.target.files[0]
    dispatch(changeMainData({ value: 'logo', label: file }))
  }

  const handleRemoveLogo = () => {
    setImageLogoPath(null)
    setImageId(null)
    dispatch(changeMainData({ value: 'logo', label: null }))
  }

  return (
    <React.Fragment>
      <p style={{ marginBottom: '9px', fontWeight: 500 }}>Логотип компании</p>
      <div
        className='cases-photo'
        style={{ width: '100%', gap: '16px', display: 'flex', flexFlow: 'row' }}
      >
        <div style={{ width: width > 1050 ? '33.33333333%' : '100%' }}>
          {!(logo || imageLogoPath) && (
            <label className='custom-file-input'>
              <input
                key={logo || imageLogoPath}
                type='file'
                accept='image/*'
                onChange={handleLogoChangeEdit}
              />
              <span style={{ margin: 0, width: '100%', borderRadius: '12px' }}>
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill='#0084E2'
                    d='M8.45417 11.6973C8.33759 11.8248 8.17277 11.8974 8 11.8974C7.82723 11.8974 7.66241 11.8248 7.54583 11.6973L4.26378 8.10755C4.03445 7.85672 4.05187 7.46747 4.30271 7.23814C4.55354 7.0088 4.94279 7.02623 5.17212 7.27707L7.38462 9.69698V0.615385C7.38462 0.275517 7.66013 0 8 0C8.33987 0 8.61539 0.275517 8.61539 0.615385V9.69698L10.8279 7.27707C11.0572 7.02623 11.4465 7.0088 11.6973 7.23814C11.9481 7.46747 11.9656 7.85672 11.7362 8.10755L8.45417 11.6973Z'
                  />
                  <path
                    fill='#0084E2'
                    d='M1.23077 10.4615C1.23077 10.1217 0.955253 9.84615 0.615386 9.84615C0.275518 9.84615 4.34887e-07 10.1217 4.34887e-07 10.4615V10.5066C-1.61444e-05 11.6287 -2.95089e-05 12.5332 0.0956097 13.2445C0.194905 13.9831 0.407331 14.6049 0.901211 15.0988C1.39509 15.5927 2.01694 15.8051 2.75549 15.9044C3.46685 16 4.37131 16 5.49344 16H10.5066C11.6287 16 12.5332 16 13.2445 15.9044C13.9831 15.8051 14.6049 15.5927 15.0988 15.0988C15.5927 14.6049 15.8051 13.9831 15.9044 13.2445C16 12.5332 16 11.6287 16 10.5066V10.4615C16 10.1217 15.7245 9.84615 15.3846 9.84615C15.0447 9.84615 14.7692 10.1217 14.7692 10.4615C14.7692 11.6393 14.7679 12.4607 14.6846 13.0805C14.6036 13.6826 14.4556 14.0014 14.2285 14.2285C14.0014 14.4556 13.6826 14.6037 13.0805 14.6846C12.4607 14.7679 11.6393 14.7692 10.4615 14.7692H5.53846C4.36068 14.7692 3.53926 14.7679 2.91948 14.6846C2.31741 14.6037 1.99858 14.4556 1.7715 14.2285C1.54441 14.0014 1.39635 13.6826 1.3154 13.0805C1.23208 12.4607 1.23077 11.6393 1.23077 10.4615Z'
                  />
                </svg>
                <span style={{ fontSize: '15px', color: '#0084e2', marginLeft: '12px' }}>
                  Загрузить логотип компании
                </span>
              </span>
            </label>
          )}
          {logo && URL.createObjectURL(logo) ? (
            <div
              className='selected-logo'
              style={{ width: '100%', border: '1px solid #d7ddea', borderRadius: '12px' }}
            >
              <img
                src={URL.createObjectURL(logo)}
                alt='Лого'
                className='profile-logo-image'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          ) : imageLogoPath && imageLogoPath !== '' ? (
            <div
              className='selected-logo'
              style={{ width: '100%', border: '1px solid #d7ddea', borderRadius: '12px' }}
            >
              <img
                src={imageLogoPath}
                alt='Лого'
                className='profile-logo-image'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          ) : null}

          {(logo || imageLogoPath) && (
            <Button
              variant='contained'
              onClick={handleRemoveLogo}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                marginTop: '14px',
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Удалить загруженный логотип
            </Button>
          )}
        </div>
        {width > 1050 && <div style={{ width: '33.33333333%' }}></div>}
        {width > 1050 && <div style={{ width: '33.33333333%' }}></div>}
      </div>
    </React.Fragment>
  )
}

export default EditCompanyName
