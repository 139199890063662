// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Approved from '../../LocalElements/Filter/Approved'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import { setShow } from '../../../../store/slices/app/comps/feedback'
import R from '../../../../services/app/request.service'
import { changeIssueCase, resetIssueCase } from '../../../../store/slices/app/views/journals'
import { refreshIssueCase } from '../../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import dayjs from 'dayjs'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const [isAutogenerated, setIsAutogenerated] = useState('')
  const measurement = useSelector(state => state.journalReducer.issueCase.measurement)
  const caseNum = useSelector(state => state.journalReducer.issueCase.caseNum)
  const issuedByName = useSelector(state => state.journalReducer.issueCase.issuedName)
  const receivedByName = useSelector(state => state.journalReducer.issueCase.receivedName)
  const isReturned = useSelector(state => state.journalReducer.issueCase.returned)
  const issueDate = useSelector(state => state.journalReducer.issueCase.issueDate)
  const receivedDate = useSelector(state => state.journalReducer.issueCase.receivedDate)

  const changeMeasurement = param => {
    dispatch(changeIssueCase({ value: 'measurement', label: param }))
  }

  const handleCaseNumChange = param => {
    dispatch(changeIssueCase({ value: 'caseNum', label: param }))
  }

  const handleIssuedByNameChange = param => {
    dispatch(changeIssueCase({ value: 'issuedName', label: param }))
  }

  const handleReceivedNameChange = param => {
    dispatch(changeIssueCase({ value: 'receivedName', label: param }))
  }

  const handleIssuedDateChange = date => {
    if (date) {
      dispatch(changeIssueCase({ value: 'issueDate', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeIssueCase({ value: 'issueDate', label: '' }))
    }
  }

  const handleReceivedDateChange = date => {
    if (date) {
      dispatch(changeIssueCase({ value: 'receivedDate', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeIssueCase({ value: 'receivedDate', label: '' }))
    }
  }

  const changeIsReturnedChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeIssueCase({ value: 'returned', label: value }))
    dispatch(
      changeIssueCase({ value: 'receivedDate', label: dayjs().format('YYYY-MM-DD HH:mm:ss') }),
    )
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      const { data: IssueCase } = await R.getIssueCaseItem(journal_id, companyId)
      const data = IssueCase.data
      setIsAutogenerated(data?.is_autogenerated)

      dispatch(changeIssueCase({ value: 'measurement', label: data?.measurement_id }))
      dispatch(changeIssueCase({ value: 'caseNum', label: data?.inner_name }))
      dispatch(changeIssueCase({ value: 'issuedName', label: data?.issued_by_name }))
      dispatch(changeIssueCase({ value: 'receivedName', label: data?.received_by_name }))
      dispatch(changeIssueCase({ value: 'returned', label: data?.is_returned }))
      dispatch(changeIssueCase({ value: 'issueDate', label: data?.issued_date }))
      dispatch(changeIssueCase({ value: 'receivedDate', label: data?.received_date }))
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = isAutogenerated
      ? {
          is_returned: isReturned,
          received_date: receivedDate,
        }
      : {
          measurement_id: measurement,
          inner_name: caseNum,
          issued_date: issueDate,
          issued_by_name: issuedByName,
          received_by_name: receivedByName,
          received_date: receivedDate,
          is_returned: isReturned,
        }

    const { status } = await R.editIssueCase(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshIssueCase())
      dispatch(resetIssueCase())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  useEffect(() => {
    return () => {
      dispatch(resetIssueCase())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все записи документа</p>
          </div>

          <div className='path_to_edit'>
            <h4>Журнал выдачи средств поверки</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: !isAutogenerated ? '25px' : '14px' }}>
              Информация о журнале
            </h2>

            {!isAutogenerated && (
              <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
                <div className='width50Percent'>
                  <div style={{ width: '100%' }}>
                    <Measurement
                      measurementId={measurement}
                      setMeasurementId={changeMeasurement}
                      label={{
                        text: 'Область измерения',
                        topBg: 'white',
                        bottomBg: 'white',
                      }}
                    />
                  </div>
                </div>

                <div className='width50Percent'>
                  <Input
                    label={'№ комплекта СП'}
                    type={'text'}
                    value={caseNum || ''}
                    actions={{
                      change: handleCaseNumChange,
                    }}
                  />
                </div>
              </div>
            )}

            {!isAutogenerated && (
              <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
                <div className='width50Percent'>
                  <Input
                    label={'Выдавший (Ф.И.О и должность)'}
                    placeholder={'Введите ФИО выдавшего комплект'}
                    type={'text'}
                    value={issuedByName || ''}
                    actions={{
                      change: handleIssuedByNameChange,
                    }}
                  />
                </div>

                <div className='width50Percent'>
                  <Input
                    label={'Принявший (Ф.И.О и должность)'}
                    placeholder={'Введите ФИО принявшего комплект'}
                    type={'text'}
                    value={receivedByName || ''}
                    actions={{
                      change: handleReceivedNameChange,
                    }}
                  />
                </div>
              </div>
            )}

            {!isAutogenerated && (
              <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
                <div className='width50Percent'>
                  <p
                    style={{
                      marginBottom: '9px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Дата выдачи комплекта
                    {!!issueDate ? (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '0px',
                        }}
                      />
                    ) : (
                      <ErrorIcon
                        sx={{
                          color: '#FFD43B',
                          marginLeft: '8px',
                          marginTop: '0px',
                        }}
                      />
                    )}
                  </p>
                  <DatePicker selectedDate={issueDate} dateChange={handleIssuedDateChange} />
                </div>
                <div className='width50Percent' style={{ paddingLeft: '14px' }}>
                  <div>
                    <p
                      style={{
                        marginBottom: '9px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Отметка о возврате
                    </p>
                    <Approved
                      approved={isReturned}
                      changeApproved={changeIsReturnedChecked}
                      labelTrue={'Присутствует'}
                      labelFalse={'Отсутствует'}
                      valueTrue={true}
                      valueFalse={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {isAutogenerated && (
              <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
                <div className='width50Percent'>
                  <p
                    style={{
                      marginBottom: '9px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Отметка о возврате
                  </p>
                  <Approved
                    approved={isReturned}
                    changeApproved={changeIsReturnedChecked}
                    labelTrue={'Присутствует'}
                    labelFalse={'Отсутствует'}
                    valueTrue={true}
                    valueFalse={false}
                  />
                </div>
              </div>
            )}

            <div className='flexContainerWithGap'>
              <div className='width50Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Дата возврата комплекта
                  {isReturned ? (
                    !!receivedDate ? (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    ) : (
                      <ErrorIcon
                        sx={{
                          color: '#FFD43B',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    )
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={receivedDate}
                  dateChange={handleReceivedDateChange}
                  minDate={issueDate ? dayjs(issueDate) : null}
                />
              </div>
              <div className='width50Percent' />
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Редактировать запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Edit
