// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import { useEffect, useRef } from "react"

const useDidMountEffect = (effect: Function, deps: Array<any>): void => {
  // ----------------------------------------------------------------
  // deps может быть только массивом
  // ----------------------------------------------------------------
  
  const firstMount = useRef(true)

  useEffect(() => {
    if ( firstMount.current ) { firstMount.current = false } 
    else { return effect() }
  }, deps)
}

export default useDidMountEffect