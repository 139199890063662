// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenuVertical'

import moment from 'moment'
import cases from '../../../img/menu/cases.svg'

import R from '../../../services/app/request.service'
import Feedback from '../../components/LocalElements/Feedback/Feedback'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import { setShow } from '../../../store/slices/app/comps/feedback'

import Fab from '@mui/material/Fab'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const ShowCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { cases_id } = useParams()
  const { width } = useWindowDimensions()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const show = useSelector(state => state.feedbackReducer.show)

  const [data, setData] = useState([])
  const [viewVariant, setViewVariant] = useState(0)
  const userRole = useSelector(state => state.headerReducer.role)

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: caseData } = await R.getCasesShow(cases_id)
        const data = caseData.data

        setData(data)

        false && console.log(data)
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [])

  const navigateToProtocols = () => {
    navigate('/metriva/cases')
  }

  const capitalizeFirstLetter = string => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          {width <= 1050 && (
            <Fab
              color='primary'
              aria-label='add'
              onClick={navigateToProtocols}
              style={{
                position: 'absolute',
                top: '100%',
                marginTop: '-78.8px',
              }}
            >
              <ArrowBackIosNewIcon style={{ marginRight: '3px' }} />
            </Fab>
          )}
          {width > 1050 && (
            <div
              className='path_to_back'
              onClick={navigateToProtocols}
              style={{ marginBottom: '50px' }}
            >
              <i
                className='fas fa-arrow-left icon_back'
                style={{ width: '30px', height: '30px', marginRight: '6px' }}
              />
              <p>Назад к списку комплектов</p>
            </div>
          )}

          <div
            className='box'
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              paddingTop: '60px',
            }}
          >
            {/* ---------------------------------------------------------------- */}
            {/* в конечном итоге превратить данный блок в полноценные хлебные крошки */}
            {/* ---------------------------------------------------------------- */}

            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                width: 'calc(100% - 21px)',
                position: 'absolute',
                top: 0,
                marginTop: '-40px',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '80px',
                  height: '80px',
                  zIndex: 2,
                  borderRadius: '50%',
                  backgroundColor: '#e5f3fc',
                  boxShadow:
                    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                }}
              >
                <img
                  alt=''
                  src={cases}
                  style={{
                    width: '60%',
                  }}
                />
              </span>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  width: '100%',
                  background: 'linear-gradient(to right, #e5f3fc 40%, white 80% 100%)',
                  height: '50px',
                  alignItems: 'center',
                  paddingLeft: '30px',
                  paddingRight: '20px',
                  marginLeft: '-14px',
                  borderRadius: '12px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  borderTop: '1px solid #d7ddea',
                  borderRight: '1px solid #d7ddea',
                  gap: '13px',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <span className='fas fa-arrow-left icon_back' onClick={navigateToProtocols} />
                </div>
                <span style={{ fontSize: '13px' }}>Метрива</span>
                <span
                  style={{
                    display: 'block',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: 'gray',
                  }}
                />
                <span style={{ fontSize: '13px' }}>Комплекты СП</span>
                <span
                  style={{
                    display: 'block',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: 'gray',
                  }}
                />
                <span style={{ fontSize: '13px' }}>Комплект СП № {data?.inner_name}</span>

                <div style={{ display: 'flex' }}>
                  <span
                    className='fas fa-pencil-alt icon_back'
                    onClick={() => navigate(`/metriva/cases/${cases_id}/edit`)}
                  />
                </div>
              </div>
            </div>

            {/* ---------------------------------------------------------------- */}
            {/* ---------------------------------------------------------------- */}

            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  width: '20%',
                  marginRight: '40px',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '16px',
                }}
              >
                <h2 style={{ width: '100%', textAlign: 'center', lineHeight: '28px' }}>
                  Комплект СП № {data?.inner_name}
                </h2>
                <BasicTabs
                  viewVariant={viewVariant}
                  callback={setViewVariant}
                  tabs={
                    data.kits
                      ? [
                          { label: 'Внутренние данные комплекта' },
                          { label: 'Область измерения' },
                        ].concat(data.kits.map(kit => ({ label: kit.device.name })))
                      : [{ label: 'Внутренние данные комплекта' }, { label: 'Область измерения' }]
                  }
                />
              </div>

              {viewVariant === 0 && (
                <div style={{ width: '80%' }}>
                  <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                    <p>Внутренний номер СП :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      {data.inner_name ? data.inner_name : 'Нет информации'}
                    </h4>
                  </div>

                  <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                    <p>Поверитель :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      {data.arshin_name ? data.arshin_name : 'Нет информации'}
                    </h4>
                  </div>

                  <div className='flexContainerWithGap'>
                    <p>Снилс :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      {data.snils ? data.snils : 'Нет информации'}
                    </h4>
                  </div>
                </div>
              )}
              {viewVariant === 1 && (
                <div style={{ width: '80%' }}>
                  <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                    <p>Область измерения :</p>
                    <h4 style={{ fontWeight: 500 }}>{data?.measurement?.name}</h4>
                  </div>

                  <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                    <p>Методика поверки :</p>
                    <h4 style={{ fontWeight: 500 }}>{data?.method?.name}</h4>
                  </div>

                  <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                    <p>Тип поверки :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      {capitalizeFirstLetter(data?.verificationType?.name)}
                    </h4>
                  </div>

                  <div className='flexContainerWithGap'>
                    <p>Пункты методики :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      {data?.points?.map(item => item.name).join(', ')}
                    </h4>
                  </div>
                </div>
              )}

              {data.kits && viewVariant > 1 && (
                <div style={{ width: '80%' }}>
                  {data.kits.map((item, index) => (
                    <React.Fragment>
                      {viewVariant === index + 2 && (
                        <div key={item.device_id}>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Тип эталона :</p>
                            <h4 style={{ fontWeight: 500 }}>{item.device_sign}</h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Заводской номер :</p>
                            <h4 style={{ fontWeight: 500 }}>{item.device_manufacturer_number}</h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Регистрационный номер :</p>
                            <h4 style={{ fontWeight: 500 }}>{item.reg_number}</h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Свидетельство о поверке :</p>
                            <h4 style={{ fontWeight: 500 }}>{item.device_description}</h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Место проведения поверки :</p>
                            <h4 style={{ fontWeight: 500 }}>
                              {item.verification_place || 'Неизвестно'}
                            </h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Ссылка :</p>
                            <h4 style={{ fontWeight: 500 }}>{item.device_link}</h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Отправляется в Аршин :</p>
                            <h4 style={{ fontWeight: 500 }}>
                              {item.is_send_to_arshin ? 'Отправляется' : 'Не отправляется'}
                            </h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Дата поверки :</p>
                            <h4 style={{ fontWeight: 500 }}>
                              {moment(item.verified_at).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
                            <p>Поверка действительна до :</p>
                            <h4 style={{ fontWeight: 500 }}>
                              {moment(item.expired_at).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default ShowCases
