// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { CSSObject } from 'styled-components'

import PdfDownloadButton from '../../../../services/app/pdf-meters.service'
import PdfIcon from '../../../../img/icon/PdfIcon'
import Tooltip from '@mui/material/Tooltip'

interface ITableMobileProps {
  data: Array<[]>,
  css: CSSObject,
  rowCallback: Function,
  rowCallbackParams: string,
  controlIconButton: "getPDF" | null
}

const TableMobile = (props: ITableMobileProps): ReactElement => {
  const { 
    data,
    css,
    rowCallback = () => {},
    rowCallbackParams = '',
    controlIconButton = null
  } = props

  const idMeasurement = useSelector((state: any) => state.meterReducer.measurement_id)

  const [ tooltipVisible, setTooltipVisible ] = useState<boolean>(false)
  const [ tooltipIndex, setTooltipIndex ] = useState<number | null>(null)

  return (
    <React.Fragment>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
      >
        { data.map((item: Array<Array<[]>>, index: number): ReactElement => {
          return (
            <div
              style={{
                display: 'block',
                position: 'relative',
                width: '100%',
                border: '1px solid #d7ddea',
                backgroundColor: 'white',
                minHeight: '40px',
                borderRadius: '12px',
                marginTop: '14px',
                padding: '20px 22px 50px'
              }}
            >
              { controlIconButton === "getPDF" && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    position: 'absolute',
                    left: '100%',
                    marginLeft: '-55px',
                    marginTop: '3px',
                    zIndex: 2,
                    fontSize: '33px'
                  }}
                >
                  { item[1] && <React.Fragment>
                    <PdfIcon 
                      style={{
                        fontSize: 'inherit',
                        position: 'absolute',
                      }}
                    />
                    <PdfDownloadButton
                      measurementId={idMeasurement} 
                      meterId={item[0][1]} 
                      title={item[2][1].toString()}
                      isMask={true}
                    />
                  </React.Fragment> }
                  { !!!item[1] && <React.Fragment>
                    <div style={{ position: 'relative' }}>
                      <PdfIcon 
                        onClick={(): void => {
                          setTooltipVisible(true)
                          setTooltipIndex(index)
                          setTimeout(() => {
                            setTooltipVisible(false)
                            setTooltipIndex(null)
                          }, 1000)
                        }}
                        style={{
                          fontSize: 'inherit',
                          position: 'relative',
                          opacity: 0.5
                        }}
                      />
                      <span
                        style={{
                          display: 'block',
                          position: 'absolute',
                          width: '280px',
                          left: 0,
                          top: 0,
                          marginTop: '-50px',
                          marginLeft: '-225px',
                          backgroundColor: '#132532d9',
                          color: 'white',
                          textAlign: 'center',
                          padding: '8px 12px 10px',
                          borderRadius: '6px',
                          transition: 'all 300ms',
                          opacity: 
                            ( tooltipVisible && index === tooltipIndex ) ? 1 : 0
                        }}
                      >
                        PDF с описанием типа отсутствует
                      </span>
                    </div>
                  </React.Fragment> }
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '100%',
                  left: '100%',
                  marginLeft: '-170px',
                  marginTop: '-48px',
                  gap: '8px',
                  cursor: 'pointer'
                }}
              >
                <span 
                  onClick={() => {
                    rowCallback(item[0][1])
                  }}
                  style={{ 
                    color: 'rgb(0, 132, 226)',
                    fontSize: '18px',
                    fontWeight: 500,
                    letterSpacing: '1px'
                  }}
                >
                  Подробнее
                </span>
                <ArrowForwardIcon 
                  style={{
                    color: 'rgb(0, 132, 226)',
                    marginTop: '3px'
                  }}
                />
              </div>
              { item.map((contentLine, index) => {
                if ( index > 1 ) return (
                  <div
                    style={{
                      display: 'block',
                      marginBottom: '20px'
                    }}
                  >
                    <h5
                      style={{
                        display: 'block',
                        position: 'relative',
                        fontSize: '18px',
                        fontWeight: 600,
                        marginBottom: '4px'
                      }}
                    >
                      { contentLine[0] }
                    </h5>
                    <span
                      style={{
                        display: 'block',
                        position: 'relative',
                        fontSize: '16px',
                        fontWeight: 300,
                        lineHeight: '23px'
                      }}
                    >
                      { contentLine[1] }
                    </span>
                  </div>
                )

                return null
              })}
            </div>
          )
        })}
      </section>
    </React.Fragment>
  )
}

export default TableMobile