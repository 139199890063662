// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'

const CheckPlace = () => {
  const dispatch = useDispatch()
  const methodPlaces = useSelector(state => state.casesReducer.createData.methodPlaces)

  const options = [
    { value: 'labaratory', label: 'Лабораторная поверка' },
    { value: 'field', label: 'Выездная поверка' },
  ]

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'methodPlaces',
        label: selectedIds,
      })
    )

    dispatch(
      changeData({
        value: 'methodPoints',
        label: [],
      })
    )

    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )
    }
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: '14px' }}>
        <p
          style={{
            marginBottom: '9px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
        >
          Способ проведения
          {methodPlaces.length === 0 ? (
            <ErrorIcon
              sx={{
                color: '#FFD43B',
                marginLeft: '8px',
                marginTop: '0px',
              }}
            />
          ) : (
            <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
          )}
        </p>
        <Selector
          placeholder={'Выберите необходимые пункты'}
          isClearable={false}
          isSearchable={false}
          isMulti={true}
          options={options}
          onChange={getValue}
          value={options.filter(option => methodPlaces.includes(option.value))}
          noOptionsMessage={() => 'Вы выбрали все доступные пункты для этой методики'}
        />
      </div>
    </React.Fragment>
  )
}

export default CheckPlace
