import React, { useState, useEffect } from "react"
import api from "../../../../services/app/axios-config.service"
import D from "../../../../store/localStorage/dispatcher"
import Selector from "../Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"
import { setCompanyId } from "../../../../store/slices/app/controlers/companyId"

const FilterCompany = ({ disabled = false }) => {
  const dispatch = useDispatch()

  const userRole = useSelector((state) => state.headerReducer.role)
  const token = useSelector((state) => state.authReducer.authData.token)
  const companyId = useSelector((state) => state.companyIdReducer.companyId)

  const [data, setData] = useState(null)
  const isClearable = true
  const isSearchable = true

  const options = data
    ? data.map((company) => ({
        value: company.id,
        label: company.name,
      }))
    : []

  const getValue = (selectedOption) => {
    const newCompanyId = selectedOption ? selectedOption.value : ""
    dispatch(setCompanyId(newCompanyId))
    D.set("selectedCompanyId", newCompanyId)
  }

  useEffect(() => {
    const savedCompanyId = D.get("selectedCompanyId")
    if (savedCompanyId) {
      dispatch(setCompanyId(parseInt(savedCompanyId, 10)))
    }

    const fetchCompaniesData = async () => {
      try {
        if (userRole === "super") {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/list`
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          }
          const response = await api.get(apiUrl, { headers })
          const companyData = response.data.data
          setData(companyData)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchCompaniesData()
  }, [token, userRole, dispatch])

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите компанию"}
        options={options}
        onChange={getValue}
        value={options.find((option) => option.value === companyId)}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных компаний"}
        isDisabled={disabled}
        label={{
          text: "Компания",
          topBg: "#f8f9fc",
          bottomBg: "white",
        }}
      />
    </React.Fragment>
  )
}

export default FilterCompany
