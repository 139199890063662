// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AsyncSelector from '../../LocalElements/Selector/reactSelect/Selector.async.customer'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import Approved from '../../LocalElements/Filter/Approved'
import R from '../../../../services/app/request.service'
import { changeTickets, resetTickets } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshTickets } from '../../../../store/slices/app/controlers/updater'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const measurement = useSelector(state => state.journalReducer.tickets.measurement)
  const createdAt = useSelector(state => state.journalReducer.tickets.createdAt)
  const createdBy = useSelector(state => state.journalReducer.tickets.createdBy)
  const customerName = useSelector(state => state.journalReducer.tickets.customerName)
  const customerPhone = useSelector(state => state.journalReducer.tickets.customerPhone)
  const customerAddress = useSelector(state => state.journalReducer.tickets.customerAddress)
  const verificationDate = useSelector(state => state.journalReducer.tickets.verificationDate)
  const isCheckedMark = useSelector(state => state.journalReducer.tickets.isCheckedMark)
  const arshinFullName = useSelector(state => state.journalReducer.tickets.arshinFullName)
  const numberAgreement = useSelector(state => state.journalReducer.tickets.numberAgreement)
  const comment = useSelector(state => state.journalReducer.tickets.comment)

  const handleCreatedByChange = param => {
    dispatch(changeTickets({ value: 'createdBy', label: param }))
  }
  const handleCustomerNameChange = param => {
    dispatch(changeTickets({ value: 'customerName', label: param }))
  }
  const handleArshinFullNameChange = param => {
    dispatch(changeTickets({ value: 'arshinFullName', label: param }))
  }
  const handleNumberAgreementChange = param => {
    dispatch(changeTickets({ value: 'numberAgreement', label: param }))
  }
  const handleCommentChange = param => {
    dispatch(changeTickets({ value: 'comment', label: param }))
  }
  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }
    dispatch(
      changeTickets({
        value: 'customerPhone',
        label: formattedPhoneNumber,
      }),
    )
  }
  const changePhone = param => {
    formatPhoneNumber(param)
  }
  const changeMeasurement = param => {
    dispatch(changeTickets({ value: 'measurement', label: param }))
  }
  const changeIsChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeTickets({ value: 'isCheckedMark', label: value }))
  }
  const handleCreatedAtChange = date => {
    if (date) {
      dispatch(changeTickets({ value: 'createdAt', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeTickets({ value: 'createdAt', label: '' }))
    }
  }
  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDate', label: '' }))
    }
  }
  const changeAddress = selectedOption => {
    dispatch(
      changeTickets({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const isPhoneValid = phone => {
    const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/
    return phoneRegex.test(phone)
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map(regionId => regionId.id)
      setRegionValue(region)

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurement,
      customer_arshin_name: createdBy,
      customer_address: customerAddress,
      is_checked: isCheckedMark,
      arshin_master_name: arshinFullName,
      comment: comment,
      customer_fullname: customerName,
      customer_phone: customerPhone,
      contact_date: createdAt,
      planned_date: verificationDate,
      agreement_number: numberAgreement,
    }

    const { status } = await R.addTickets(companyId, data)

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshTickets())
      dispatch(resetTickets())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  useEffect(() => {
    return () => {
      dispatch(resetTickets())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все записи документа</p>
          </div>

          <div className='path_to_edit'>
            <h4>Журнал регистрации заявок на поверку</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '14px' }}>Информация о журнале</h2>
            <div className='width50Percent' style={{ margin: '0px 0 25px', width: '100%' }}>
              <p
                style={{
                  marginBottom: '9px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Область измерения
                {!!measurement ? (
                  <CheckCircleIcon
                    sx={{
                      color: '#63E6BE',
                      marginLeft: '8px',
                      marginTop: '-0px',
                    }}
                  />
                ) : (
                  <ErrorIcon
                    sx={{
                      color: '#FFD43B',
                      marginLeft: '8px',
                      marginTop: '-0px',
                    }}
                  />
                )}
              </p>
              <div style={{ width: '100%' }}>
                <Measurement measurementId={measurement} setMeasurementId={changeMeasurement} />
              </div>
            </div>
            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div className='width50Percent'>
                <Input
                  label={'Владелец СИ'}
                  placeholder={'Напишите имя владельца СИ'}
                  type={'text'}
                  value={createdBy || ''}
                  actions={{
                    change: handleCreatedByChange,
                  }}
                />
              </div>

              <div className='width50Percent'>
                <Input
                  label={'Ф.И.О инженера по метрологии'}
                  placeholder={'Напишите Ф.И.О инженера'}
                  type={'text'}
                  value={arshinFullName || ''}
                  actions={{
                    change: handleArshinFullNameChange,
                  }}
                />
              </div>
            </div>

            {tariffIds && tariffIds.includes(6) && (
              <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
                <div className='width50Percent'>
                  <Input
                    label={'Ф.И.О. заказчика'}
                    placeholder={'Напишите Ф.И.О заказчика'}
                    type={'text'}
                    value={customerName || ''}
                    actions={{
                      change: handleCustomerNameChange,
                    }}
                  />
                </div>

                <div className='width50Percent'>
                  <Input
                    label={'Контактный телефон'}
                    placeholder={'+7 (ХХХ) ХХХ-ХХ-ХХ'}
                    type={'text'}
                    value={customerPhone || ''}
                    actions={{
                      change: changePhone,
                    }}
                    isValid={isPhoneValid(customerPhone)}
                  />
                </div>
              </div>
            )}

            <div style={{ width: '100%', marginBottom: '20px' }}>
              <p
                style={{
                  marginBottom: '9px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Адрес поверки
                {!!customerAddress ? (
                  <CheckCircleIcon
                    sx={{
                      color: '#63E6BE',
                      marginLeft: '8px',
                      marginTop: '-0px',
                    }}
                  />
                ) : (
                  <ErrorIcon
                    sx={{
                      color: '#FFD43B',
                      marginLeft: '8px',
                      marginTop: '-0px',
                    }}
                  />
                )}
              </p>

              <AsyncSelector
                callback={changeAddress}
                optionKey={'suggestions'}
                options={async param =>
                  R.addresses(
                    param,
                    regionValue.map(regionId => ({ kladr_id: regionId })),
                  )
                }
                value={customerAddress}
              />
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div className='width32Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Плановая дата поверки
                  {!!verificationDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                />
              </div>

              <div className='width32Percent'>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Дата заявки
                  {!!createdAt ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </p>
                <DatePicker selectedDate={createdAt} dateChange={handleCreatedAtChange} />
              </div>

              <div className='width32Percent' style={{ paddingLeft: '14px' }}>
                <p
                  style={{
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Отметка о поверке
                  {isCheckedMark !== '' ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </p>
                <Approved
                  approved={isCheckedMark}
                  changeApproved={changeIsChecked}
                  labelTrue={'Присутствует'}
                  labelFalse={'Отсутствует'}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>
            </div>

            <div className='flexContainerWithGap'>
              <div className='width32Percent'>
                <Input
                  notRequired={true}
                  label={'Номер договора'}
                  placeholder={'Напишите номер договора'}
                  type={'text'}
                  value={numberAgreement || ''}
                  actions={{
                    change: handleNumberAgreementChange,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  notRequired={true}
                  label={'Примечание к записи'}
                  placeholder={'Примечание к записи в журнале'}
                  type={'text'}
                  value={comment || ''}
                  actions={{
                    change: handleCommentChange,
                  }}
                />
              </div>

              <div className='width32Percent'></div>
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Добавить запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Add
