import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [],
  meta: null | undefined,
  isLoading: true,
  searchQuery: "",
  createData: {
    companyId: "",
    regionIds: [],
    rolesId: null,
    casesIds: [],
    userId: null,
    usersId: [],
    isTrusted: "",
    name: "",
    surname: "",
    patr: "",
    login: "",
    password: "",
    email: "",
  },
};

export const usersReducer = createSlice({
  name: "usersReducer",
  initialState,
  reducers: {
    changeData: (state, action) => {
      state.createData[action.payload.value] = action.payload.label;
    },
    resetCreateData: (state) => {
      state.createData = initialState.createData;
    },
    setList: (state, action) => {
      state.usersList = action.payload;
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  changeData,
  resetCreateData,
  setList,
  setMeta,
  setSearchQuery,
  setIsLoading,
} = usersReducer.actions;
export default usersReducer.reducer;
