// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'

import R from '../../../services/app/request.service'

import { resetCreateData } from '../../../store/slices/app/views/companies'
import { refreshCompanies, refreshHeader } from '../../../store/slices/app/controlers/updater'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

import NameCompany from '../../components/Companies/NameCompany'
import AddressCompany from '../../components/Companies/AddressCompany'
import LogoCompany from '../../components/Companies/LogoCompany'
import CompanyBank from '../../components/Companies/CompanyBank'
import RegionCompany from '../../components/Companies/RegionCompany'

const AddCompany = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)

  const name = useSelector(state => state.companiesReducer.createData.main.name)
  const ceo = useSelector(state => state.companiesReducer.createData.main.ceo)
  const number = useSelector(state => state.companiesReducer.createData.main.number)
  const email = useSelector(state => state.companiesReducer.createData.main.email)

  const regionId = useSelector(state => state.companiesReducer.createData.main.regionId)

  const address = useSelector(state => state.companiesReducer.createData.main.address)
  const logo = useSelector(state => state.companiesReducer.createData.main.logo)
  const rs = useSelector(state => state.companiesReducer.createData.over.rs)
  const ks = useSelector(state => state.companiesReducer.createData.over.ks)
  const bank = useSelector(state => state.companiesReducer.createData.over.bank)
  const inn = useSelector(state => state.companiesReducer.createData.over.inn)
  const kpp = useSelector(state => state.companiesReducer.createData.over.kpp)
  const ogrn = useSelector(state => state.companiesReducer.createData.over.ogrn)
  const bik = useSelector(state => state.companiesReducer.createData.over.bik)
  const bankAddress = useSelector(state => state.companiesReducer.createData.over.bankAddress)
  const code = useSelector(state => state.companiesReducer.createData.over.code)
  const num = useSelector(state => state.companiesReducer.createData.over.num)
  const orderNum = useSelector(state => state.companiesReducer.createData.over.orderNum)
  const orderDate = useSelector(state => state.companiesReducer.createData.over.orderDate)

  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      address: address,
      region_id: regionId,
      phone: number.replace(/\D/g, ''),
      email: email,
      inn: inn,
      kpp: kpp,
      image: logo,
      bin: ogrn,
      checking_account: rs,
      bank_address: bankAddress,
      bank_name: bank,
      bic: bik,
      correspondent_account: ks,
      director_full_name: ceo,
      sign_cipher: code,
      accreditation_unique_id: num,
      agreement_number: orderNum,
      agreement_date: orderDate,
    }

    const { status, data: companiesAddData } = await R.addCompanies(data)

    if (status === 200) {
      dispatch(setMessage('Компания успешно создана'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData(resetCreateData))
      dispatch(refreshCompanies())
      dispatch(refreshHeader())
      navigate('/metriva/companies')
    } else if (status === 422) {
      if (companiesAddData.errors) {
        const errors = companiesAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Компания не создана - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к api'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/companies')
  }

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='path_to_back' onClick={navigateToProtocols}>
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Назад к списку компаний</p>
      </div>

      <div className='box'>
        <h2 style={{ marginBottom: '25px' }}>Основные данные компании</h2>

        <NameCompany validationErrors={validationErrors} />

        <div className='flexContainer' style={{ marginBottom: '15px' }}>
          <RegionCompany validationErrors={validationErrors} />

          <AddressCompany validationErrors={validationErrors} />
        </div>

        <LogoCompany />
      </div>

      <CompanyBank validationErrors={validationErrors} />

      <div style={{ marginTop: '14px' }}>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            margin: 0,
            borderRadius: '12px',
            width: '100%',
          }}
        >
          Сохранить компанию
        </Button>
      </div>
    </main>
  )
}

export default AddCompany
