import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../../services/app/axios-config.service'

import Selector from '../Selector/reactSelect/Selector'

const MasterArshin = ({ userId, setUserId }) => {
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const token = useSelector(state => state.authReducer.authData.token)
  const [data, setData] = useState([])

  const options = data
    ? data.map(data => ({
        value: data?.arshin_master_name,
        label: data?.arshin_master_name,
      }))
    : []

  const getValue = selectedOption => {
    setUserId(selectedOption ? selectedOption.value : null)
  }

  useEffect(() => {
    const fetchMasterArshin = async () => {
      try {
        if (userRole === 'super' || userRole === 'admin') {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/arshin-master-name-list`
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }
          const response = await api.get(apiUrl, { headers })
          const metrologist = response.data.data
          setData(metrologist)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchMasterArshin()
  }, [token, userRole])

  return (
    <React.Fragment>
      <Selector
        placeholder={'Выберите поверителя'}
        options={options}
        onChange={getValue}
        isClearable={true}
        isSearchable={true}
        value={userId ? options.find(option => option.value === userId) : null}
        noOptionsMessage={() => 'Нет доступных поверителей'}
      />
    </React.Fragment>
  )
}

export default MasterArshin
