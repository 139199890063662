// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import Approved from '../../LocalElements/Filter/Approved'
import R from '../../../../services/app/request.service'
import { changePersonal, resetPersonal } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshPersonalMetrolog } from '../../../../store/slices/app/controlers/updater'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurement = useSelector(state => state.journalReducer.personal.measurement)
  const numSi = useSelector(state => state.journalReducer.personal.numSi)
  const nameSi = useSelector(state => state.journalReducer.personal.nameSi)
  const typeSi = useSelector(state => state.journalReducer.personal.typeSi)
  const numFactory = useSelector(state => state.journalReducer.personal.numFactory)
  const verificationDate = useSelector(state => state.journalReducer.personal.verificationDate)
  const numNotification = useSelector(state => state.journalReducer.personal.numNotification)
  const numProtocol = useSelector(state => state.journalReducer.personal.numProtocol)

  const changeMeasurement = param => {
    dispatch(changePersonal({ value: 'measurement', label: param }))
  }

  const changeNumSi = param => {
    const validInput = param.replace(/[^0-9\- ]/g, '')
    dispatch(changePersonal({ value: 'numSi', label: validInput }))
  }

  const changeNameSi = param => {
    dispatch(changePersonal({ value: 'nameSi', label: param }))
  }

  const changeIsNameSi = event => {
    dispatch(changePersonal({ value: 'nameSi', label: event.target.value }))
  }

  const changeTypeSi = param => {
    dispatch(changePersonal({ value: 'typeSi', label: param }))
  }

  const changeNumFactory = param => {
    dispatch(changePersonal({ value: 'numFactory', label: param }))
  }

  const handleNumNotification = param => {
    dispatch(changePersonal({ value: 'numNotification', label: param }))
  }

  const handleNumProtocol = param => {
    dispatch(changePersonal({ value: 'numProtocol', label: param }))
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDate', label: '' }))
    }
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurement,
      fif_number: numSi,
      customer_arshin_name: nameSi,
      name_type_si: typeSi,
      meter_factory_number: numFactory,
      verification_date: verificationDate,
      arshin_id: numNotification,
      protocol_number: numProtocol,
    }

    const { status } = await R.addPersonalMetrolog(companyId, masterArshine, data)

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshPersonalMetrolog())
      dispatch(resetPersonal())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все записи документа</p>
          </div>

          <div className='path_to_edit'>
            <h4>Персональный журнал инженера по метрологии</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '25px' }}>Информация о журнале</h2>
            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div className='width32Percent'>
                <div style={{ width: '100%' }}>
                  <Measurement
                    measurementId={measurement}
                    setMeasurementId={changeMeasurement}
                    label={{
                      text: 'Область измерения',
                      topBg: 'white',
                      bottomBg: 'white',
                    }}
                  />
                </div>
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Наименование, тип СИ'}
                  placeholder={'Введите наименование и тип СИ'}
                  type={'text'}
                  value={typeSi}
                  actions={{
                    change: changeTypeSi,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'№ СИ в госреетсре'}
                  placeholder={'Пример ввода - 349392-93'}
                  type={'text'}
                  value={numSi}
                  actions={{
                    change: changeNumSi,
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div className='width32Percent'>
                <Input
                  label={'Заводской номер'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  value={numFactory}
                  actions={{
                    change: changeNumFactory,
                  }}
                />
              </div>

              <div style={{ gap: '8px', display: 'flex', flexFlow: 'column', width: '66%' }}>
                <Input
                  label={'Наименование владельца СИ'}
                  placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                  type={'text'}
                  value={nameSi}
                  actions={{
                    change: changeNameSi,
                  }}
                />
                <Approved
                  approved={nameSi}
                  changeApproved={changeIsNameSi}
                  labelTrue={'Физическое лицо'}
                  labelFalse={'Юридическое лицо'}
                  valueTrue={'Физическое лицо'}
                  valueFalse={'Юридическое лицо'}
                />
              </div>
            </div>

            <div className='flexContainerWithGap'>
              <div className='width32Percent'>
                <Input
                  label={'№ свидетельства или извещения'}
                  placeholder={'Пример ввода - С-АИК/24-09-2024/372708413'}
                  type={'text'}
                  value={numNotification}
                  actions={{
                    change: handleNumNotification,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Номер протокола поверки'}
                  placeholder={'Введите номер протокола'}
                  type={'text'}
                  value={numProtocol}
                  actions={{
                    change: handleNumProtocol,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                  label='Дата поверки'
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Добавить запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Add
