// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from '@mui/material'

import Input from '../../components/LocalElements/Input/InputMUI'
import R from '../../../services/app/request.service'
import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import { resetCreateData, changeData } from '../../../store/slices/app/views/masterArshin'
import { refreshMasterArshin } from '../../../store/slices/app/controlers/updater'

const AddOfficialUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [data, setData] = useState([])
  const userRole = useSelector(state => state.headerReducer.role)
  const companyId = useSelector(state => state.companyIdReducer.companyId)
  const name = useSelector(state => state.masterArshinReducer.createData.name)
  const surname = useSelector(state => state.masterArshinReducer.createData.surname)
  const patr = useSelector(state => state.masterArshinReducer.createData.patr)

  const role = useSelector(state => state.masterArshinReducer.createData.role)
  const snils = useSelector(state => state.masterArshinReducer.createData.snils)

  const measurementIds = useSelector(state => state.masterArshinReducer.createData.measurementIds)

  const handleNameChange = param => {
    dispatch(changeData({ value: 'name', label: param }))
  }
  const handleSurNameChange = param => {
    dispatch(changeData({ value: 'surname', label: param }))
  }
  const handlePatrNameChange = param => {
    dispatch(changeData({ value: 'patr', label: param }))
  }
  const handleRoleChange = param => {
    dispatch(changeData({ value: 'role', label: param }))
  }
  const handleSnilsChange = param => {
    let formattedValue = param
      .replace(/\D/g, '')
      .slice(0, 11)
      .replace(/(\d{3})(?=\d{3})/g, '$1-')
      .replace(/(\d{2})$/, ' $1')
    dispatch(changeData({ value: 'snils', label: formattedValue }))
  }

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(changeData({ value: 'measurementIds', label: selectedIds }))
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      surname: surname,
      patr: patr,
      post: role,
      snils: snils,
      measurements: measurementIds,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status, data: addCaseResponse } = await R.postMaster(data)

    if (status === 200) {
      dispatch(setMessage('Мастер успешно создан.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshMasterArshin())
      navigate('/metriva/cases')
    } else if (status === 422) {
      dispatch(setMessage('Мастер не добавлен - ошибка валидации'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage('Ошибка обращения к api'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/cases')
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='path_to_back' onClick={navigateToProtocols}>
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Все официальные пользователи СП</p>
      </div>

      <div className='box'>
        <h2 style={{ marginBottom: '25px' }}>Основные данные</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div className='flexContainerWithGap'>
            <div className='width32Percent'>
              <Input
                label={'Фамилия пользователя'}
                type={'text'}
                placeholder={'Введите фамилию'}
                value={surname}
                actions={{
                  change: handleSurNameChange,
                }}
              />
            </div>

            <div className='width32Percent'>
              <Input
                label={'Имя пользователя'}
                type={'text'}
                placeholder={'Введите имя'}
                value={name}
                actions={{
                  change: handleNameChange,
                }}
              />
            </div>

            <div className='width32Percent'>
              <Input
                label={'Отчество (необязательно)'}
                type={'text'}
                placeholder={'Введите отчество'}
                value={patr}
                actions={{
                  change: handlePatrNameChange,
                }}
              />
            </div>
          </div>

          <div className='flexContainerWithGap'>
            <div className='width32Percent'>
              <Input
                label={
                  <div>
                    Должность в компании
                    <Tooltip
                      title='Рабочая должность официального пользователя комплекта СП, нужна для заполнения журнала выдачи средств поверки'
                      placement='top'
                      style={{ marginLeft: '7px' }}
                    >
                      <i className='fas fa-question-circle question' />
                    </Tooltip>
                  </div>
                }
                type={'text'}
                placeholder={'Пример ввода - Поверитель СИ'}
                value={role}
                actions={{
                  change: handleRoleChange,
                }}
              />
            </div>

            <div className='width32Percent'>
              <Input
                label={
                  <div>
                    Снилс
                    <Tooltip
                      title='Информация необходима для передачи данных в Росаккредитацию (ФСА)'
                      placement='top'
                      style={{ marginLeft: '7px' }}
                    >
                      <i className='fas fa-question-circle question' />
                    </Tooltip>
                  </div>
                }
                type={'text'}
                placeholder={'XXX-XXX-XXX XX'}
                value={snils}
                actions={{
                  change: handleSnilsChange,
                }}
              />
            </div>

            <div className='width32Percent'>
              <Selector
                placeholder={'Выберите область измерния'}
                options={options}
                value={options.filter(option => measurementIds.includes(option.value))}
                onChange={getValue}
                isMulti={true}
                isSearchable={true}
                noOptionsMessage={() => 'Нет доступных областей измерения'}
                label={{
                  text: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Рабочая область измерения
                      <Tooltip
                        title='Область или области измерения, в рамках которой пользователь имеет официальное право осуществлять поверочную деятельность'
                        placement='top'
                      >
                        <i
                          className='fas fa-question-circle'
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  ),
                  topBg: 'white',
                  bottomBg: 'white',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '14px' }}>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            margin: 0,
            borderRadius: '12px',
            width: '100%',
          }}
        >
          Сохранить
        </Button>
      </div>
    </main>
  )
}

export default AddOfficialUser
