import React from 'react'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ru'

dayjs.locale('ru')

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  width: '100%',
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    border: 'none',
    backgroundColor: '#ffffff',
    paddingRight: '20px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d7ddea !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d7ddea !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #0084e2 !important',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #d7ddea',
  },
  '& .MuiInputBase-root': {
    borderRadius: '12px',
  },
  '& .MuiPickersDay-day': {
    borderRadius: '12px',
  },
  '& .MuiPickersCalendar-transitionContainer': {
    borderRadius: '12px',
  },
  '& .MuiSvgIcon-root': {
    color: '#0084e2',
  },
}))

const DatePicker = props => {
  const {
    selectedDate,
    dateChange,
    minDate,
    maxDate,
    label,
    isDisabled = false,
    disableFuture = false,
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale='ru'>
      <StyledDatePicker
        value={selectedDate ? dayjs(selectedDate) : null}
        onChange={dateChange}
        format='DD-MM-YYYY'
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        label={label ? label : null}
        disabled={isDisabled}
        slotProps={{
          textField: {
            inputProps: {
              readOnly: true,
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
