import { createSlice } from '@reduxjs/toolkit'

const loading = createSlice({
  name: 'loadingReducer',
  initialState: {
    loadingCount: 0,
  },
  reducers: {
    incrementLoading(state) {
      state.loadingCount += 1
    },
    decrementLoading(state) {
      state.loadingCount = Math.max(0, state.loadingCount - 1)
    },
  },
})

export const { incrementLoading, decrementLoading } = loading.actions
export default loading.reducer
