import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { setAuthToken } from '../../store/slices/app/controlers/auth'

const mapDispatchToProps = dispatch => ({
  reloadApp: () => {
    Cookies.remove('accessToken')
    dispatch(setAuthToken(''))
  },
})

const ErrorBoundaryHOCComponent = Component => connect(null, mapDispatchToProps)(Component)

export default ErrorBoundaryHOCComponent
