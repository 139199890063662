import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Cases from '../app/pages/Cases/Cases'
import AddOfficialUser from '../app/pages/Cases/AddOfficialUser'
import EditOfficialUser from '../app/pages/Cases/EditOfficialUser'
import AddCases from '../app/pages/Cases/AddCases'
import ShowCases from '../app/pages/Cases/ShowCases'
import EditCases from '../app/pages/Cases/EditCases'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteCases: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route index element={width > 1050 ? <Cases /> : <NotForMobile />} />
      <Route path='create' element={<AddCases />} />
      <Route path='create_official_user' element={<AddOfficialUser />} />
      <Route path=':cases_id' element={<ShowCases />} />
      <Route path=':cases_id/edit' element={<EditCases />} />
      <Route path=':master_id/edit_master' element={<EditOfficialUser />} />
    </Routes>
  )
}

export default RouteCases
