import React, { useState } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DeleteIcon = props => {
  const [hovered, setHovered] = useState(false)

  return (
    <SvgIcon
      {...props}
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        cursor: 'pointer',
        transition: 'fill 0.3s',
        transform: 'scale(0.85)',
        '& path': {
          fill: hovered ? '#0084E2' : '#899298',
        },
      }}
    >
      <path d='M2.48828 5.56354C2.48828 5.18001 2.7761 4.8691 3.13114 4.8691L5.35134 4.86873C5.79247 4.85665 6.18163 4.55365 6.33173 4.1054C6.33568 4.09362 6.34022 4.07908 6.35649 4.02633L6.45216 3.71625C6.5107 3.52613 6.5617 3.3605 6.63307 3.21245C6.91502 2.62755 7.43668 2.22139 8.0395 2.11741C8.19209 2.09109 8.35368 2.0912 8.53916 2.09133H11.4375C11.623 2.0912 11.7846 2.09109 11.9372 2.11741C12.54 2.22139 13.0617 2.62755 13.3436 3.21245C13.415 3.3605 13.466 3.52613 13.5245 3.71625L13.6202 4.02633C13.6365 4.07908 13.641 4.09362 13.645 4.1054C13.7951 4.55365 14.2614 4.85702 14.7026 4.8691H16.8454C17.2005 4.8691 17.4883 5.18001 17.4883 5.56354C17.4883 5.94706 17.2005 6.25798 16.8454 6.25798H3.13114C2.7761 6.25798 2.48828 5.94706 2.48828 5.56354Z' />
      <path d='M9.86892 18.7578H10.5247C12.7809 18.7578 13.9091 18.7578 14.6426 18.0385C15.376 17.3192 15.4511 16.1392 15.6012 13.7793L15.8174 10.3788C15.8989 9.09838 15.9396 8.45815 15.5716 8.05244C15.2036 7.64673 14.5822 7.64673 13.3395 7.64673H7.05416C5.81137 7.64673 5.18998 7.64673 4.82201 8.05244C4.45404 8.45815 4.49476 9.09838 4.57619 10.3788L4.79245 13.7793C4.94253 16.1392 5.01757 17.3192 5.75106 18.0385C6.48455 18.7578 7.61268 18.7578 9.86892 18.7578Z' />
    </SvgIcon>
  )
}

export default DeleteIcon
