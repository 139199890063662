// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import dayjs from 'dayjs'
import { Button, Tooltip } from '@mui/material'
import { RadioGroup, FormControlLabel, Radio, Collapse } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import ArchiveIcon from '../../../../img/icon/RestoreIcon'
import Fab from '@mui/material/Fab'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import Table from '../../LocalElements/Table/Table'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import SelectorJournals from '../Select/SelectJournal'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'

import Pdf from '../../../../img/icon/PdfIcon'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  setSearchQuery,
  setArchiveFromDate,
  setArchiveToDate,
  setComplete,
  setJournalIdFilter,
  setEntryId,
  setJournalId,
  changeArchived,
} from '../../../../store/slices/app/views/journals'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  showBD,
} from '../../../../store/slices/app/comps/modal'
import {
  refreshConditions,
  refreshPersonalMetrolog,
  refreshReceiveds,
  refreshTickets,
  refreshIssueCase,
  refreshEntriesArchive,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'

import R from '../../../../services/app/request.service'

const TableArchive = () => {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [deleteJournalDate, setDeleteJournalDate] = useState('')

  const userRole = useSelector(state => state.headerReducer.role)
  const isLoading = useSelector(state => state.journalReducer.isLoadingArchive)

  const data = useSelector(state => state.journalReducer.dataArchive)
  const journalIdFilter = useSelector(state => state.journalReducer.journalIdFilter)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const archiveFromDate = useSelector(state => state.journalReducer.archiveFromDate)
  const archiveToDate = useSelector(state => state.journalReducer.archiveToDate)
  const complete = useSelector(state => state.journalReducer.complete)

  const selectedRows = useSelector(state => state.journalReducer.archived.selectedRows)
  const deletedDate = useSelector(state => state.journalReducer.archived.deletedDate)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (journalIdFilter) filtersCount++
    if (archiveFromDate) filtersCount++
    if (archiveToDate) filtersCount++

    if (!complete && !journalIdFilter && !archiveFromDate && !archiveToDate) filtersCount = 0
    return filtersCount
  }, [archiveFromDate, archiveToDate, complete, journalIdFilter])

  const resetActions = [setComplete, setArchiveFromDate, setArchiveToDate, setJournalIdFilter]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const handleShowClick = param => {
    dispatch(setEntryId(+param.id))
    dispatch(setJournalId(+param.row.Title.split(' * ')[1]))
    dispatch(refreshEntriesArchive())

    navigate('/metriva/journals/archive_entries')
  }

  const handleUnarchive = async param => {
    const { status } = await R.unarchiveJournals(companyId, param)

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был успешно восстановлен'))

      dispatch(refreshArchives())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при восстановлении документа - ${status}`))
    }
  }

  const handleDelete = param => async deletedDateParam => {
    console.log(deletedDateParam)

    const { status } = await R.sheduleDeleteJournals(companyId, param, {
      deletion_scheduled_date: deletedDateParam,
    })

    if (status === 200) {
      dispatch(setOpenModal())

      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был поставлен на удаление'))

      dispatch(refreshArchives())
      setDeleteJournalDate('')
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при планировании удаления документа - ${status}`))
    }
  }

  const handleEditClick = param => {
    navigate(`/metriva/journals/${param}/edit_archives`)
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data } = await R.getArchiveEntriesPdf(
      companyId,
      '?' + selectedRows.map(row => `ids[]=${row}`).join('&'),
    )

    console.log(data)

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        selectedRows.length > 1 ? 'archive-selected.zip' : 'archive-selected.pdf',
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getArchivePdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getArchiveCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getArchiveExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  let colData = [
    { field: 'Num', headerName: '№ п/п', width: 120 },
    {
      field: 'IncomingDate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Дата переноса в архив</div>
      ),
      width: 230,
    },
    {
      field: 'Title',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Наименование документа</div>
      ),
      width: 300,
    },
    { field: 'RegNum', headerName: 'Учетный номер', width: 230 },
    { field: 'CountList', headerName: 'Количество листов', width: 230 },
    { field: 'WhoName', headerName: 'ФИО передавшего документ в архив', width: 430 },
    { field: 'FromArchiveDate', headerName: 'Дата выдачи из архива', width: 230 },
    { field: 'ToArchiveDate', headerName: 'Дата возврата в архив', width: 230 },
    { field: 'DestrDate', headerName: 'Дата уничтожения', width: 230 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 200,
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать запись' placement='top-end'>
              <div
                onClick={() => {
                  handleEditClick(localItem.row.id)
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <PencilIcon />
              </div>
            </Tooltip>
            {localItem.row.status ? (
              <Tooltip title='Редактировать запись' placement='top-end'>
                <div
                  onClick={() => {
                    handleEditClick(localItem.row.id)
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <ArchiveIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Восстановить документ' placement='top-end'>
                <div
                  onClick={() => {
                    dispatch(setTitle('Восстановление документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(showBA(true))
                    dispatch(showBD(true))
                    dispatch(setBA('Восстановить'))
                    dispatch(setBD('Отменить'))
                    dispatch(
                      setConfirmAction(() => {
                        handleUnarchive(+item.row.id)
                      }),
                    )
                    dispatch(setOpenModal(true))
                  }}
                >
                  <ArchiveIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Назначить дату удаления' placement='top-end'>
              <div
                onClick={() => {
                  dispatch(setTitle('Уничтожение документа'))
                  dispatch(setChildren(modalContentRemove))
                  dispatch(showBA(true))
                  dispatch(showBD(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setBD('Отменить'))
                  dispatch(
                    setConfirmAction(param => {
                      handleDelete(+item.row.id)(param)
                    }),
                  )
                  dispatch(setOpenModal(true))
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ]

  let rowData =
    data && data[0] !== null
      ? data.map(item => ({
          id: item.id,
          Num: item.number,
          IncomingDate: item.entry_date,
          Title: item?.journal_type_name + ' * ' + item?.journal_type_id,
          RegNum: item.account_number,
          CountList: item.pages,
          WhoName: item.created_by_name,
          FromArchiveDate: item.issue_date,
          ToArchiveDate: item.return_date,
          DestrDate: item.deletion_scheduled_date,
        }))
      : []

  useEffect(() => {
    return () => {
      false && handleResetFilter()

      dispatch(refreshConditions())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      dispatch(refreshIssueCase())
    }
  }, [])

  useEffect(() => {
    !!!false && console.log(deletedDate)
  }, [deletedDate])

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите восстановить выбранный документ? После восстановления, все архивные
        записи будут объединены с записями из вкладки "Актуальная версия документа".
      </span>
    </section>
  )

  const modalContentRemove = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, выберите дату планового уничтожения документа.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Обратите внимание, после уничтожения документ будет удалён из системы окончательно и
        восстановить его будет невозможно.
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>Дата уничтожения документа</span>
          <DatePicker
            selectedDate={deletedDate}
            dateChange={date => {
              dispatch(
                changeArchived({
                  value: 'deletedDate',
                  label: date.format('YYYY-MM-DD'),
                }),
              )
            }}
          />
        </div>
      </div>
    </section>
  )

  return (
    <main>
      <RightSideContainer blockTitle={'Фильтры'}>
        <div className='right-side-container'>
          <div className='right-side-container-block'>
            <span className='label-filter'>Наименование документа</span>
            <div style={{ width: '100%' }}>
              <SelectorJournals isLabel={false} variant='filter' />
            </div>
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter' style={{ opacity: 0.6 }}>
              Передавший в архив
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите нужного человека'}
                options={[]}
                onChange={() => {}}
                isClearable={true}
                isSearchable={true}
                isDisabled={true}
                value={''}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата переноса в архив</span>
            <DatePicker
              selectedDate={archiveFromDate}
              dateChange={date => dispatch(setArchiveFromDate(date.format('YYYY-MM-DD')))}
            />

            <DatePicker
              selectedDate={archiveToDate}
              dateChange={date => dispatch(setArchiveToDate(date.format('YYYY-MM-DD')))}
            />
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Заполнение записи</span>
            <div style={{ width: '100%' }}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={complete}
                onChange={event => dispatch(setComplete(event.target.value))}
              >
                <FormControlLabel
                  value={'non-complete'}
                  control={<Radio />}
                  label={'Частичное'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={'complete'}
                  control={<Radio />}
                  label={'Полное'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <Button
            variant='outlined'
            onClick={handleResetFilter}
            style={{
              width: '100%',
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </RightSideContainer>
      <div
        className='header_box'
        style={{
          marginTop: '12px',
          position: 'relative',
          paddingRight: count > 0 ? '50px' : '0px',
        }}
      >
        <div style={{ width: width > 1050 ? '80%' : '100%' }}>
          <SearchQuery
            searchQuery={searchQuery}
            setSearchQuery={changeSearchQuery}
            disabled={false}
          />
        </div>

        <div className='flexContainerWithGap' style={{ gap: 15 }}>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                <Pdf />
              </div>
            </Tooltip>
          )}
          <Tooltip title='Скачать документ в Csv' placement='top'>
            <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
              <Csv />
            </div>
          </Tooltip>
          <Tooltip title='Скачать документ в Excel' placement='top'>
            <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
              <Excel />
            </div>
          </Tooltip>
        </div>

        <div
          style={{
            position: 'relative',
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterAltIcon />}
            disabled={false}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '22px',
              height: '22px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: '11px',
              lineHeight: '21px',
              textAlign: 'center',
              top: 0,
              left: '100%',
              marginLeft: '-18px',
              marginTop: '-4px',
              transition: 'all 300ms',
              opacity: count > 0 ? 1 : 0,
            }}
          >
            {count}
          </span>
        </div>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </div>

      <Collapse in={selectedRows && selectedRows.length > 0}>
        <div
          className='flexContainerWithGap'
          style={
            width > 1050
              ? { marginTop: '11px', gap: '0px' }
              : { marginTop: '12px', gap: '0px', marginBottom: '0px' }
          }
        >
          <Button
            variant='text'
            disabled={false}
            startIcon={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z'
                  fill='#1C274C'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 17.5303C12.3897 17.671 12.1989 17.75 12 17.75C11.8011 17.75 11.6103 17.671 11.4697 17.5303L8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697C9.26256 13.6768 9.73744 13.6768 10.0303 13.9697L11.25 15.1893V11C11.25 10.5858 11.5858 10.25 12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V15.1893L13.9697 13.9697C14.2626 13.6768 14.7374 13.6768 15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303L12.5303 17.5303Z'
                  fill='#4B5962'
                />
              </svg>
            }
            onClick={handleDownloadSelectionPdf}
            style={{
              width: width > 1050 ? '' : '100%',
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              color: '#123532',
            }}
          >
            Скачать PDF выбранных документов
          </Button>
        </div>
      </Collapse>

      {isLoading || !!!data ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '1.2',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',

              height: 'auto',
              lineHeight: '1.5',
              padding: '8px',
              boxSizing: 'border-box',
            },
          }}
          colData={colData}
          rowData={rowData}
          rowCallback={handleShowClick}
          rowSelectionCallback={array => {
            dispatch(
              changeArchived({
                value: 'selectedRows',
                label: array,
              }),
            )
          }}
          hasCheckboxSelection={true}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default TableArchive
