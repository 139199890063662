import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import Cookies from 'js-cookie'

Pusher.logToConsole = true

window.Pusher = Pusher

const echo = new Echo({
  broadcaster: 'pusher',
  key: 'app-key',
  cluster: 'eu',
  wssPort: 443,
  wsPort: 443,
  wsHost: 'metrology-laravel-develop-ws.metriva.ru',
  wssHost: 'metrology-laravel-develop-ws.metriva.ru',
  forceTLS: 'https',
  encrypted: false,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  },
})

export default echo
