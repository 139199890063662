import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Meters from '../app/pages/Meters/Meters'
import AddMetersWater from '../app/pages/Meters/water/AddMeters'
import AddMetersGas from '../app/pages/Meters/gas/AddMetersGas'
import MeterShow from '../app/pages/Meters/MeterShow'
import EditMetersWater from '../app/pages/Meters/water/EditMeters'
import EditMetersGas from '../app/pages/Meters/gas/EditMetersGas'

const RouteMeters: React.FC = (): ReactElement => {
  return (
    <Routes>
      <Route index element={<Meters />} />
      <Route path='/create_water_base' element={<AddMetersWater />} />
      <Route path='/create_gas_base' element={<AddMetersGas />} />
      <Route path=':meters_id/edit_water' element={<EditMetersWater />} />
      <Route path=':meters_id/edit_gas' element={<EditMetersGas />} />
      <Route path=':meters_id' element={<MeterShow />} />
    </Routes>
  )
}

export default RouteMeters
